import { Icon } from '@buggy/shared'
import { Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useIntl } from '@/i18n/hooks/use-intl'
import { Potential } from '@/utils/detail/potential'

interface HighPotentialTagProps {
  size?: 'small' | 'default'
  potential?: Potential
}

const HighPotentialTag: FunctionComponent<HighPotentialTagProps> = ({
  size = 'default',
  potential,
}) => {
  const { formatMessage } = useIntl()

  if (potential !== Potential.High) {
    return null
  }

  return (
    <Tooltip
      placement='bottom'
      title={`${formatMessage({
        id: 'general:high_potential_kol',
      })}${formatMessage({ id: 'general:colon' })}${formatMessage({
        id: 'kol:high_potential_tooltip',
      })}`}
    >
      <Tag $size={size === 'small' ? 16 : 20}>
        <Icon
          fontSize={size === 'small' ? 10 : 12}
          name='trending-up'
          stroke='#26AE60'
        />
      </Tag>
    </Tooltip>
  )
}

const Tag = styled.div<{ $size: number }>`
  display: inline-flex;
  width: ${({ $size }): number => $size}px;
  height: ${({ $size }): number => $size}px;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  background: #e6fcde;
  border-color: #a0d88c;
`

export default HighPotentialTag
