import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useFetchUserStatusQuery } from '@/api/user-api'
import logoSrc from '@/assets/images/logo/logo-horizontal.png'
import { H6 } from '@/components/common/typography'
import { Icon } from '@/components/custom-icon'
import LangDropdown from '@/components/header/lang-dropdown'
import MessageDropdown from '@/components/header/message-dropdown'
import {
  SearchInputContainer,
  SearchKeyword,
} from '@/components/header/search-keyword'
import { WorkspaceDropdown } from '@/components/header/workspace-dropdown'
import TrialCounter from '@/components/layout/header/trial-counter'
import { LayoutProps } from '@/components/layout/irm/irm-layout'
import { WidthBoundary } from '@/components/layout/irm/width-boundary'
import { SafeNewTabLink } from '@/components/safe-new-tab-link'
import { CountryCode } from '@/constants/country-currency'
import { REQUEST_DEMO_LINK } from '@/constants/links'
import routes from '@/hooks/use-authorization/routes'
import { usePermissions } from '@/hooks/use-permissions'
import useTrial from '@/hooks/use-trial'
import { useIntl } from '@/i18n/hooks/use-intl'
import { StyledHeader } from '@/types/antd/styled-antd'
import { ampli, PromiseResult, Result } from '@/utils/ampli'

interface HeaderProps extends LayoutProps {
  isCollapsed: boolean
}

export const IRM_HEADER_HEIGHT = 64

const IrmHeader: FunctionComponent<HeaderProps> = ({ layout, isCollapsed }) => {
  const { formatMessage } = useIntl()
  const { asPath } = useRouter()
  const { canTrial } = useTrial()
  const { data: userStatus } = useFetchUserStatusQuery()

  const { withFreePermission, withTrialPermission } = usePermissions()

  const isTrial = userStatus?.currentWorkspace.isTrial
  const showRequestDemoLink =
    (withFreePermission || withTrialPermission) &&
    userStatus?.currentWorkspace.countryCode === CountryCode.TW

  return (
    <HeaderWrapper isCollapsed={isCollapsed}>
      <StyleHeader>
        <WidthBoundary>
          <Wrapper>
            <LogoWrapper>
              <Image alt='logo' height={24} src={logoSrc} width={116} />
            </LogoWrapper>
            <SearchKeyword />
            <ActionWrapper>
              {showRequestDemoLink && (
                <RequestDemoLink href={REQUEST_DEMO_LINK}>
                  <Icon type='elIconCs' />
                  {formatMessage({ id: 'irm:header_request_demo_act' })}
                </RequestDemoLink>
              )}
              {layout === 'setting' && (
                <Link href={routes.search.stringifyUrl({})}>
                  <>
                    <Icon
                      style={{
                        height: 16,
                        color: '#3e3d3d',
                        marginRight: 8,
                      }}
                      type='home'
                    />
                    <H6>
                      {formatMessage({
                        id: 'irm:header_button_backtoservice',
                      })}
                    </H6>
                  </>
                </Link>
              )}
              {canTrial && (
                <FreePremiumTrial
                  href={routes.workspacePlan.stringifyUrl(
                    {},
                    { openTrialModal: 1 },
                  )}
                  onClick={(): PromiseResult<Result> =>
                    ampli.clickWorkspacePlan({ path: asPath })
                  }
                >
                  <Icon type='elIconCrownOutline' />
                  {formatMessage({ id: 'account:free_premium_trial' })}
                </FreePremiumTrial>
              )}
              <LangDropdown />
              <MessageDropdown />
              <WorkspaceDropdown />
            </ActionWrapper>
          </Wrapper>
        </WidthBoundary>
      </StyleHeader>
      {isTrial && layout === 'feature' && <TrialCounter />}
    </HeaderWrapper>
  )
}

// 目前只有警告頁面是特例，需要顯示 Logo
export const LogoWrapper = styled.div`
  display: none;
`

export const HeaderWrapper = styled.div<{ isCollapsed: boolean }>`
  position: fixed;
  z-index: 100;
  left: ${({ isCollapsed }): string => (isCollapsed ? '80px' : '200px')};
  width: calc(
    100% - ${({ isCollapsed }): number => (isCollapsed ? 80 : 200)}px
  );
`

const StyleHeader = styled(StyledHeader)`
  border-bottom: 1px solid #f0f0f0;
  height: ${IRM_HEADER_HEIGHT}px;
  line-height: 1.6;
`

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin: 0 0 0 40px;

    @media (max-width: 1280px) {
      margin: 0 0 0 12px;
    }
  }

  ${LangDropdown} {
    flex: 0 0 auto;
    max-width: 120px;
  }

  ${MessageDropdown} {
    flex: 0 0 auto;
    max-width: 120px;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${IRM_HEADER_HEIGHT}px;

  ${SearchInputContainer} {
    flex: 0 1 240px;
  }

  ${ActionWrapper} {
    flex: 1 1 auto;
  }
`

const FreePremiumTrial = styled(Link)`
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }): string => theme.colors.plan.premium};

  &:hover {
    color: ${({ theme }): string => theme.colors.plan.premium};
  }

  > .anticon {
    margin-right: 10px;
  }
`

const RequestDemoLink = styled(SafeNewTabLink)`
  display: flex;
  padding: 3px 15px;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #67b36a;
  background: #f1ffe6;
  color: #67b36a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  :hover {
    color: #67b36a;
  }

  > .anticon {
    color: #67b36a;
    margin-right: 6px;
  }
`

export default IrmHeader
