import isEmpty from 'lodash-es/isEmpty'
import isUndefined from 'lodash-es/isUndefined'
import { useCallback, useMemo } from 'react'
import { CUSTOMIZED_TAG_I18N_MAP } from '@/constants/customized-tag'
import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'
import { PlatformData } from '@/tests/mock-data/mock-content-distribution'
import searchCustomizedTagNames, {
  CustomizedTagNameSearchData,
} from '@/utils/search-customized-tag-names'

interface UseCustomizedTag {
  getCustomizedTagLocalizedName: (tag: string) => string
  searchCustomizedTagNames: (keyword: string) => string[]
  parseFilterTags: (filterNames?: string) => string[]
  getSubTagsWithRatioTooltip: (
    subCustomizedTags: PlatformData['subCustomizedTags'],
  ) => string
}

const useCustomizedTag = (): UseCustomizedTag => {
  const { formatMessage, formatNumber } = useIntl()

  const tags = useMemo((): CustomizedTagNameSearchData[] => {
    return Object.entries(CUSTOMIZED_TAG_I18N_MAP).map(
      (entry): CustomizedTagNameSearchData => {
        const [name, i18nId] = entry
        return {
          name,
          localizedName: formatMessage({ id: i18nId }),
        }
      },
    )
  }, [formatMessage])

  const getCustomizedTagLocalizedName = useCallback(
    (tag: string): string => {
      const mapResult: I18nId | undefined = CUSTOMIZED_TAG_I18N_MAP[tag]
      if (isUndefined(mapResult)) {
        return tag
      }

      return formatMessage({ id: mapResult })
    },
    [formatMessage],
  )

  const getSubTagsWithRatioTooltip = (
    subCustomizedTags: PlatformData['subCustomizedTags'],
  ): string => {
    return subCustomizedTags
      .map(
        ({ customizedTagName, customizedTagPostCountRatio }) =>
          `${getCustomizedTagLocalizedName(customizedTagName)} ${formatNumber(
            customizedTagPostCountRatio,
            {
              isPercentageDisplay: true,
            },
          )}`,
      )
      .join(',')
  }

  return {
    getCustomizedTagLocalizedName,
    getSubTagsWithRatioTooltip,
    parseFilterTags: (filterNames?: string): string[] =>
      filterNames?.split(/[,|]/g).filter((x) => !isEmpty(x)) ?? [],
    searchCustomizedTagNames: useCallback(
      (keyword: string): string[] => searchCustomizedTagNames(keyword, tags),
      [],
    ),
  }
}

export default useCustomizedTag
