import styled from 'styled-components'

const spaceSize = {
  small: 8,
  middle: 16,
  large: 24,
}

type Size = 'small' | 'middle' | 'large' | number

export type SpaceProps = {
  align?: 'start' | 'end' | 'center' | 'baseline'
  className?: string
  direction?: 'horizontal' | 'vertical'
  size?: Size
}

/**
 * @deprecated Use `antd` Space instead.
 */
const Space = styled.div<SpaceProps>`
  display: inline-flex;
  align-items: ${(props): string | undefined => {
    const mergedAlign =
      props.align === undefined && props.direction === 'horizontal'
        ? 'center'
        : props.align
    switch (mergedAlign) {
      case 'start':
        return 'flex-start'
      case 'end':
        return 'flex-end'
      default:
        return mergedAlign
    }
  }};
  flex-direction: ${(props): string =>
    props.direction === 'vertical' ? 'column' : 'row'};

  > :not(:last-child) {
    margin-right: ${(props): number =>
      props.direction !== 'vertical'
        ? typeof props.size === 'number'
          ? props.size
          : spaceSize[props.size || 'small']
        : 0}px;
    margin-bottom: ${(props): number =>
      props.direction === 'vertical'
        ? typeof props.size === 'number'
          ? props.size
          : spaceSize[props.size || 'small']
        : 0}px;
  }
`

export default Space
