import { theme } from '@buggy/shared'
import { Badge } from 'antd'
import Link from 'next/link'
import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import { useMount } from 'react-use'
import styled from 'styled-components'
import { useFetchUserStatusQuery } from '@/api/user-api'
import { H6 } from '@/components/common/typography'
import { Icon } from '@/components/custom-icon'
import { WorkspaceDropdown } from '@/components/header/workspace-dropdown'
import { Page } from '@/hooks/use-authorization/constants'
import { usePermissions } from '@/hooks/use-permissions'
import { useSelector } from '@/hooks/use-selector'
import { useIntl } from '@/i18n/hooks/use-intl'
import { actions } from '@/store/state.report'

export const HeaderItemGroupWithWorkspace: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { data: userStatus } = useFetchUserStatusQuery()
  const { unreadReport } = useSelector((state) => state.report)
  const { withGuestPermission } = usePermissions()

  const fontSize = '24px'

  useMount(() => {
    dispatch(
      actions.getUnreadReportStatus({
        workspaceID: userStatus?.currentWorkspaceId,
      }),
    )
  })

  return (
    <HeaderItemGroupWrapper>
      <HeaderItemWrapper>
        <Link href={Page.Report}>
          <Icon style={{ fontSize }} type='elIconBarChart' />
          <Badge dot={unreadReport}>
            <H6>{formatMessage({ id: 'general:navbar_autoreport' })}</H6>
          </Badge>
        </Link>
      </HeaderItemWrapper>
      {!withGuestPermission && (
        <HeaderItemWrapper>
          <Link href={Page.CollectionsKol}>
            <Icon
              style={{ fontSize, color: theme.colors.text.primary }}
              type='elIconCheckListThin'
            />
            <H6>{formatMessage({ id: 'general:navbar_project_text' })}</H6>
          </Link>
        </HeaderItemWrapper>
      )}
      <WorkspaceDropdown />
    </HeaderItemGroupWrapper>
  )
}

const HeaderItemGroupWrapper = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;

    > * {
      :not(:last-child) {
        margin-right: 41px;
      }
    }
  }
`

const HeaderItemWrapper = styled.div`
  cursor: pointer;

  ${H6} {
    display: inline-block;
    margin-left: 8px;
  }

  .anticon {
    color: #3e3d3d;
  }

  .bullhorn {
    svg {
      padding-top: 5px;
    }
  }

  &:hover {
    color: ${({ theme }): string => theme.colors.brand.primaryHover};
  }
`
