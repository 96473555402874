import { I18nId } from '@/i18n/config'

export const countryCode = [
  { name: '台灣', value: 'tw', countryCallingCode: '+886' },
  { name: '香港', value: 'hk', countryCallingCode: '+852' },
  { name: '日本', value: 'jp', countryCallingCode: '+81' },
  { name: '中國', value: 'cn', countryCallingCode: '+86' },
  { name: '馬來西亞', value: 'my', countryCallingCode: '+60' },
  { name: '新加坡', value: 'sg', countryCallingCode: '+65' },
  { name: '泰國', value: 'th', countryCallingCode: '+66' },
  { name: '越南', value: 'vn', countryCallingCode: '+84' },
  { name: '韓國', value: 'kr', countryCallingCode: '+82' },
  { name: '印尼', value: 'id', countryCallingCode: '+62' },
  { name: '美國', value: 'us', countryCallingCode: '+1' },
  { name: '加拿大', value: 'ca', countryCallingCode: '+1' },
] as const

export type CountryCodeConst = (typeof countryCode)[number]
export type CountryCode = CountryCodeConst['value']

export const COUNTRY_CODE_I18N_MAP: Record<CountryCode, I18nId> = {
  tw: 'account:register_region_tw',
  hk: 'account:register_region_hk',
  jp: 'account:register_region_jp',
  cn: 'account:register_region_cn',
  my: 'account:register_region_my',
  sg: 'account:register_region_sg',
  th: 'account:register_region_th',
  vn: 'account:register_region_vn',
  kr: 'account:register_region_kr',
  id: 'account:register_region_id',
  us: 'account:register_region_us',
  ca: 'account:register_region_ca',
}
