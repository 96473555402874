import { FunctionComponent, ReactNode } from 'react'
import { useBreakpoint } from '@/hooks/use-breakpoint'

interface RwdProps {
  mobile?: boolean
  pc?: boolean
  children: ReactNode
}

/**
 * @example
 * // `true` meaning to display, defaults `true`
 * <RWD mobile={false} PC={true}>
 *   {children}
 * </RWD>
 */
export const RWD: FunctionComponent<RwdProps> = ({
  pc = true,
  mobile = true,
  children,
}) => {
  const breakpoint = useBreakpoint()
  const visible =
    (breakpoint === 'mobile' && mobile === true) ||
    (breakpoint === 'PC' && pc === true)

  if (!visible) {
    return null
  }

  return children
}
