import { useMemo } from 'react'
import { useFetchUserStatusQuery, WorkspaceType } from '@/api/user-api'
import { CountryCode } from '@/constants/country-currency'
import useAgentInfo from '@/hooks/use-agent-info'
import { useAuth } from '@/hooks/use-auth'
import { ServiceCategory } from '@/types/mock-api-types'
import { KolUserIdentityType } from '@/types/schema/agent-schema'

export type Permission =
  | 'irm'
  | 'report'
  | 'internal'
  | 'campaign'
  | 'competitive'

const coverPermission = (permission: Permission): keyof Permissions => {
  const permissionMap: Record<Permission, keyof Permissions> = {
    irm: 'withIRMPermission',
    report: 'withReportPermission',
    internal: 'withInternalPermission',
    campaign: 'withCampaignPermission',
    competitive: 'withCompetitivePermission',
  }

  return permissionMap[permission]
}

interface Permissions {
  withIRMPermission: boolean
  withReportPermission: boolean
  withCampaignPermission: boolean
  withInternalPermission: boolean
  withEnterprisePermission: boolean
  withPremiumPermission: boolean
  withFreePermission: boolean
  withKolPermission: boolean
  withGuestPermission: boolean
  withRadarStarPermission: boolean
  withAgencyKolPermission: boolean
  withDeprecatedPremiumPermission: boolean
  withOfflinePremiumPermission: boolean
  withAdvancedPermission: boolean
  withTrialPermission: boolean
  withCompetitivePermission: boolean
  withAIEnhancedPermission: boolean
}

// TODO: support all permissions
export function usePermissions(): Permissions {
  const { data: userStatus } = useFetchUserStatusQuery()
  const { isAgent, isKol } = useAuth()
  const { kolUserIdentityType } = useAgentInfo()

  const withIRMPermission = useMemo<boolean>(
    () =>
      userStatus?.currentWorkspace.availableServices.some(
        (service) => service.serviceCategory === 'irm',
      ) || false,
    [userStatus?.currentWorkspace],
  )

  const withReportPermission = useMemo<boolean>(
    () =>
      userStatus?.currentWorkspace.availableResources.some(
        (resource) => resource.resourceType === 'report',
      ) ||
      userStatus?.currentWorkspace.availableServices.some(
        (service) => service.serviceCategory === 'report',
      ) ||
      false,
    [userStatus?.currentWorkspace],
  )

  const withCampaignPermission = useMemo<boolean>(
    () =>
      userStatus?.currentWorkspace.availableServices.some(
        (service) => service.serviceCategory === 'campaign',
      ) || false,
    [userStatus?.currentWorkspace],
  )

  const withCompetitivePermission = useMemo<boolean>(
    () =>
      userStatus?.currentWorkspace.availableServices.some(
        (service) => service.serviceCategory === ServiceCategory.Competitive,
      ) ?? false,
    [userStatus?.currentWorkspace],
  )

  const withInternalPermission = useMemo(() => {
    return userStatus?.currentWorkspace.workspaceType === WorkspaceType.Internal
  }, [userStatus?.currentWorkspace.workspaceType])

  const withEnterprisePermission = useMemo(() => {
    return (
      userStatus?.currentWorkspace.workspaceType === WorkspaceType.Enterprise ||
      userStatus?.currentWorkspace.workspaceType === WorkspaceType.Corporate
    )
  }, [userStatus?.currentWorkspace.workspaceType])

  const withPremiumPermission = useMemo(() => {
    return userStatus?.currentWorkspace.workspaceType === WorkspaceType.Premium
  }, [userStatus?.currentWorkspace.workspaceType])

  const withAdvancedPermission = useMemo(() => {
    return userStatus?.currentWorkspace.workspaceType === WorkspaceType.Advanced
  }, [userStatus?.currentWorkspace.workspaceType])

  /**
   * @deprecated
   */
  const withDeprecatedPremiumPermission = useMemo(() => {
    return (
      userStatus?.currentWorkspace.workspaceType === WorkspaceType.Vip ||
      userStatus?.currentWorkspace.workspaceType === WorkspaceType.PaymentVip ||
      userStatus?.currentWorkspace.workspaceType === WorkspaceType.VipTrial
    )
  }, [userStatus?.currentWorkspace.workspaceType])
  /**
   * @deprecated
   */
  const withOfflinePremiumPermission = useMemo(() => {
    return userStatus?.currentWorkspace.workspaceType === WorkspaceType.Vip
  }, [userStatus?.currentWorkspace.workspaceType])

  const withFreePermission = useMemo(() => {
    return userStatus?.currentWorkspace.workspaceType === WorkspaceType.Free
  }, [userStatus?.currentWorkspace.workspaceType])

  const withTrialPermission = useMemo(() => {
    return userStatus?.currentWorkspace.workspaceType === WorkspaceType.Trial
  }, [userStatus?.currentWorkspace.workspaceType])

  const withKolPermission = isAgent || isKol
  const withRadarStarPermission = [
    KolUserIdentityType.RadarStarKOL,
    KolUserIdentityType.RadarStarAgency,
  ].some((type) => type === kolUserIdentityType)
  const withGuestPermission =
    userStatus?.currentWorkspace.memberStatus === 'guest' ||
    userStatus?.currentWorkspace.memberStatus === 'viewer'

  const withAgencyKolPermission =
    kolUserIdentityType === KolUserIdentityType.AgencyKOL

  const withAIEnhancedPermission =
    userStatus?.currentWorkspace.countryCode === CountryCode.TW

  return {
    withInternalPermission,
    withEnterprisePermission,
    withPremiumPermission,
    withIRMPermission,
    withReportPermission,
    withCampaignPermission,
    withFreePermission,
    withKolPermission,
    withRadarStarPermission,
    withGuestPermission,
    withAgencyKolPermission,
    withDeprecatedPremiumPermission,
    withOfflinePremiumPermission,
    withAdvancedPermission,
    withTrialPermission,
    withCompetitivePermission,
    withAIEnhancedPermission,
  }
}

export function usePermission(permission: Permission): boolean {
  const permissions = usePermissions()

  return permissions[coverPermission(permission)] || false
}
