import { Space } from 'antd'
import { values } from 'lodash-es'
import isUndefined from 'lodash-es/isUndefined'
import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { createEnumParam } from 'use-query-params'
import { HashtagSource } from '@/api/workspaces-api'
import CountrySelect from '@/components/common/country-select'
import DurationSelect, { Duration } from '@/components/common/duration-select'
import { Icon } from '@/components/custom-icon'
import FilterWrapper from '@/components/hashtag/common/filter-wrapper'
import DbPurchaseModal from '@/components/modal/db-purchase-modal'
import { VipOnlyModal } from '@/components/modal/vip-only-modal'
import CustomizedTagSelector from '@/components/search/filters/common/customized-tag-selector'
import useCustomizedTag from '@/hooks/use-customized-tag'
import useDefaultCountry from '@/hooks/use-default-country'
import { usePermissions } from '@/hooks/use-permissions'
import { useIntl } from '@/i18n/hooks/use-intl'
import { ampli, ChangeHashtagFilterProperties } from '@/utils/ampli'
import { PlatformShortcode } from '@/utils/convert-platform'

interface HashtagFilterValue {
  country: string
  platform: PlatformShortcode
  duration: Duration
  customized_tags_name: string
}

interface HashtagFilterProps {
  value: HashtagFilterValue
  section: ChangeHashtagFilterProperties['at']
  onChange: (value: HashtagFilterValue) => void
}

export const DEFAULT_FILTER_VALUE: Omit<HashtagFilterValue, 'country'> = {
  customized_tags_name: 'all',
  duration: Duration.FourteenDays,
  platform: PlatformShortcode.Instagram,
}

export const DurationParam = createEnumParam(values(Duration))
export const PlatformParam = createEnumParam(values(PlatformShortcode))
export const HashtagSourceParam = createEnumParam(values(HashtagSource))

const HashtagFilter: FunctionComponent<HashtagFilterProps> = ({
  value,
  onChange,
  section,
}) => {
  const { withFreePermission } = usePermissions()
  const defaultCountry = useDefaultCountry()
  const { getCustomizedTagLocalizedName } = useCustomizedTag()
  const { formatMessage } = useIntl()

  const [vipOnlyModalVisible, setVipOnlyModalVisible] = useState(false)
  const [dBPurchaseModalVisible, setDBPurchaseModalVisible] = useState(false)

  const handleChangeFilter = (filter: Partial<HashtagFilterValue>): void => {
    onChange({
      ...value,
      ...filter,
    })

    const country = filter.country ?? value.country
    const kolCategory = [
      filter.customized_tags_name ?? value.customized_tags_name,
    ]
    const period = filter.duration ?? value.duration

    ampli.changeHashtagFilter({
      at: section,
      country,
      kolCategory,
      period,
    })
  }

  return (
    <FilterWrapper>
      <Space align='center' data-tour-marketing-trend-step='3' size={20}>
        <CountrySelect
          country={value.country}
          defaultCountry={defaultCountry}
          hideNoDataCountry={true}
          setCountry={(country): void => {
            handleChangeFilter({
              country,
            })
          }}
          onSelectUnavailableCountry={(): void =>
            setDBPurchaseModalVisible(true)
          }
        />
        <DurationSelect
          unavailableDurations={
            withFreePermission
              ? [Duration.OneMonth, Duration.ThreeMonths, Duration.SixMonths]
              : []
          }
          value={value.duration}
          onChange={(duration): void => handleChangeFilter({ duration })}
          onSelectUnavailableDuration={(): void => setVipOnlyModalVisible(true)}
        />
        <TypeSelectorWrapper>
          <CustomizedTagSelector
            isUpdateOnClose
            customizedTagsNames={value.customized_tags_name}
            mode='single'
            onValuesChange={(customized_tags_name): void => {
              handleChangeFilter({
                customized_tags_name,
              })
            }}
          >
            <TagSelectorTrigger>
              <Text>
                {value.customized_tags_name ===
                  DEFAULT_FILTER_VALUE.customized_tags_name ||
                isUndefined(value.customized_tags_name)
                  ? formatMessage({ id: 'general:auto_tag_all' })
                  : getCustomizedTagLocalizedName(value.customized_tags_name)}
              </Text>
              <Icon type='down' />
            </TagSelectorTrigger>
          </CustomizedTagSelector>
        </TypeSelectorWrapper>
      </Space>
      <DbPurchaseModal
        open={dBPurchaseModalVisible}
        onClose={(): void => setDBPurchaseModalVisible(false)}
      />
      <VipOnlyModal
        visible={vipOnlyModalVisible}
        onClose={(): void => setVipOnlyModalVisible(false)}
      />
    </FilterWrapper>
  )
}

export const TagSelectorTrigger = styled.div`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: inline-flex;
  font-size: 14px;
  color: ${({ theme }): string => theme.colors.text.secondary};
  background: ${({ theme }): string => theme.colors.background.white};
  border: 1px solid ${({ theme }): string => theme.colors.border.border};
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  padding: 4px 11px;
  height: 32px;
  line-height: 22px;

  &:hover {
    border-color: ${({ theme }): string => theme.colors.brand.primary};
    border-right-width: 1px !important;
  }

  .anticon {
    font-size: 12px;
    color: ${({ theme }): string => theme.colors.base.grey.grey3};
  }
`

const Text = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
`

export const TypeSelectorWrapper = styled.div`
  .ant-popover-content {
    min-width: 526px;
  }
`

export default HashtagFilter
