import { useI18n } from '@/hooks/use-i18n'
import { SupportedLanguages } from '@/i18n/config'

const DEFAULT_FONT_FAMILIES = [
  'Noto Sans TC',
  'YuGothic',
  'Noto Sans JP',
  'Noto Sans',
  'Roboto',
  'sans-serif',
]

const ENGLISH_FONT_FAMILIES = [
  'Inter',
  'Noto Sans',
  'Noto Sans TC',
  'YuGothic',
  'Noto Sans JP',
  'Roboto',
  'sans-serif',
]

const getFontFamilies = (lang: SupportedLanguages): string[] => {
  if (lang === SupportedLanguages.enUS) {
    return ENGLISH_FONT_FAMILIES
  } else {
    return DEFAULT_FONT_FAMILIES
  }
}

const useFontFamily = (): string => {
  const { language } = useI18n()

  const fontFamilies = getFontFamilies(language.i18nCode)

  return fontFamilies.join(',')
}

export default useFontFamily
