import { Dropdown, Menu } from 'antd'
import React, { FunctionComponent, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import CampaignCaseRow from '@/components/campaign/chatroom/campaign-case-row'
import BackButton from '@/components/common/buttons/back-button'
import { Icon } from '@/components/custom-icon'
import {
  Conversation,
  switchConversation,
  toggleMessageComposeVisible,
} from '@/store/chatroom'
import { CampaignKolStatus } from '@/types/mock-api-types'

type CurrentCampaignCaseHeaderProps = {
  conversationList: Conversation[]
  currentConversation: Conversation
}

const CurrentConversationDropdown: FunctionComponent<
  CurrentCampaignCaseHeaderProps
> = ({ conversationList, currentConversation }) => {
  const dispatch = useDispatch()
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const isEnabled =
    conversationList.length > 1 &&
    currentConversation.campaignCase?.status !== CampaignKolStatus.Done

  const campaignCaseList = (
    <Menu>
      {conversationList
        .filter((conversation) => conversation.id !== currentConversation.id)
        .map((conversation) => {
          return (
            <Menu.Item
              key={conversation.id}
              onClick={(): void => setIsDropdownVisible(false)}
            >
              <CampaignCaseRow
                conversation={conversation}
                currentConversationId={currentConversation.id}
                onClick={(): void => {
                  dispatch(switchConversation(conversation.id))
                }}
              />
            </Menu.Item>
          )
        })}
    </Menu>
  )

  return (
    <Wrapper ref={wrapperRef}>
      <Dropdown
        align={{ offset: [0, -2] }}
        disabled={!isEnabled}
        getPopupContainer={(): HTMLElement =>
          wrapperRef.current || document.body
        }
        open={isDropdownVisible}
        overlay={campaignCaseList}
        placement='bottomRight'
        trigger={['click']}
        onOpenChange={(visible): void => setIsDropdownVisible(visible)}
      >
        <HStack>
          <BackButton
            onClick={(): void => {
              dispatch(toggleMessageComposeVisible())
            }}
          >
            <Icon type='chevronLeftSolid' />
          </BackButton>
          <CampaignCaseRow
            conversation={currentConversation}
            currentConversationId={currentConversation.id}
          />
          {isEnabled && (
            <Icon
              type={isDropdownVisible ? 'chevronUpSolid' : 'chevronDownSolid'}
            />
          )}
        </HStack>
      </Dropdown>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .ant-dropdown {
    width: 100%;
  }

  .ant-dropdown-menu {
    height: 500px;
    overflow: hidden scroll;
    padding: 0;
  }

  .ant-dropdown-menu-item {
    padding: 11px 20px;
  }
`

const HStack = styled.div`
  align-items: center;
  background: ${({ theme }): string => theme.colors.background.white};
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px;
  display: flex;
  padding: 11px 20px 11px 10px;
  width: 100%;

  > :not(:last-child) {
    margin-right: 8px;
  }

  @media (min-width: 768px) {
    padding-left: 20px;
  }

  > .anticon {
    font-size: 16px;
  }
`

export default CurrentConversationDropdown
