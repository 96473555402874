import Link from 'next/link'
import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useQueryParam } from 'use-query-params'
import { Icon } from '@/components/custom-icon'
import LangDropdown from '@/components/header/lang-dropdown'
import UnsafeNewTabLink from '@/components/public-page/footer/components/unsafe-new-tab-link'
import Dropdown from '@/components/public-page/header/components/dropdown'
import KolRadarLogo from '@/components/public-page/header/components/kol-radar-logo'
import useKolRadarJpMenuItems from '@/components/public-page/header/components/use-kol-radar-jp-menu-items'
import useUrlControl from '@/components/public-page/hook/use-url-control'
import { useAuth } from '@/hooks/use-auth'
import { Page } from '@/hooks/use-authorization/constants'
import { useI18n } from '@/hooks/use-i18n'
import { useIntl } from '@/i18n/hooks/use-intl'
import { logout } from '@/store/me'
import { NonNullStringParam } from '@/utils/use-query-params'

const AppHeaderJpCountryMobile: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const [from] = useQueryParam('from', NonNullStringParam)

  const { language } = useI18n()
  const dispatch = useDispatch()
  const { userInfo } = useAuth()
  const { getAppUrl, getRootUrl } = useUrlControl()
  const menuItems = useKolRadarJpMenuItems()

  return (
    <Wrapper>
      <LeftItemsContainer>
        <HomePageLink href={getRootUrl()}>
          <KolRadarLogo />
        </HomePageLink>
      </LeftItemsContainer>
      <RightItemsContainer>
        <LangDropdown language={language} />
        <Dropdown menuTitle={<ListIcon type='elIconList' />} position='full'>
          <MobileMenuContentWrapper>
            {menuItems.map((item, i) => {
              switch (item.type) {
                case 'link': {
                  const { url, onClick, title, isBlank } = item

                  if (isBlank) {
                    return (
                      <div key={i}>
                        <UnsafeNewTabLink href={url} onClick={onClick}>
                          {title}
                        </UnsafeNewTabLink>
                      </div>
                    )
                  }
                  return (
                    <div key={i}>
                      <Link href={url} onClick={onClick}>
                        {title}
                      </Link>
                    </div>
                  )
                }
                case 'dropdown': {
                  const { columns } = item
                  return columns.map((column) => {
                    return column.map((group) => {
                      const { visible: groupVisible = true, linkItems } = group

                      if (!groupVisible) {
                        return null
                      }

                      const isAllItemHidden = linkItems.every(
                        (item) => item.visible === false,
                      )
                      if (isAllItemHidden) {
                        return null
                      }

                      return linkItems.map((item, j) => {
                        const {
                          title,
                          visible = true,
                          url,
                          onClick = (): void => {},
                          isBlank = false,
                        } = item

                        if (!visible) {
                          return null
                        }

                        if (isBlank) {
                          return (
                            <div key={j}>
                              <UnsafeNewTabLink href={url} onClick={onClick}>
                                {title}
                              </UnsafeNewTabLink>
                            </div>
                          )
                        }
                        return (
                          <div key={j}>
                            <Link href={url} onClick={onClick}>
                              {title}
                            </Link>
                          </div>
                        )
                      })
                    })
                  })
                }
                default:
                  return item
              }
            })}
            {!userInfo ? (
              <>
                <div>
                  <a href={getAppUrl(Page.Login, { from })}>
                    {formatMessage({ id: 'general:navbar_text_login' })}
                  </a>
                </div>
                <div>
                  <UnsafeNewTabLink href={getAppUrl(Page.Register, { from })}>
                    <Button>
                      {formatMessage({ id: 'general:navbar_text_signup' })}
                    </Button>
                  </UnsafeNewTabLink>
                </div>
              </>
            ) : (
              <>
                <div>
                  <a href={getAppUrl(Page.Default)}>
                    {formatMessage({ id: 'general:go_to_app' })}
                  </a>
                </div>
                <div
                  onClick={(): void => {
                    dispatch(logout())
                  }}
                >
                  <a>{formatMessage({ id: 'general:logout' })}</a>
                </div>
              </>
            )}
          </MobileMenuContentWrapper>
        </Dropdown>
      </RightItemsContainer>
    </Wrapper>
  )
}

export default AppHeaderJpCountryMobile

const MobileMenuContentWrapper = styled.div`
  div {
    margin-bottom: 20px;

    a,
    span {
      color: ${({ theme }): string => theme.colors.text.primary};
      font-size: 16px;
      font-weight: bold;

      &:hover {
        color: ${({ theme }): string => theme.colors.text.primary};
      }
    }
  }

  ul {
    padding-left: 25px;
    margin: 5px 0;
    border-left: 1px solid
      ${({ theme }): string => theme.colors.text.placeholder};

    li {
      padding: 10px 0;

      a {
        font-size: 14px;
        color: ${({ theme }): string => theme.colors.text.primary};

        &:hover {
          color: ${({ theme }): string => theme.colors.brand.primary};
        }
      }
    }
  }
`

const Button = styled.button`
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  color: ${({ theme }): string => theme.colors.text.white};
  background-color: ${({ theme }): string => theme.colors.brand.primary};

  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`

const HeaderItemContainer = styled.div`
  display: flex;
  align-items: center;

  > * {
    padding: 0 15px;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }
`

const ListIcon = styled(Icon)`
  svg {
    height: 30px;
    width: 30px;
  }
`

const HomePageLink = styled.a`
  svg {
    margin: 5px 0 0;
  }
`

const LeftItemsContainer = styled(HeaderItemContainer)`
  > * {
    &:first-child {
      padding: 0 10px 0 0;
    }
  }
`

const RightItemsContainer = styled(HeaderItemContainer)`
  > * {
    &:last-child {
      padding: 0 0 0 10px;
    }
  }
  display: flex;
  align-items: center;
`
const Wrapper = styled.div`
  align-items: center;
  background: ${({ theme }): string => theme.colors.background.white};
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.102);
  display: flex;
  height: 62px;
  justify-content: space-between;
  padding: 0 16px 0 20px;

  // 為了 transition 的體驗，壓過 ant-drawer 的 z-index
  position: relative;
  z-index: 1001;
`
