export type LanguageCodeType = keyof typeof LANGUAGE_CODE_LIST

export const LANGUAGE_CODE_LIST = {
  'zh-hant': {
    zh: '繁體中文',
    originalLang: '中文（繁體）',
    i18nKey: 'languages_code:zh-hant',
  },
  en: { zh: '英文', originalLang: 'English', i18nKey: 'languages_code:en' },
  ja: { zh: '日文', originalLang: '日本語', i18nKey: 'languages_code:ja' },
  th: { zh: '泰文', originalLang: 'ภาษาไทย', i18nKey: 'languages_code:th' },
  vi: {
    zh: '越南文',
    originalLang: 'Tiếng Việt',
    i18nKey: 'languages_code:vi',
  },
  id: {
    zh: '印尼文',
    originalLang: 'Bahasa Indonesia',
    i18nKey: 'languages_code:id',
  },
  'zh-hans': {
    zh: '簡體中文',
    originalLang: '中文（簡體）',
    i18nKey: 'languages_code:zh-hans',
  },
  ms: {
    zh: '馬來文',
    originalLang: 'Bahasa Melayu',
    i18nKey: 'languages_code:ms',
  },
  it: {
    zh: '義大利文',
    originalLang: 'Italiano',
    i18nKey: 'languages_code:it',
  },
  ko: { zh: '韓文', originalLang: '한국어', i18nKey: 'languages_code:ko' },
  fr: { zh: '法文', originalLang: 'Français', i18nKey: 'languages_code:fr' },
  de: { zh: '德文', originalLang: 'Deutsch', i18nKey: 'languages_code:de' },
  es: {
    zh: '西班牙文',
    originalLang: 'Español',
    i18nKey: 'languages_code:es',
  },
  'zh-yue': {
    zh: '廣東話',
    originalLang: '中文（粤語）',
    i18nKey: 'languages_code:zh-yue',
  },
  ceb: {
    zh: '宿霧語',
    originalLang: 'Baybayin',
    i18nKey: 'languages_code:ceb',
  },
  ru: { zh: '俄文', originalLang: 'русский', i18nKey: 'languages_code:ru' },
  ar: {
    zh: '阿拉伯文',
    originalLang: 'العربية',
    i18nKey: 'languages_code:ar',
  },
  jbo: {
    zh: '津巴布韋語',
    originalLang: 'La .lojban.',
    i18nKey: 'languages_code:jbo',
  },
  pt: {
    zh: '葡萄牙文',
    originalLang: 'Português',
    i18nKey: 'languages_code:pt',
  },
  bg: {
    zh: '保加利亞文',
    originalLang: 'български',
    i18nKey: 'languages_code:bg',
  },
  ur: {
    zh: '烏爾都文',
    originalLang: 'ur duwen',
    i18nKey: 'languages_code:ur',
  },
  sv: { zh: '瑞典文', originalLang: 'svenska', i18nKey: 'languages_code:sv' },
  hy: {
    zh: '亞美尼亞文',
    originalLang: 'հայերեն',
    i18nKey: 'languages_code:hy',
  },
  eo: {
    zh: '世界語',
    originalLang: 'Esperanto',
    i18nKey: 'languages_code:eo',
  },
  ta: {
    zh: '泰米爾文',
    originalLang: 'தமில்வின்',
    i18nKey: 'languages_code:ta',
  },
  ca: {
    zh: '加泰隆尼亞文',
    originalLang: 'Catalònia',
    i18nKey: 'languages_code:ca',
  },
  pl: { zh: '波蘭文', originalLang: 'Polski', i18nKey: 'languages_code:pl' },
  hu: { zh: '匈牙利文', originalLang: 'Magyar', i18nKey: 'languages_code:hu' },
  bn: {
    zh: '孟加拉文',
    originalLang: 'বাংলাদেশ',
    i18nKey: 'languages_code:bn',
  },
  tk: {
    zh: '土庫曼文',
    originalLang: 'Tuku Manwen',
    i18nKey: 'languages_code:tk',
  },
  fa: { zh: '波斯文', originalLang: 'فارسی', i18nKey: 'languages_code:fa' },
  uk: {
    zh: '烏克蘭文',
    originalLang: 'Український',
    i18nKey: 'languages_code:uk',
  },
  nl: {
    zh: '荷蘭文',
    originalLang: 'Nederlandse taal',
    i18nKey: 'languages_code:nl',
  },
  cs: { zh: '捷克文', originalLang: 'čeština', i18nKey: 'languages_code:cs' },
  km: { zh: '高棉文', originalLang: 'ខ្មែរ', i18nKey: 'languages_code:km' },
  fi: {
    zh: '芬蘭文',
    originalLang: 'Suomalainen',
    i18nKey: 'languages_code:fi',
  },
  tr: { zh: '土耳其文', originalLang: 'Türkçe', i18nKey: 'languages_code:tr' },
  sr: {
    zh: '塞爾維亞文',
    originalLang: 'Српски',
    i18nKey: 'languages_code:sr',
  },
  hi: { zh: '印地文', originalLang: 'हिंदी', i18nKey: 'languages_code:hi' },
  lt: {
    zh: '立陶宛文',
    originalLang: 'Lithianwen',
    i18nKey: 'languages_code:lt',
  },
  nds: {
    zh: '低地德文',
    originalLang: 'Nedersaksisch',
    i18nKey: 'languages_code:nds',
  },
  no: { zh: '挪威文', originalLang: 'norsk', i18nKey: 'languages_code:no' },
  oc: {
    zh: '奧克西坦文',
    originalLang: 'Occitan',
    i18nKey: 'languages_code:oc',
  },
  lo: { zh: '寮國文', originalLang: '[OGOO', i18nKey: 'languages_code:lo' },
  ro: {
    zh: '羅馬尼亞文',
    originalLang: 'Română',
    i18nKey: 'languages_code:ro',
  },
  eu: {
    zh: '巴斯克文',
    originalLang: 'Bascwin',
    i18nKey: 'languages_code:eu',
  },
  he: {
    zh: '希伯來文',
    originalLang: 'עִברִית',
    i18nKey: 'languages_code:he',
  },
  my: { zh: '緬甸文', originalLang: 'ဗမာ', i18nKey: 'languages_code:my' },
  sl: {
    zh: '斯洛維尼亞文',
    originalLang: 'Slovenščina',
    i18nKey: 'languages_code:sl',
  },
  ml: {
    zh: '馬拉雅拉姆文',
    originalLang: 'മാല ജാംവിൻ',
    i18nKey: 'languages_code:ml',
  },
  hr: {
    zh: '克羅地亞文',
    originalLang: 'Hrvatski',
    i18nKey: 'languages_code:hr',
  },
  lv: {
    zh: '拉脫維亞文',
    originalLang: 'Latvija',
    i18nKey: 'languages_code:lv',
  },
  sh: {
    zh: '塞爾維亞-克羅埃西亞文',
    originalLang: 'Cрпскохрватски',
    i18nKey: 'languages_code:sh',
  },
  ka: {
    zh: '喬治亞文',
    originalLang: 'ჯორჯ იავენი',
    i18nKey: 'languages_code:ka',
  },
  si: {
    zh: '僧伽羅文',
    originalLang: 'සං ha ලුවුලින්',
    i18nKey: 'languages_code:si',
  },
  cy: {
    zh: '威爾斯文',
    originalLang: 'Gymreig',
    i18nKey: 'languages_code:cy',
  },
  sk: {
    zh: '斯洛伐克文',
    originalLang: 'Slovenský',
    i18nKey: 'languages_code:sk',
  },
  war: {
    zh: '瓦賽語',
    originalLang: 'Winaray',
    i18nKey: 'languages_code:war',
  },
  la: { zh: '拉丁文', originalLang: 'Latine', i18nKey: 'languages_code:la' },
  da: { zh: '丹麥文', originalLang: 'dansk', i18nKey: 'languages_code:da' },
  tl: {
    zh: '塔加路族文',
    originalLang: 'Pamilya Tagasu',
    i18nKey: 'languages_code:tl',
  },
  el: {
    zh: '希臘文',
    originalLang: 'Ελληνικά',
    i18nKey: 'languages_code:el',
  },
  mk: {
    zh: '馬其頓文',
    originalLang: 'Македонијан',
    i18nKey: 'languages_code:mk',
  },
  az: {
    zh: '阿塞拜疆文',
    originalLang: 'Azerbaijinwen',
    i18nKey: 'languages_code:az',
  },
  yi: {
    zh: '意第緒文',
    originalLang: 'Yidi saka',
    i18nKey: 'languages_code:yi',
  },
  et: {
    zh: '愛沙尼亞文',
    originalLang: 'Eestonian',
    i18nKey: 'languages_code:et',
  },
  kn: {
    zh: '卡納達文',
    originalLang: 'ಕನ್ನಧಂತು',
    i18nKey: 'languages_code:kn',
  },
  br: {
    zh: '布里多尼亞語',
    originalLang: 'Brezhoneg',
    i18nKey: 'languages_code:br',
  },
  kk: { zh: '哈薩克文', originalLang: 'Қазақ', i18nKey: 'languages_code:kk' },
  gl: {
    zh: '加利西亞文',
    originalLang: 'Gali Siya',
    i18nKey: 'languages_code:gl',
  },
  pa: { zh: '旁遮普文', originalLang: 'ਪੰਜਾਬ', i18nKey: 'languages_code:pa' },
  or: { zh: '奧里雅文', originalLang: 'Oliwen', i18nKey: 'languages_code:or' },
  uz: {
    zh: '烏茲別克文',
    originalLang: 'Uzbekist',
    i18nKey: 'languages_code:uz',
  },
  te: { zh: '泰盧固文', originalLang: 'తాయున్', i18nKey: 'languages_code:te' },
  be: {
    zh: '白俄羅斯文',
    originalLang: 'Беларус',
    i18nKey: 'languages_code:be',
  },
  so: {
    zh: '索馬里文',
    originalLang: 'Waxgarasho',
    i18nKey: 'languages_code:so',
  },
  lb: {
    zh: '盧森堡文',
    originalLang: 'Lëtzebuerg',
    i18nKey: 'languages_code:lb',
  },
  ne: { zh: '尼泊爾文', originalLang: 'नेपाल', i18nKey: 'languages_code:ne' },
  tt: {
    zh: '塔塔爾文',
    originalLang: 'Татавин',
    i18nKey: 'languages_code:tt',
  },
  pms: {
    zh: '皮埃蒙特文',
    originalLang: 'Piemontèis',
    i18nKey: 'languages_code:pms',
  },
  mr: {
    zh: '馬拉地文',
    originalLang: 'मारन चीनी',
    i18nKey: 'languages_code:mr',
  },
  is: { zh: '冰島文', originalLang: 'Ísland', i18nKey: 'languages_code:is' },
  af: {
    zh: '南非荷蘭文',
    originalLang: 'Suid -Afrika Nederlands',
    i18nKey: 'languages_code:af',
  },
  mt: { zh: '馬爾他文', originalLang: 'Malwin', i18nKey: 'languages_code:mt' },
  als: {
    zh: '艾爾薩斯文',
    originalLang: 'Elsas',
    i18nKey: 'languages_code:als',
  },
  new: {
    zh: '尼瓦爾文',
    originalLang: 'नेपाल भाषा',
    i18nKey: 'languages_code:new',
  },
  xmf: {
    zh: '梅格列文',
    originalLang: 'მარგალური ნინა',
    i18nKey: 'languages_code:xmf',
  },
  fy: {
    zh: '弗里西亞文',
    originalLang: 'Freisian',
    i18nKey: 'languages_code:fy',
  },
  ast: {
    zh: '阿斯圖里亞斯文',
    originalLang: 'Asturianu',
    i18nKey: 'languages_code:ast',
  },
  lez: {
    zh: '列兹金文',
    originalLang: 'лезги чІал',
    i18nKey: 'languages_code:lez',
  },
  vo: { zh: '世界語', originalLang: 'Volapük', i18nKey: 'languages_code:vo' },
  bo: { zh: '藏文', originalLang: 'བོད་སྐད', i18nKey: 'languages_code:bo' },
  sq: {
    zh: '阿爾巴尼亞文',
    originalLang: 'Shqip',
    i18nKey: 'languages_code:sq',
  },
  bs: {
    zh: '波斯尼亞文',
    originalLang: 'Persienac',
    i18nKey: 'languages_code:bs',
  },
  yo: {
    zh: '約魯巴文',
    originalLang: 'Yuebawen',
    i18nKey: 'languages_code:yo',
  },
  kw: {
    zh: '康瓦耳文',
    originalLang: 'Kernewek',
    i18nKey: 'languages_code:kw',
  },
  nn: {
    zh: '新挪威文',
    originalLang: 'Nynorsk',
    i18nKey: 'languages_code:nn',
  },
  mg: {
    zh: '馬拉加西文',
    originalLang: 'Maragoswi',
    i18nKey: 'languages_code:mg',
  },
  gu: {
    zh: '古吉拉特文',
    originalLang: 'ગુજરેટવેન',
    i18nKey: 'languages_code:gu',
  },
  jv: { zh: '爪哇文', originalLang: 'Javawen', i18nKey: 'languages_code:jv' },
  wa: { zh: '瓦隆語', originalLang: 'Walon', i18nKey: 'languages_code:wa' },
  ga: {
    zh: '愛爾蘭文',
    originalLang: 'Gaeilge',
    i18nKey: 'languages_code:ga',
  },
  io: { zh: '伊多文', originalLang: 'Ido', i18nKey: 'languages_code:io' },
  sw: {
    zh: '斯瓦希里文',
    originalLang: 'Kiswahilin',
    i18nKey: 'languages_code:sw',
  },
  ky: {
    zh: '吉爾吉斯文',
    originalLang: 'Кыргызча',
    i18nKey: 'languages_code:ky',
  },
  am: {
    zh: '阿姆哈拉文',
    originalLang: 'አማራፋዊን',
    i18nKey: 'languages_code:am',
  },
  ia: {
    zh: '國際語',
    originalLang: 'Interlingua',
    i18nKey: 'languages_code:ia',
  },
  gd: {
    zh: '蘇格蘭蓋爾文',
    originalLang: 'Golvin na h-Alba',
    i18nKey: 'languages_code:gd',
  },
  ckb: {
    zh: '中庫德文',
    originalLang: 'کوردی',
    i18nKey: 'languages_code:ckb',
  },
  pnb: {
    zh: '西部旁遮普文',
    originalLang: 'پنجابی',
    i18nKey: 'languages_code:pnb',
  },
  su: {
    zh: '巽他文',
    originalLang: 'Kasucian',
    i18nKey: 'languages_code:su',
  },
  mn: { zh: '蒙古文', originalLang: 'Монгол', i18nKey: 'languages_code:mn' },
  mzn: {
    zh: '馬贊德蘭文',
    originalLang: 'مازرونی',
    i18nKey: 'languages_code:mzn',
  },
  tg: { zh: '塔吉克文', originalLang: 'Ҷабин', i18nKey: 'languages_code:tg' },
  rm: {
    zh: '羅曼斯文',
    originalLang: 'Rumantsch',
    i18nKey: 'languages_code:rm',
  },
  cbk: {
    zh: '查維亞諾文',
    originalLang: 'Chavacano',
    i18nKey: 'languages_code:cbk',
  },
  ilo: {
    zh: '伊洛岡諾文',
    originalLang: 'Iloko',
    i18nKey: 'languages_code:ilo',
  },
  ug: {
    zh: '維吾爾文',
    originalLang: 'ئۇيغۇرچى',
    i18nKey: 'languages_code:ug',
  },
  ht: {
    zh: '海地克里奧爾文',
    originalLang: 'Ayiti Crevin',
    i18nKey: 'languages_code:ht',
  },
  ba: {
    zh: '巴什基爾文',
    originalLang: 'Башҡорт',
    i18nKey: 'languages_code:ba',
  },
  sa: {
    zh: '梵文',
    originalLang: 'संस्कृतम्',
    i18nKey: 'languages_code:sa',
  },
  bar: {
    zh: '巴伐利亞文',
    originalLang: 'Boarisch',
    i18nKey: 'languages_code:bar',
  },
  mhr: {
    zh: '馬里烏普爾文',
    originalLang: 'марий',
    i18nKey: 'languages_code:mhr',
  },
  wuu: { zh: '吳語', originalLang: '吳語', i18nKey: 'languages_code:wuu' },
  ku: { zh: '庫德文', originalLang: 'Kuden', i18nKey: 'languages_code:ku' },
  arz: {
    zh: '埃及阿拉伯文',
    originalLang: 'اللغة المصرية العامية‎',
    i18nKey: 'languages_code:arz',
  },
  sah: {
    zh: '雅庫特文',
    originalLang: 'Саха тыла',
    i18nKey: 'languages_code:sah',
  },
  eml: {
    zh: '愛米利亞文',
    originalLang: 'Emiliàn',
    i18nKey: 'languages_code:eml',
  },
  li: {
    zh: '林堡文',
    originalLang: 'Limburgs',
    i18nKey: 'languages_code:li',
  },
  os: {
    zh: '奧塞提亞文',
    originalLang: 'Ирон æвзаг',
    i18nKey: 'languages_code:os',
  },
  lmo: {
    zh: '倫巴底文',
    originalLang: 'Lumbard',
    i18nKey: 'languages_code:lmo',
  },
  vep: {
    zh: '維普薩文',
    originalLang: 'vepsän kel',
    i18nKey: 'languages_code:vep',
  },
  sco: {
    zh: '蘇格蘭文',
    originalLang: 'Scots',
    i18nKey: 'languages_code:sco',
  },
  ce: {
    zh: '車臣文',
    originalLang: 'нохчийн мотт',
    i18nKey: 'languages_code:ce',
  },
  cv: {
    zh: '楚瓦什文',
    originalLang: 'Чăвашла',
    i18nKey: 'languages_code:cv',
  },
  min: {
    zh: '米南卡堡文',
    originalLang: 'Baso Minang',
    i18nKey: 'languages_code:min',
  },
  azb: {
    zh: '南阿塞拜疆文',
    originalLang: 'تورکجه',
    i18nKey: 'languages_code:azb',
  },
  gn: {
    zh: '瓜拉尼文',
    originalLang: "Avañe'ẽ",
    i18nKey: 'languages_code:gn',
  },
  scn: {
    zh: '西西里文',
    originalLang: 'Sicilianu',
    i18nKey: 'languages_code:scn',
  },
  an: {
    zh: '阿拉貢文',
    originalLang: 'Aragonés',
    i18nKey: 'languages_code:an',
  },
  dv: { zh: '迪維希文', originalLang: 'ދިވެހި', i18nKey: 'languages_code:dv' },
  bpy: {
    zh: '邦普爾文',
    originalLang: 'ইমার ঠার/বিষ্ণুপ্রিয়া মণিপুরী',
    i18nKey: 'languages_code:bpy',
  },
  gv: { zh: '曼島文', originalLang: 'Gaelg', i18nKey: 'languages_code:gv' },
  pam: {
    zh: '潘帕恩加文',
    originalLang: 'Kapampangan',
    i18nKey: 'languages_code:pam',
  },
  gom: {
    zh: '戈亚文',
    originalLang: 'गोंयची कोंकणी',
    i18nKey: 'languages_code:gom',
  },
  hsb: {
    zh: '上索布文',
    originalLang: 'Hornjoserbšćina',
    i18nKey: 'languages_code:hsb',
  },
  yue: { zh: '廣東話', originalLang: '粵語', i18nKey: 'languages_code:yue' },
  sd: { zh: '信德文', originalLang: 'ايمان ع', i18nKey: 'languages_code:sd' },
  nah: {
    zh: '納瓦特爾文',
    originalLang: 'Nāhuatl',
    i18nKey: 'languages_code:nah',
  },
  diq: {
    zh: '低薩克遜文',
    originalLang: 'Zazakî',
    i18nKey: 'languages_code:diq',
  },
  as: {
    zh: '阿薩姆文',
    originalLang: 'অসমীয়া',
    i18nKey: 'languages_code:as',
  },
  ie: {
    zh: '奧西登塔爾文',
    originalLang: 'Occidental',
    i18nKey: 'languages_code:ie',
  },
  xal: {
    zh: '卡爾莫克文',
    originalLang: 'Хальмг келн',
    i18nKey: 'languages_code:xal',
  },
  kv: {
    zh: '科米文',
    originalLang: 'Коми кыв',
    i18nKey: 'languages_code:kv',
  },
  mwl: {
    zh: '米蘭德斯文',
    originalLang: 'Mirandés',
    i18nKey: 'languages_code:mwl',
  },
  vec: {
    zh: '威尼斯文',
    originalLang: 'Vèneto',
    i18nKey: 'languages_code:vec',
  },
  mrj: {
    zh: '山地馬里文',
    originalLang: 'мары йылме',
    i18nKey: 'languages_code:mrj',
  },
  bh: {
    zh: '班加拉文',
    originalLang: 'भोजपुरी',
    i18nKey: 'languages_code:bh',
  },
  qu: {
    zh: '凱楚亞��',
    originalLang: 'Runa Simi',
    i18nKey: 'languages_code:qu',
  },
  bcl: {
    zh: '比科爾文',
    originalLang: 'Bikol',
    i18nKey: 'languages_code:bcl',
  },
  tyv: {
    zh: '圖瓦文',
    originalLang: 'Тыва дыл',
    i18nKey: 'languages_code:tyv',
  },
  ps: {
    zh: '普什圖文',
    originalLang: 'د ګراف کښلیکونکی',
    i18nKey: 'languages_code:ps',
  },
  nap: {
    zh: '拿波利文',
    originalLang: 'Napulitano',
    i18nKey: 'languages_code:nap',
  },
  co: {
    zh: '科西嘉文',
    originalLang: 'Coskari',
    i18nKey: 'languages_code:co',
  },
  mai: {
    zh: '馬伊蒂利文',
    originalLang: 'मैथिली',
    i18nKey: 'languages_code:mai',
  },
  sc: {
    zh: '薩丁尼亞文',
    originalLang: 'Sardu',
    i18nKey: 'languages_code:sc',
  },
  rue: {
    zh: '盧森尼亞文',
    originalLang: 'русиньскый язык',
    i18nKey: 'languages_code:rue',
  },
  pfl: {
    zh: '巴瓦利文',
    originalLang: 'Pfälzisch',
    i18nKey: 'languages_code:pfl',
  },
  myv: {
    zh: '厄爾茲亞馬里文',
    originalLang: 'эрзянь кель',
    i18nKey: 'languages_code:myv',
  },
  lrc: {
    zh: '祕魯馬拉伊文',
    originalLang: 'Irarutu',
    i18nKey: 'languages_code:lrc',
  },
  av: {
    zh: '阿瓦爾文',
    originalLang: 'авар мацӀ',
    i18nKey: 'languages_code:av',
  },
  krc: {
    zh: '卡拉恰伊巴爾卡爾文',
    originalLang: 'къарачай-малкъар тил',
    i18nKey: 'languages_code:krc',
  },
  vls: {
    zh: '西佛蘭德文',
    originalLang: 'West-Vlams',
    i18nKey: 'languages_code:vls',
  },
  dsb: {
    zh: '下索布文',
    originalLang: 'Dolnoserbšćina',
    i18nKey: 'languages_code:dsb',
  },
  frr: {
    zh: '北弗里西文',
    originalLang: 'Nordfriisk',
    i18nKey: 'languages_code:frr',
  },
} as const
