import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  failureAPIStatus,
  initialAPIStatus,
  successAPIStatus,
} from '@/constants/api-status'
import { APIStatus } from '@/types/api-status'
import {
  CampaignCase,
  CampaignCaseColumn,
  ResponseCampaignCaseList,
} from '@/types/campaign-case'

interface CampaignCaseState {
  invitingCampaignList: CampaignCaseList
  appliedCampaignList: CampaignCaseList
  runningCampaignList: CampaignCaseList
  selectedCampaignCase?: CampaignCase
}

interface CampaignCaseList {
  data?: CampaignCaseColumn[]
  total?: number
  page?: number
  apiStatus: APIStatus
}

const initialState: CampaignCaseState = {
  invitingCampaignList: {
    apiStatus: initialAPIStatus(),
  },
  appliedCampaignList: {
    apiStatus: initialAPIStatus(),
  },
  runningCampaignList: {
    apiStatus: initialAPIStatus(),
  },
}

const campaignCaseSlice = createSlice({
  name: 'campaignCase',
  initialState,
  reducers: {
    getInvitingCampaignListSuccess: (
      state,
      action: PayloadAction<ResponseCampaignCaseList>,
    ) => {
      state.invitingCampaignList.data = action.payload.data
      state.invitingCampaignList.total = action.payload.total
      state.invitingCampaignList.page = action.payload.page
      state.invitingCampaignList.apiStatus = successAPIStatus()
    },
    getInvitingCampaignListFailure: (state) => {
      state.invitingCampaignList.apiStatus = failureAPIStatus()
    },
    /**@todo: 因為聊天室清單需要而新增，未來全部改RTK再拔除 */
    getAppliedCampaignListSuccess: (
      state,
      action: PayloadAction<ResponseCampaignCaseList>,
    ) => {
      state.appliedCampaignList.data = action.payload.data
      state.appliedCampaignList.total = action.payload.total
      state.appliedCampaignList.page = action.payload.page
      state.appliedCampaignList.apiStatus = successAPIStatus()
    },
    getRunningCampaignListSuccess: (
      state,
      action: PayloadAction<ResponseCampaignCaseList>,
    ) => {
      state.runningCampaignList.data = action.payload.data
      state.runningCampaignList.total = action.payload.total
      state.runningCampaignList.page = action.payload.page
      state.runningCampaignList.apiStatus = successAPIStatus()
    },
    setSelectedCampaignCase: (state, action: PayloadAction<CampaignCase>) => {
      state.selectedCampaignCase = action.payload
    },
  },
})

export const {
  getInvitingCampaignListSuccess,
  getAppliedCampaignListSuccess,
  getRunningCampaignListSuccess,
  setSelectedCampaignCase,
} = campaignCaseSlice.actions

export default campaignCaseSlice.reducer
