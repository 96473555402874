import { IconNames, theme } from '@buggy/shared'
import { find } from 'lodash-es'
import { OurIconType } from '@/components/custom-icon'
import { PlatformType } from '@/types/schema/report-schema'

export enum PlatformShortcode {
  All = 'all',
  Blog = 'blog',
  Facebook = 'fb',
  FacebookPerson = 'fb_person',
  Instagram = 'ig',
  Other = 'other',
  TikTok = 'tiktok',
  YouTube = 'yt',
  Twitter = 'twitter',
}

export enum PlatformCode {
  All = 'all',
  Blog = 'blog',
  Facebook = 'facebook',
  Instagram = 'instagram',
  Other = 'other',
  TikTok = 'tiktok',
  YouTube = 'youtube',
  Twitter = 'twitter',
}

export interface Platform {
  code: PlatformCode
  shortcode: PlatformShortcode
  icon?: OurIconType
  inactiveIcon?: OurIconType
  cornerIcon?: OurIconType
  title: string
  type?: PlatformType
  /**
   * @todo Replace icon
   */
  sharedIconName?: IconNames
  color?: string
}

const platforms: Platform[] = [
  {
    code: PlatformCode.All,
    shortcode: PlatformShortcode.All,
    title: 'All',
    icon: 'elIconLayout1',
  },
  {
    type: PlatformType.Other,
    code: PlatformCode.Other,
    shortcode: PlatformShortcode.Other,
    title: 'Other',
  },
  { code: PlatformCode.Blog, shortcode: PlatformShortcode.Blog, title: 'Blog' },
  {
    code: PlatformCode.TikTok,
    shortcode: PlatformShortcode.TikTok,
    icon: 'elIconTiktok',
    inactiveIcon: 'elIconTiktokDeactivate',
    title: 'TikTok',
    type: PlatformType.TikTok,
    sharedIconName: 'brand-tiktok-filled',
    color: theme.colors.social.tiktok,
  },
  {
    code: PlatformCode.Facebook,
    shortcode: PlatformShortcode.Facebook,
    icon: 'elIconFacebook',
    inactiveIcon: 'elIconFacebookDeactivate',
    cornerIcon: 'fbCorner',
    title: 'Facebook',
    type: PlatformType.Facebook,
    sharedIconName: 'brand-facebook-filled',
    color: theme.colors.social.facebook,
  },
  {
    code: PlatformCode.Instagram,
    shortcode: PlatformShortcode.Instagram,
    icon: 'elIconInstagram',
    inactiveIcon: 'elIconInstagramDeactivate',
    cornerIcon: 'igCorner',
    title: 'Instagram',
    type: PlatformType.Instagram,
    sharedIconName: 'brand-instagram-filled',
    color: theme.colors.social.instagram,
  },
  {
    code: PlatformCode.YouTube,
    shortcode: PlatformShortcode.YouTube,
    icon: 'elIconYoutube',
    inactiveIcon: 'elIconYoutubeDeactivate',
    cornerIcon: 'ytCorner',
    title: 'YouTube',
    type: PlatformType.YouTube,
    sharedIconName: 'brand-youtube-filled',
    color: theme.colors.social.youtube,
  },
  {
    code: PlatformCode.Twitter,
    shortcode: PlatformShortcode.Twitter,
    icon: 'elIconTwitter',
    inactiveIcon: 'elIconTwitterDeactivate',
    title: 'Twitter',
    type: PlatformType.Twitter,
    sharedIconName: 'brand-twitter-filled',
    color: theme.colors.social.twitter,
  },
]

export const convertPlatformCode = (
  code: PlatformCode,
): Platform | undefined => {
  return find(platforms, { code })
}

/**
 * TODO: Should return optional
 * TODO: merge all convert
 */
export function convertPlatformShortcode(shortcode = ''): Platform {
  return (
    find(platforms, { shortcode: shortcode as PlatformShortcode }) ?? {
      code: shortcode as PlatformCode,
      shortcode: shortcode as PlatformShortcode,
      title: '',
    }
  )
}

export const convertPlatformType = (
  type: PlatformType,
): Platform | undefined => {
  return find(platforms, { type })
}
