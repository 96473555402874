import OtaClient from '@crowdin/ota-client'
import { defaults } from 'lodash-es'
import { useRouter } from 'next/router'
import { useAsync } from 'react-use'
import { AsyncState } from 'react-use/lib/useAsyncFn'
import { useAuth } from '@/hooks/use-auth'
import { I18nId, inContextPseudoLanguage, sourceLanguage } from '@/i18n/config'
import { i18nApi } from '@/i18n/i18n-api'
import { getLanguage, LanguageDict, languages } from '@/i18n/languages'
import sourceLocale from '@/i18n/locales/zh/locale.json'
import localeFileMeta from '@/i18n/locales/zh/source-file-meta.json'

function isSupportLanguage(lang: string): boolean {
  return languages.some((_lang) => _lang.code === lang)
}

export function getLanguageCode(lang: string = sourceLanguage): string {
  return isSupportLanguage(lang) ? lang : sourceLanguage
}

export function getLocaleRegion(locale: string): string {
  if (locale === 'ja') {
    return 'jp'
  } else if (locale === 'en') {
    return 'en'
  } else {
    return ''
  }
}

export const isI18nId = (id: string): id is I18nId => {
  return id in sourceLocale
}

export const getSourceLocaleFormatMessage = (key: string): string => {
  return isI18nId(key) ? sourceLocale[key] : key
}

const crowdinOtaClient = new OtaClient(
  process.env.NEXT_PUBLIC_CROWDIN_DISTRIBUTION_HASH,
)

export const getMessagesByLocale = async (
  locale: string,
): Promise<Record<string, string>> => {
  return locale === sourceLanguage || locale === inContextPseudoLanguage
    ? i18nApi.messages(locale)
    : defaults(
        await crowdinOtaClient
          .getLanguageTranslations(locale)
          .then((translations) => {
            // crowdin 的 ota 不會給我們 branch 的資訊，所以我們這邊用 / 切開去算數量，master 以外的 branch，path 會多一層 branch name
            const isMasterBranch = localeFileMeta.path.split('/').length === 3

            const targetTranslation = translations.find((translation) => {
              if (isMasterBranch) {
                return translation.file.split('/').length === 5
              }

              return translation.file.includes(localeFileMeta.path)
            })

            return targetTranslation?.content ?? translations[0].content
          }),
        sourceLocale,
      )
}

export const useI18n = (): {
  rootLang: string
  locale: string
  language: LanguageDict
  messagesState: AsyncState<Record<string, string>>
} => {
  const router = useRouter()
  let lang = router.query['lang']

  if (typeof lang !== 'string') {
    lang = sourceLanguage
  }

  const rootLang = getLanguageCode(lang)
  const { userInfo } = useAuth()

  const locale = userInfo?.language || rootLang
  const language = getLanguage(locale)

  const messagesState = useAsync(async (): Promise<Record<string, string>> => {
    return await getMessagesByLocale(language.i18nCode)
  }, [language.i18nCode])

  return { rootLang, locale, language, messagesState }
}
