import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { WorkspacesAPITagType } from '@/api/api'
import { searchApi } from '@/api/workspace-api/search-api'
import { RootState } from '@/store/store'

const updateKolCollectionKolQueryData = (
  state: RootState,
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  kolIds: string[],
  isCollected: boolean,
): void => {
  const endpointsToUpdate = searchApi.util.selectInvalidatedBy(state, [
    WorkspacesAPITagType.KolCollectionKol,
  ])

  endpointsToUpdate.forEach(({ endpointName, originalArgs }) => {
    switch (endpointName) {
      case 'searchSimilarKols':
        dispatch(
          searchApi.util.updateQueryData(
            endpointName,
            originalArgs,
            (draft) => {
              draft.list.forEach((kol) => {
                if (!kolIds.includes(kol.id)) {
                  return
                }

                kol.isCollectionKol = isCollected
              })
            },
          ),
        )
        break
    }
  })
}

export default updateKolCollectionKolQueryData
