import { Tooltip } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip'
import { isNumber } from 'lodash-es'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Icon } from '@/components/custom-icon'
import HighPotentialTag from '@/components/kol/common/high-potential-tag'
import RecommendationTag, {
  RecommendationTagProps,
} from '@/components/kol/common/recommendation-tag'
import { Potential } from '@/utils/detail/potential'
import useThumbnailAndNameBadgeInfo from '@/utils/hooks/use-thumbnail-and-name-badge-info'

interface KolNameBadgeProps {
  name: string
  potential?: Potential
  isVerified: boolean
  isRadarStar: boolean
  iconSize?: number
  badgeWidth?: number | string
  toolTipPlacement?: TooltipPlacement
  recommendation?: RecommendationTagProps['data']
}
const KolNameBadge: FunctionComponent<KolNameBadgeProps> = ({
  name,
  potential,
  isVerified,
  isRadarStar,
  iconSize = 14,
  badgeWidth = 130,
  toolTipPlacement = 'right',
  recommendation,
}) => {
  const { hasBadge, toolTipTitle, badgeType } = useThumbnailAndNameBadgeInfo(
    isVerified,
    isRadarStar,
  )
  return (
    <KOLNameWrapper badgeWidth={badgeWidth} iconSize={iconSize}>
      <KOLName className='kolName'>{name}</KOLName>
      {hasBadge && (
        <Tooltip placement={toolTipPlacement} title={toolTipTitle}>
          {!!badgeType && (
            <Icon
              className='badgeIcon'
              style={{ fontSize: iconSize }}
              type={badgeType}
            />
          )}
        </Tooltip>
      )}
      <HighPotentialTag potential={potential} size='small' />
      <RecommendationTag data={recommendation} size='small' />
    </KOLNameWrapper>
  )
}

const KOLNameWrapper = styled.span<{
  iconSize: number
  badgeWidth: number | string
}>`
  display: flex;
  align-items: center;
  gap: 4px;
  width: ${(props): string =>
    isNumber(props.badgeWidth) ? `${props.badgeWidth}px` : props.badgeWidth};
  i.badge {
    font-size: ${(props): string => `${props.iconSize}px`};
  }
`
export const KOLName = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #3a3d4c;
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export default KolNameBadge
