import { omit } from 'lodash-es'
import { useCallback } from 'react'
import { useIntl as originalUseIntl } from 'react-intl'
import { SupportedLanguages } from '@/i18n/config'
import { getLanguage } from '@/i18n/languages'
import {
  FORMAT_DATE_TYPE_OPTION_MAP,
  FormatDateRangeType,
  FormatDateType,
  OurIntlShape,
} from '@/i18n/typing'
import { getPercentageMaximumFractionDigits } from '@/utils/i18n/get-percentage-maximum-fraction-digits'
import getLocalizedDiscountRate from '@/utils/subscription-checkout/get-localized-discount-rate'

/**
 * 用來取代 `react-intl.useIntl` 具備 {id} compiler check typing
 */
export const useIntl: () => OurIntlShape = () => {
  const {
    formatNumber: originFormatNumber,
    formatDate: originFormatDate,
    ...rest
  } = originalUseIntl()

  const formatDate = useCallback(
    (
      value: Parameters<Intl.DateTimeFormat['format']>[0] | string,
      type?: FormatDateType,
    ): string => {
      return originFormatDate(
        value,
        FORMAT_DATE_TYPE_OPTION_MAP[type ?? 'default'],
      )
    },
    [originFormatDate],
  )

  return {
    formatNumber: useCallback(
      (value, opts): string => {
        // default value
        opts = {
          maximumFractionDigits: 0,
          ...opts,
        }

        if (Number.isNaN(value) && opts?.treatNaNAsZero) {
          value = 0
        }

        if (opts?.isCompactDisplay) {
          opts.notation = 'compact'
          opts.useGrouping = 'always'
          opts.maximumFractionDigits = 1
        }

        if (opts?.isPercentageDisplay ?? opts?.style === 'percent') {
          opts.style = 'percent'
          opts.maximumFractionDigits = getPercentageMaximumFractionDigits(value)
        }

        if (opts?.isDiscountRateDisplay) {
          const language = getLanguage(rest.locale).i18nCode
          const localizedDiscountRate = getLocalizedDiscountRate(
            value,
            language,
          )

          return language === SupportedLanguages.zhTW
            ? `${localizedDiscountRate}`
            : `${localizedDiscountRate}%`
        }

        return originFormatNumber(value, opts)
      },
      [originFormatNumber, rest.locale],
    ),
    formatDate,
    formatDateTimeRange: useCallback(
      (from, to, type?: FormatDateRangeType): string => {
        return formatDate(from, type) + ' - ' + formatDate(to, type)
      },
      [formatDate],
    ),
    ...omit(rest, ['formatDateTimeRange']),
  }
}
