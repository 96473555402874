import { z } from 'zod'

export const followerLevelSchema = z.enum([
  'level1',
  'level2',
  'level3',
  'level4',
  'level5',
])
type FollowerLevel = z.infer<typeof followerLevelSchema>

export default FollowerLevel
