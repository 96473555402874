import {
  createApi,
  defaultSerializeQueryArgs,
  fetchBaseQuery,
  SerializeQueryArgs,
} from '@reduxjs/toolkit/dist/query/react'
import { isEmpty, isEqual, omit } from 'lodash-es'
import querystring, { StringifyOptions } from 'query-string'
import { z } from 'zod'
import zodBaseQueryWrapper from '@/api/zod-base-query-wrapper'
import {
  AnchorTypeRequest,
  AnchorTypeResource,
  ListResource,
} from '@/types/resource-type'
import { listResourceSchema } from '@/types/schema/api/list-resource-schema'
import { prepareHeaders } from '@/utils/api-utils'

export enum CampaignsAPITagType {
  CampaignList = 'CampaignList',
  ApplyCampaignKolList = 'ApplyCampaignKolList',
}

export enum OAuthAPITagType {
  AuthPages = 'AuthPages',
  GoogleAccessToken = 'GoogleAccessToken',
}

export enum UserAPITagType {
  ContractStatus = 'ContractStatus',
  UserStatus = 'UserStatus',
  UserInfo = 'UserInfo',
  KolBasicInfo = 'KolBasicInfo',
  KolCooperateInfo = 'KolCooperateInfo',
  AgentKolList = 'AgentKolList',
  PubNubAccessToken = 'PubNubAccessToken',
  RecipientInfo = 'RecipientInfo',
  UserMetadata = 'UserMetadata',
}

export enum WorkspacesAPITagType {
  BrandRecognition = 'BrandRecognitionSnap',
  Campaign = 'Campaign',
  CampaignList = 'CampaignList',
  CampaignKolList = 'CampaignKolList',
  CampaignPaymentSetting = 'CampaignPaymentSetting',
  CreditCards = 'CreditCards',
  CollectionSettings = 'CollectionSettings',
  CommentBoard = 'CommentBoard',
  GoogleAnalyticsSetting = 'GoogleAnalyticsSetting',
  SubscriptionCases = 'SubscriptionCases',
  RecommendKolList = 'recommendKolList',
  RecommendKol = 'recommendKol',
  Report = 'Report',
  Subscription = 'Subscription',
  IrmRecommend = 'IrmRecommend',
  HashtagWatchlist = 'HashtagWatchlist',
  HashtagAnalyzeDetail = 'HashtagAnalyzeDetail',
  KolCollectionKol = 'KolCollectionKol',
  Collection = 'Collection',
  CollectionStatistic = 'CollectionStatistic',
  Collections = 'Collections',
  KolInfo = 'KolInfo',
  QuotaWalletUsage = 'QuotaWalletUsage',
  Workspace = 'Workspace',
  ProductReceipts = 'ProductReceipts',
  InfluencerRanking = 'InfluencerRanking',
  PostCollectionPostList = 'PostCollectionPostList',
  PostCollectionList = 'PostCollectionList',
  PostCollection = 'PostCollection',
  PostCollectionPost = 'PostCollectionPost',
  FeedbackAnalyze = 'FeedbackAnalyze',
  KolContent = 'KolContent',
  CompetitiveBrandAnalysesHashTagList = 'CompetitiveBrandAnalysesHashTagList',
  CompetitiveBrandKolStatisticList = 'CompetitiveBrandKolStatisticList',
  CompetitiveBrandKolStatisticListCharts = 'CompetitiveKolStatisticListCharts',
  CompetitiveAnalysisCrawlerStatus = 'CompetitiveAnalysisCrawlerStatus',
  CompetitiveMentionAnalysis = 'CompetitiveMentionAnalysis',
  CompetitiveMentionAnalysisList = 'CompetitiveMentionAnalysisList',
  CompetitiveMentionAnalysisKolList = 'CompetitiveMentionAnalysisKolList',
  CompetitiveMentionAnalysesCreatorList = 'CompetitiveMentionAnalysesCreatorList',
  CompetitiveMentionKolPropertyChartData = 'CompetitiveMentionKolPropertyChartData',
  CompetitiveMentionAnalysesHashTagList = 'CompetitiveMentionAnalysesHashTagList',
  CompetitiveKeywordAnalysis = 'CompetitiveKeywordAnalysis',
  CompetitiveKeywordAnalysisList = 'CompetitiveKeywordAnalysisList',
  CompetitiveKeywordAnalysisHashtagList = 'CompetitiveKeywordAnalysesHashtagList',
  CompetitiveKeywordAnalysisStatisticList = 'CompetitiveKeywordAnalysisStatisticList',
  CompetitiveKeywordAnalysesCreatorList = 'CompetitiveKeywordAnalysesCreatorList',
  CompetitiveKeywordAnalysisKeywordList = 'CompetitiveKeywordAnalysisKeywordList',
  CompetitiveKeywordStatisticListCharts = 'CompetitiveKeywordStatisticListCharts',
  CompetitiveKeywordKolCustomizedTagsNameChartData = 'CompetitiveKeywordKolCustomizedTagsNameChartData',
  TrialList = 'TrialList',
  TutorialList = 'TutorialList',
  HighlightFeatureList = 'HighlightFeatureList',
  SearchSimilarKols = 'SearchSimilarKols',
  BehaviorRecommendation = 'BehaviorRecommendation',
}

export enum PartnerAPITagType {
  CampaignList = 'CampaignList',
}

export enum InfluencerAPITagType {
  InfluencerInfo = 'InfluencerInfo',
  AgentInfo = 'AgentInfo',
  Accounting = 'Accounting',
}

export interface StringifyRequest
  extends Pick<StringifyOptions, 'arrayFormat'> {
  stringify: boolean
}

export interface CampaignInvitationResponse {
  succeeded: number
  failed: number
}

export interface BasicRequest {
  workspaceId: number
}

export interface PaginatedBasicRequest extends BasicRequest {
  page: number
  perPage?: number
}

const zodUnknownListResource = listResourceSchema.extend({
  data: z.array(z.unknown()),
})

type InfiniteConfigSetting = <
  RequestType,
  ResultType extends
    | ListResource<unknown>
    | z.infer<typeof zodUnknownListResource>
    | AnchorTypeResource<unknown>,
>() => {
  serializeQueryArgs: SerializeQueryArgs<RequestType>
  merge: (
    currentCache: ResultType,
    newResponse: ResultType,
    args: { arg: RequestType & AnchorTypeRequest },
  ) => void
  forceRefetch: (params: {
    currentArg: RequestType | undefined
    previousArg: RequestType | undefined
  }) => boolean
}

/** @description : general setting, 適合用在不需要控制 data 狀態的 infinite scroll, 需要控狀態建議另外客制 merge 邏輯  */
export const infiniteConfigSetting: InfiniteConfigSetting = () => {
  return {
    serializeQueryArgs: ({
      endpointName,
      queryArgs,
      endpointDefinition,
    }): string => {
      const argWithoutPagination = queryArgs
        ? omit(queryArgs, ['page', 'next_paging_token'])
        : undefined
      const cacheKey = defaultSerializeQueryArgs({
        endpointName,
        queryArgs: argWithoutPagination,
        endpointDefinition,
      })
      return cacheKey //infinite setting need to use the same endpointName
    },
    merge: (currentCache, newResponse, args): void => {
      let needReset = false
      if ('page' in newResponse) {
        needReset = newResponse.page === 1
      }
      if ('next_paging_token' in args.arg) {
        needReset = isEmpty(args.arg.next_paging_token)
      }
      const updateCache = {
        ...omit(newResponse, 'data'),
        data: needReset
          ? newResponse.data
          : [...currentCache.data, ...newResponse.data],
      }
      Object.assign(currentCache, updateCache)
    },
    // Refetch when the page arg changes
    forceRefetch({ currentArg, previousArg }): boolean {
      return !isEqual(currentArg, previousArg)
    },
  }
}

export const api = createApi({
  reducerPath: 'api',
  baseQuery: zodBaseQueryWrapper(
    fetchBaseQuery({
      baseUrl: `${process.env.NEXT_PUBLIC_NEXT_API_BASE_URL}/v2`,
      prepareHeaders,
      paramsSerializer: ({ stringify, ...params }) => {
        if (stringify) {
          return querystring.stringify(omit(params, 'array_format'), {
            arrayFormat: params['array_format'],
          })
        } else {
          return new URLSearchParams(params).toString()
        }
      },
    }),
  ),
  tagTypes: [
    ...Object.values(CampaignsAPITagType),
    ...Object.values(OAuthAPITagType),
    ...Object.values(PartnerAPITagType),
    ...Object.values(InfluencerAPITagType),
    ...Object.values(UserAPITagType),
    ...Object.values(WorkspacesAPITagType),
  ],
  endpoints: () => ({}),
})
