import * as Sentry from '@sentry/react'
import { omit } from 'lodash-es'
import { useEffect } from 'react'
import { useFetchUserStatusQuery } from '@/api/user-api'
import ZeusEnvironment from '@/constants/zeus-environment'
import { useAuth } from '@/hooks/use-auth'

// 來自 https://docs.sentry.io/platforms/javascript/legacy-sdk/tips/
const officialRecommendedIgnoreErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  'Script error.',
  // Avast extension error
  '_avast_submit',
]

const officialRecommendedDenyUrls = [
  // Google Adsense
  /pagead\/js/i,
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
]

type UseSentryInit = () => void

const useSentryInit: UseSentryInit = () => {
  const { data: userStatus } = useFetchUserStatusQuery()
  const { userInfo } = useAuth()

  // init sentry
  useEffect(() => {
    if (window.__SENTRY__?.hub) {
      // already init
      return
    }
    Sentry.init({
      release: process.env.NEXT_PUBLIC_VERSION,
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      environment:
        process.env.NEXT_PUBLIC_ZEUS_ENVIRONMENT === ZeusEnvironment.Development
          ? 'development'
          : process.env.NEXT_PUBLIC_ZEUS_ENVIRONMENT,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications',
        'Non-Error exception captured',
        ...officialRecommendedIgnoreErrors,
      ],
      denyUrls: [...officialRecommendedDenyUrls],
      normalizeDepth: 10,
      beforeBreadcrumb(breadcrumb, hint) {
        if (!breadcrumb?.category || !hint) {
          return breadcrumb
        }

        // ui.click 事件預設會帶 className 讓開發者識別元素，但 styled-component 在編譯過後的識別性很低，所以要客製化訊息
        // doc: https://docs.sentry.io/platforms/javascript/enriching-events/breadcrumbs/
        try {
          if (breadcrumb.category.startsWith('ui')) {
            breadcrumb.message = `${breadcrumb.message}: ${hint.event.target.innerText}`
          }
        } catch (e) {}
        return breadcrumb
      },
    })
  }, [])

  // set additional info for sentry
  useEffect(() => {
    Sentry.setTags({ service: 'app', version: process.env.NEXT_PUBLIC_VERSION })
    if (userInfo) {
      Sentry.setUser(userInfo)
    }
    if (userStatus?.currentWorkspace) {
      Sentry.setContext('workspace', {
        ...omit(userStatus.currentWorkspace, ['availableServices']),
        availableServices: JSON.stringify(
          userStatus.currentWorkspace.availableServices,
        ),
      })
    }
  }, [userInfo, userStatus?.currentWorkspace])
}

export default useSentryInit
