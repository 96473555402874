import { SupportedLanguages } from '@/i18n/config'

export const getIntlLocal = (language: string): string => {
  switch (language) {
    case SupportedLanguages.zhTW:
      return 'zh-Hant' // To use native Intl feature like notation compact display, we should provide correct local.
    default:
      return language
  }
}
