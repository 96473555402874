export const totalKOLs = 3000000
export const totalPosts = 1000000000
export const totalTaiwanKOLs = 250000
export const totalJapanKOLs = 750000
export const totalKoreaKols = 700000
export const totalMalaysiaKOLs = 1000000
export const totalHongKongKOLs = 10000
export const totalThaiKols = 150000
export const totalVietnamKols = 130000
export const totalSingaporeKols = 20000
