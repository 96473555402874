import { isEqual } from 'lodash-es'
import { FOLLOWER_COUNT_RANGE_RECORD } from '@/components/kol/common/follower-level-selector'

const getFollowerLevelByFollowerCountRange = (followerCountRange: {
  followerStartFrom: undefined | number
  followerEndTo: undefined | number
}): number | undefined => {
  if (isEqual(followerCountRange, FOLLOWER_COUNT_RANGE_RECORD.level1)) {
    return 1
  }
  if (isEqual(followerCountRange, FOLLOWER_COUNT_RANGE_RECORD.level2)) {
    return 2
  }
  if (isEqual(followerCountRange, FOLLOWER_COUNT_RANGE_RECORD.level3)) {
    return 3
  }
  if (isEqual(followerCountRange, FOLLOWER_COUNT_RANGE_RECORD.level4)) {
    return 4
  }
  if (isEqual(followerCountRange, FOLLOWER_COUNT_RANGE_RECORD.level5)) {
    return 5
  }

  return undefined
}

export default getFollowerLevelByFollowerCountRange
