import { Skeleton } from 'antd'
import { compact, isEmpty } from 'lodash-es'
import { DefaultOptionType } from 'rc-select/lib/Select'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { KolSearchDefault } from '@/components/search/kol-search-default'
import { DropdownKolDetail } from '@/components/search/search-nudge/dropdown-kol-detail'
import MatchedCustomizedTagNames from '@/components/search/search-nudge/matched-customized-tag-names'
import SearchOperatorSuggestions from '@/components/search/search-nudge/search-operator-suggestions'
import { SearchOperator } from '@/constants/search/search-operators'
import useCustomizedTag from '@/hooks/use-customized-tag'
import { QuickSearchKOL } from '@/types/api-v2-types'
import { Optional } from '@/types/optional'
import getSearchOperatorSuggestionsTrigger, {
  SearchOperatorSuggestionsTrigger,
} from '@/utils/search/get-search-operator-suggestions-trigger'

interface UseKolAutocompleteOptionsProps {
  searchOperatorSuggestionsEnabled?: boolean
}

interface UseKolAutocompleteOptions {
  (props?: UseKolAutocompleteOptionsProps): {
    getKolAutocompleteOptions: (
      searching: boolean,
      keyword: string,
      dataSource: QuickSearchKOL[],
      onClickCustomizedTagName: (customizedTagName: string) => void,
      onClickSearchOperatorSuggestion?: (
        operator: SearchOperator,
        trigger: SearchOperatorSuggestionsTrigger,
      ) => void,
    ) => KolAutocompleteOptionType[]
  }
}

export type KolAutocompleteOptionType = DefaultOptionType & {
  value: 'loading' | 'keyword' | 'customizedTag' | 'searchOperator' | string
}

const useKolAutocompleteOptions: UseKolAutocompleteOptions = (props) => {
  const { searchCustomizedTagNames } = useCustomizedTag()

  const getKolAutocompleteOptions = useCallback<
    ReturnType<UseKolAutocompleteOptions>['getKolAutocompleteOptions']
  >(
    (
      searching,
      keyword,
      dataSource,
      onClickCustomizedTagName,
      onClickSearchOperatorSuggestion,
    ) => {
      const searchOperatorSuggestionsTrigger =
        props?.searchOperatorSuggestionsEnabled
          ? getSearchOperatorSuggestionsTrigger(keyword)
          : undefined
      const searchOperatorSuggestionsOption: Optional<KolAutocompleteOptionType> =
        searchOperatorSuggestionsTrigger
          ? {
              key: 'searchOperator',
              value: 'searchOperator',
              label: (
                <SearchOperatorSuggestions
                  onClick={(operator): void =>
                    onClickSearchOperatorSuggestion?.(
                      operator,
                      searchOperatorSuggestionsTrigger,
                    )
                  }
                />
              ),
            }
          : undefined
      const customizedTagSearchResult = searchCustomizedTagNames(keyword)
      const customizedTagOption: KolAutocompleteOptionType | undefined =
        isEmpty(customizedTagSearchResult)
          ? undefined
          : {
              key: 'customizedTag',
              value: 'customizedTag',
              label: (
                <MatchedCustomizedTagNames
                  keyword={keyword}
                  names={customizedTagSearchResult}
                  onClick={onClickCustomizedTagName}
                />
              ),
            }

      const options: KolAutocompleteOptionType[] = searching
        ? [
            {
              disabled: true,
              key: 'Skeleton',
              value: 'loading',
              label: (
                <SkeletonWrapper>
                  <Skeleton
                    active
                    avatar={{ shape: 'circle' }}
                    paragraph={{
                      rows: 1,
                    }}
                    style={{ maxWidth: 650 }}
                  />
                </SkeletonWrapper>
              ),
            },
          ]
        : dataSource.map((kol) => {
            const name = kol.name[0] ?? ''
            const subNames = (kol.name.slice(1) ?? []).filter((subName) =>
              subName.toLowerCase().includes(keyword.toLowerCase()),
            )

            return {
              key: kol.uuid,
              value: kol.uuid,
              label: (
                <DropdownKolDetail
                  UUID={kol.uuid}
                  followerCount={kol.followerCount ?? 0}
                  isRadarStar={kol.isRadarStar}
                  isVerified={kol.isVerified}
                  name={name}
                  similarKolPlatform={kol.similarKolPlatform}
                  subNames={subNames}
                />
              ),
            }
          })

      const searchOption: KolAutocompleteOptionType = {
        key: 'keyword',
        value: 'keyword',
        label: <KolSearchDefault keyword={keyword} />,
      }

      return compact([
        searchOperatorSuggestionsOption,
        customizedTagOption,
        ...options,
        searchOption,
      ])
    },
    [props?.searchOperatorSuggestionsEnabled, searchCustomizedTagNames],
  )

  return {
    getKolAutocompleteOptions,
  }
}

const SkeletonWrapper = styled.div`
  padding: 16px 20px;
  width: 100%;

  .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
    height: 20px;
    margin: 0 0 4px 0 !important;
  }

  .ant-skeleton-with-avatar
    .ant-skeleton-content
    .ant-skeleton-title
    + .ant-skeleton-paragraph {
    margin: 0;
    height: 17px;
  }
`

export default useKolAutocompleteOptions
