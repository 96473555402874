import { Input, Radio, Slider } from 'antd'
import { RadioChangeEvent } from 'antd/es/radio'
import { InputProps } from 'antd/lib/input'
import isUndefined from 'lodash-es/isUndefined'
import toNumber from 'lodash-es/toNumber'
import React, { FunctionComponent, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'
import styled from 'styled-components'

const QUERY_UPPER_BOUND = 100000
const QUERY_LOWER_BOUND = -99999

const INPUT_UPPER_BOUND = 10000
const INPUT_LOWER_BOUND = 0

interface RateSelectorOption {
  max?: number
  min?: number
  label: string
}

interface RateSelectorProps {
  // undefined 在 UI 上代表空字串，但在打 API 的時候代表上下限，根據使用情境要做轉換
  value: {
    fromValue?: number
    endValue?: number
  }
  negativeInput?: boolean
  options: RateSelectorOption[]
  onValuesChange: (fromValue?: number, endValue?: number) => void
}

const parsePositiveNumber = (value: string): number => {
  return Math.abs(toNumber(value))
}

const RateSelectorContent: FunctionComponent<RateSelectorProps> = ({
  value,
  options,
  onValuesChange,
  negativeInput,
}) => {
  // 當值來自 queryString 時，有一瞬間會是 string
  const defaultValue: RateSelectorProps['value'] = {
    fromValue: isUndefined(value.fromValue)
      ? value.fromValue
      : Number(value.fromValue),
    endValue: isUndefined(value.endValue)
      ? value.endValue
      : Number(value.endValue),
  }
  const lowerBound = negativeInput ? QUERY_LOWER_BOUND : INPUT_LOWER_BOUND

  const [inputFromValue, setInputFromValue] = useState<number | undefined>()
  const [inputEndValue, setInputEndValue] = useState<number | undefined>()

  const handleValuesChange = (
    from: string | number | undefined,
    end: string | number | undefined,
  ): void => {
    const fromValue = ((): number | undefined => {
      if ([QUERY_LOWER_BOUND, '', undefined].includes(from)) {
        return undefined
      }

      const updateValue = toNumber(from)
      return updateValue < lowerBound ? lowerBound : updateValue
    })()
    const endValue = ((): number | undefined => {
      if ([QUERY_UPPER_BOUND, '', undefined].includes(end)) {
        return undefined
      }

      const updateValue = toNumber(end)
      return updateValue > INPUT_UPPER_BOUND ? INPUT_UPPER_BOUND : updateValue
    })()

    setInputFromValue(fromValue)
    setInputEndValue(endValue)
    const unlimited = isUndefined(fromValue) && isUndefined(endValue)

    onValuesChange(
      unlimited ? undefined : fromValue ?? QUERY_LOWER_BOUND,
      unlimited ? undefined : endValue ?? QUERY_UPPER_BOUND,
    )
  }
  const handleOnInputBlur = (): void => {
    const isSwap =
      (inputFromValue ?? lowerBound) > (inputEndValue ?? INPUT_UPPER_BOUND)

    if (isSwap) {
      setInputFromValue(inputEndValue)
      setInputEndValue(inputFromValue)
      onValuesChange(inputEndValue, inputFromValue)
    }
  }

  const handleSliderChange = ([from, end]: [number, number]): void =>
    handleValuesChange(from, end)

  const handleSelectRange = (event: RadioChangeEvent): void => {
    const minMaxString = event.target.value
    const [min, max] = minMaxString
      .split('-')
      .map((value?: string | number) => {
        if (value === 'undefined') {
          return undefined
        }
        return value
      })
    handleValuesChange(min, max)
  }

  // props: value changed
  useDeepCompareEffect(() => {
    handleValuesChange(defaultValue.fromValue, defaultValue.endValue)
  }, [defaultValue])

  return (
    <RateSelectorContentWrapper>
      <InputWrapper>
        <StyledInput
          max={INPUT_UPPER_BOUND}
          min={lowerBound}
          placeholder='From'
          suffix='%'
          type='number'
          value={inputFromValue}
          onBlur={handleOnInputBlur}
          onChange={(event): void => {
            handleValuesChange(
              negativeInput
                ? event.target.value
                : parsePositiveNumber(event.target.value),
              inputEndValue,
            )
          }}
        />
        <Hyphen> - </Hyphen>
        <StyledInput
          max={INPUT_UPPER_BOUND}
          min={lowerBound}
          placeholder='To'
          suffix='%'
          type='number'
          value={inputEndValue}
          onBlur={handleOnInputBlur}
          onChange={(event): void => {
            handleValuesChange(
              inputFromValue,
              negativeInput
                ? event.target.value
                : parsePositiveNumber(event.target.value),
            )
          }}
        />
        <Slider
          range
          max={INPUT_UPPER_BOUND}
          min={INPUT_LOWER_BOUND}
          value={[
            inputFromValue ?? INPUT_LOWER_BOUND,
            inputEndValue ?? INPUT_UPPER_BOUND,
          ]}
          onChange={handleSliderChange}
        />
        <RateRadioWrapper>
          <Radio.Group
            options={options.map((option: RateSelectorOption) => ({
              label: option.label,
              value: `${option.min}-${option.max}`,
            }))}
            value={`${inputFromValue}-${inputEndValue}`}
            onChange={handleSelectRange}
          />
        </RateRadioWrapper>
      </InputWrapper>
    </RateSelectorContentWrapper>
  )
}

const StyledInput = styled((props: InputProps) => <Input {...props} />)`
  width: 45%;
`

const InputWrapper = styled.div`
  width: 100%;

  .ant-slider-track {
    background-color: ${({ theme }): string => theme.colors.brand.primary};
  }

  .ant-slider-handle {
    border-color: ${({ theme }): string => theme.colors.brand.primary};
  }
`

const Hyphen = styled.span`
  padding: 3px 2%;
`

export const RateRadioWrapper = styled.div`
  label {
    display: block;
    margin-bottom: 20px;
    line-height: normal !important;
    height: auto !important;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const RateSelectorContentWrapper = styled.div`
  width: 100%;

  ${RateRadioWrapper} {
    margin: 14px 0;
  }
`

export default RateSelectorContent
