import { sortBy, values } from 'lodash-es'
import { I18nId } from '@/i18n/config'
import { SearchForKolProperties } from '@/utils/ampli'

type BusinessAmpliType = NonNullable<
  SearchForKolProperties['filterOfficialAccount']
>[0]

enum BusinessType {
  Restaurants = 1,
  ProfessionalServices = 2,
  GroceryAndConvenienceStores = 3,
  NonProfitsAndReligiousOrganizations = 4,
  LocalEvents = 5,
  Publishers = 6,
  BusinessAndUtilityServices = 7,
  TransportationAndAccommodationServices = 8,
  HomeGoodsStores = 9,
  ContentAndApps = 10,
  AutoDealers = 11,
  GovernmentAgencies = 12,
  Entities = 13,
  Networking = 14,
  Geography = 15,
  Politician = 16,
  Media = 17,
  Brand = 18,
}

export interface BusinessGeneralOption {
  value: string
  label: I18nId
  ampliType: BusinessAmpliType
  order: number
}

export const BUSINESS_GENERAL_TAG_CODE_RECORD: Record<
  BusinessType,
  BusinessGeneralOption
> = {
  [BusinessType.Restaurants]: {
    label: 'business_type:restaurants',
    ampliType: 'Restaurants',
    value: `${BusinessType.Restaurants}`,
    order: 4,
  },
  [BusinessType.ProfessionalServices]: {
    label: 'business_type:professional_services',
    ampliType: 'Professional Services',
    value: `${BusinessType.ProfessionalServices}`,
    order: 5,
  },
  [BusinessType.GroceryAndConvenienceStores]: {
    label: 'business_type:grocery_and_convenience_stores',
    ampliType: 'Grocery & Convenience Stores',
    value: `${BusinessType.GroceryAndConvenienceStores}`,
    order: 6,
  },
  [BusinessType.NonProfitsAndReligiousOrganizations]: {
    label: 'business_type:non_profits_and_religious_organizations',
    ampliType: 'Non-Profits & Religious Organizations',
    value: `${BusinessType.NonProfitsAndReligiousOrganizations}`,
    order: 7,
  },
  [BusinessType.LocalEvents]: {
    label: 'business_type:local_events',
    ampliType: 'Local Events',
    value: `${BusinessType.LocalEvents}`,
    order: 8,
  },
  [BusinessType.Publishers]: {
    label: 'business_type:publishers',
    ampliType: 'Publishers',
    value: `${BusinessType.Publishers}`,
    order: 9,
  },
  [BusinessType.BusinessAndUtilityServices]: {
    label: 'business_type:business_and_utility_services',
    ampliType: 'Business & Utility Services',
    value: `${BusinessType.BusinessAndUtilityServices}`,
    order: 10,
  },
  [BusinessType.TransportationAndAccommodationServices]: {
    label: 'business_type:transportation_and_ accommodation_services',
    ampliType: 'Transportation & Accommodation Services',
    value: `${BusinessType.TransportationAndAccommodationServices}`,
    order: 11,
  },
  [BusinessType.HomeGoodsStores]: {
    label: 'business_type:home_goods_stores',
    ampliType: 'Home Goods Stores',
    value: `${BusinessType.HomeGoodsStores}`,
    order: 12,
  },
  [BusinessType.ContentAndApps]: {
    label: 'business_type:content_and_apps',
    ampliType: 'Content & Apps',
    value: `${BusinessType.ContentAndApps}`,
    order: 13,
  },
  [BusinessType.AutoDealers]: {
    label: 'business_type:auto_dealers',
    ampliType: 'Auto Dealers',
    value: `${BusinessType.AutoDealers}`,
    order: 14,
  },
  [BusinessType.GovernmentAgencies]: {
    label: 'business_type:government_agencies',
    ampliType: 'Government Agencies',
    value: `${BusinessType.GovernmentAgencies}`,
    order: 15,
  },
  [BusinessType.Entities]: {
    label: 'business_type:entities',
    ampliType: 'Entities',
    value: `${BusinessType.Entities}`,
    order: 16,
  },
  [BusinessType.Networking]: {
    label: 'business_type:networking',
    ampliType: 'Networking',
    value: `${BusinessType.Networking}`,
    order: 17,
  },
  [BusinessType.Geography]: {
    label: 'business_type:geography',
    ampliType: 'Geography',
    value: `${BusinessType.Geography}`,
    order: 18,
  },
  [BusinessType.Politician]: {
    label: 'business_type:politician',
    ampliType: 'Politician',
    value: `${BusinessType.Politician}`,
    order: 2,
  },
  [BusinessType.Media]: {
    label: 'business_type:media',
    ampliType: 'Media',
    value: `${BusinessType.Media}`,
    order: 3,
  },
  [BusinessType.Brand]: {
    label: 'business_type:brand',
    ampliType: 'Brand',
    value: `${BusinessType.Brand}`,
    order: 1,
  },
}
export const BUSINESS_OPTIONS: BusinessGeneralOption[] = sortBy(
  values(BUSINESS_GENERAL_TAG_CODE_RECORD),
  'order',
)

export const ALL_BUSINESS_OPTION_VALUES = BUSINESS_OPTIONS.map((x) => x.value)
