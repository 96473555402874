import { Input, Radio, Slider } from 'antd'
import { InputProps } from 'antd/lib/input'
import { RadioChangeEvent } from 'antd/lib/radio/interface'
import { isUndefined, toNumber } from 'lodash-es'
import React, { FunctionComponent, useCallback, useState } from 'react'
import { useDebounce, useDeepCompareEffect } from 'react-use'
import styled from 'styled-components'
import { FollowerOption, FollowerRange } from '@/constants/follower-range'
import { FollowerFilter } from '@/constants/search/follower-filter'
import { useIntl } from '@/i18n/hooks/use-intl'
import { Condition } from '@/types/api/search'
import { ampli } from '@/utils/ampli'
import getFollowerLevelByFollowerCountRange from '@/utils/kol/get-follower-level-by-follower-count-range'

interface FansSelectorContentProps {
  filterValues: Pick<Condition, 'follower_start_from' | 'follower_end_to'>
  onValuesChange: (changedValues: Condition) => void
}

const FansSelectorContent: FunctionComponent<FansSelectorContentProps> = ({
  filterValues,
  onValuesChange,
}) => {
  const { formatMessage } = useIntl()

  const [currentFromValue, setCurrentFromValue] = useState<number>(
    filterValues.follower_start_from ?? FollowerFilter.LowerBound,
  )

  const [currentEndValue, setCurrentEndValue] = useState<number>(
    filterValues.follower_end_to ?? FollowerFilter.UpperBound,
  )

  const onFollowerSliderChange = ([from, end]: [number, number]): void => {
    ampli.fansSelector({ type: 'slider' })
    setCurrentFromValue(from)
    setCurrentEndValue(end)
    handleValuesChange(from, end)
  }

  const handleSelectRange = (e: RadioChangeEvent): void => {
    const minMaxString = e.target.value
    const [fromValue, endValue] = minMaxString
      .split('~')
      .map((value?: string | number) => {
        if (value === 'undefined') {
          return undefined
        }
        return value
      })

    setCurrentFromValue(toNumber(fromValue))
    setCurrentEndValue(toNumber(endValue))
    handleValuesChange(toNumber(fromValue), toNumber(endValue))
  }

  const handleValuesChange = useCallback(
    (fromValue?: number, endValue?: number): void => {
      const from = fromValue ?? FollowerFilter.LowerBound
      const end = endValue ?? FollowerFilter.UpperBound

      const queryCondition =
        from === FollowerFilter.LowerBound && end === FollowerFilter.UpperBound
          ? {
              follower_start_from: undefined,
              follower_end_to: undefined,
            }
          : {
              follower_start_from: from,
              follower_end_to: end,
            }

      const followerRangeLevel = getFollowerLevelByFollowerCountRange({
        followerStartFrom: queryCondition.follower_start_from,
        followerEndTo: queryCondition.follower_end_to,
      })

      onValuesChange({
        ...queryCondition,
        followerRangeLevel,
      })
    },
    [onValuesChange],
  )

  // 如果 from 比 end 大，就交換兩者數值
  useDebounce(
    () => {
      if (
        isUndefined(currentFromValue) ||
        isUndefined(currentEndValue) ||
        currentFromValue <= currentEndValue
      ) {
        return
      }

      setCurrentFromValue(currentEndValue)
      setCurrentEndValue(currentFromValue)
      handleValuesChange(currentEndValue, currentFromValue)
    },
    500,
    [currentFromValue, currentEndValue],
  )

  // props: filterValues changed
  useDeepCompareEffect(() => {
    setCurrentFromValue(
      filterValues.follower_start_from ?? FollowerFilter.LowerBound,
    )
    setCurrentEndValue(
      filterValues.follower_end_to ?? FollowerFilter.UpperBound,
    )
  }, [filterValues])

  return (
    <SelectWrapper>
      <InputWrapper>
        <StyledInput
          min={FollowerFilter.LowerBound}
          type='number'
          value={currentFromValue}
          onChange={(event): void => {
            setCurrentFromValue(toNumber(event.target.value))
            handleValuesChange(toNumber(event.target.value), currentEndValue)
          }}
        />
        <Hyphen> - </Hyphen>
        <StyledInput
          min={FollowerFilter.LowerBound}
          type='number'
          value={currentEndValue}
          onChange={(event): void => {
            setCurrentEndValue(toNumber(event.target.value))
            handleValuesChange(currentFromValue, toNumber(event.target.value))
          }}
        />
        <Slider
          range
          max={FollowerFilter.UpperBound}
          min={FollowerFilter.LowerBound}
          value={[
            currentFromValue ?? FollowerFilter.LowerBound,
            currentEndValue ?? FollowerFilter.UpperBound,
          ]}
          onChange={onFollowerSliderChange}
        />
        <RadioWrapper>
          <Radio.Group
            options={FollowerRange.map((option: FollowerOption) => ({
              label: formatMessage({ id: option.label }),
              value: option.value,
            }))}
            value={`${currentFromValue}~${currentEndValue}`}
            onChange={handleSelectRange}
          />
        </RadioWrapper>
      </InputWrapper>
    </SelectWrapper>
  )
}

export default FansSelectorContent

const StyledInput = styled((props: InputProps) => <Input {...props} />)`
  width: 45%;
`

const InputWrapper = styled.div`
  max-width: 420px;

  .ant-slider-track {
    background-color: ${({ theme }): string => theme.colors.brand.primary};
  }

  .ant-slider-handle {
    border-color: ${({ theme }): string => theme.colors.brand.primary};
  }
`

const Hyphen = styled.span`
  padding: 3px 2%;
`

const SelectWrapper = styled.div`
  width: 100%;
`

const RadioWrapper = styled.div`
  label {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    line-height: normal !important;
    height: auto !important;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
