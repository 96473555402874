import { theme } from '@buggy/shared'

export function clickLabelStatus(
  checked?: boolean,
  disabled?: boolean,
): string {
  return disabled
    ? theme.colors.text.placeholder
    : checked
      ? theme.colors.brand.secondary
      : theme.colors.text.secondary
}
