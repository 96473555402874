import {
  DestinationPlugin,
  Event,
  PluginType,
  Result,
} from '@amplitude/analytics-types'
import isNil from 'lodash-es/isNil'
import { decodeBoolean } from 'serialize-query-params'

class GeneralEventPropertiesPlugin implements DestinationPlugin {
  name = 'general-event-properties'
  type = PluginType.DESTINATION as const

  /**
   * setup() is called on plugin installation
   * example: client.add(new AddEventIdPlugin());
   */
  setup(): Promise<undefined> {
    return Promise.resolve(undefined)
  }

  /**
   * execute() is called on each event instrumented
   * example: client.track('New Event');
   */
  execute(event: Event): Promise<Result> {
    const searchParams = new URLSearchParams(window.location.search)
    const searchId = searchParams.get('searchId') ?? undefined
    const isUnlocked = decodeBoolean(searchParams.get('isUnlocked'))
    const kolId = location.pathname.startsWith('/kol/')
      ? location.pathname.split('/')[2]
      : undefined
    const path = location.pathname

    // 外面送進來的權重更高，如果已經有了，那就不要覆蓋他
    if (
      searchId &&
      event.event_properties &&
      !event.event_properties['searchId']
    ) {
      event.event_properties['searchId'] = searchId
    }
    if (kolId && event.event_properties && !event.event_properties['kolId']) {
      event.event_properties['kolId'] = kolId
    }
    if (path && event.event_properties && !event.event_properties['path']) {
      event.event_properties['path'] = path
    }

    if (
      kolId &&
      !isNil(isUnlocked) &&
      event.event_properties &&
      !event.event_properties['isUnlocked']
    ) {
      event.event_properties['isUnlocked'] = isUnlocked
    }

    return Promise.resolve({
      event,
      code: 200,
      message: '',
    })
  }
}

export default GeneralEventPropertiesPlugin
