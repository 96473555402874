import { z as P } from "zod";
var Q = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function kt(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
function ap(e) {
  if (e.__esModule)
    return e;
  var t = e.default;
  if (typeof t == "function") {
    var r = function n() {
      return this instanceof n ? Reflect.construct(t, arguments, this.constructor) : t.apply(this, arguments);
    };
    r.prototype = t.prototype;
  } else
    r = {};
  return Object.defineProperty(r, "__esModule", { value: !0 }), Object.keys(e).forEach(function(n) {
    var a = Object.getOwnPropertyDescriptor(e, n);
    Object.defineProperty(r, n, a.get ? a : {
      enumerable: !0,
      get: function() {
        return e[n];
      }
    });
  }), r;
}
const Ye = P.enum([
  "payment-outlined",
  "ai-badge-outlined",
  "ai-badge-purple-gradient-outlined",
  "arrow-forward-outlined",
  "product-outlined",
  "sort-outlined",
  "bullhorn-outlined",
  "book-mark-outlined",
  "circle-check-filled",
  "star-box-outlined",
  "badge-radar-star-filled",
  "badge-radar-verified-filled",
  "crown-outlined",
  "crown-filled",
  "edit-outlined",
  "edit-2-outlined",
  "brand-blogger-filled",
  "brand-sina-filled",
  "brand-plurk-filled",
  "brand-instagram-filled",
  "brand-instagram-inactive-filled",
  "brand-facebook-filled",
  "brand-facebook-inactive-filled",
  "brand-facebook-circle-filled",
  "brand-youtube-filled",
  "brand-youtube-button-filled",
  "brand-youtube-inactive-filled",
  "brand-twitch-filled",
  "brand-twitter-filled",
  "brand-thread-filled",
  "brand-tiktok-filled",
  "brand-google-filled",
  "brand-tiktok-inactive-filled",
  "brand-twitter-inactive-filled",
  "radar-chart-filled",
  "film-outlined",
  "key-outlined",
  "user-outlined",
  "home-outlined",
  "hand-shake-outlined",
  "user-square-outlined",
  "target-outlined",
  "bulb-on-outlined",
  "conversation-outlined",
  "rocket-outlined",
  "analytics-outlined",
  "face-open-mouth-outlined",
  "tags-filled",
  "no-data-outlined",
  "cooperation-post-outlined",
  "cooperation-video-outlined",
  "cooperation-live-outlined",
  "cooperation-event-outlined",
  "dollar-circle-outlined",
  "resume-outlined",
  "save-to-collection-outlined",
  "save-to-collection-filled",
  "table-outlined",
  "shopping-bag-outlined",
  "flag-outlined",
  "checks-outlined",
  "trending-plateau",
  "top-1-filled",
  "top-2-filled",
  "top-3-filled",
  "brand-facebook-corner-filled",
  "brand-instagram-corner-filled",
  "brand-youtube-corner-filled",
  "flag-jp-filled",
  "flag-th-filled",
  "flag-tw-filled",
  "flag-us-filled",
  "search-kol-filled",
  "social-performance-filled",
  "marketing-trend-filled",
  "report-performance-filled",
  "predict-competitor-filled"
]);
function Dr() {
  this.__data__ = [], this.size = 0;
}
var Lr = Dr;
function Fr(e, t) {
  return e === t || e !== e && t !== t;
}
var Ht = Fr, jr = Ht;
function Mr(e, t) {
  for (var r = e.length; r--; )
    if (jr(e[r][0], t))
      return r;
  return -1;
}
var oe = Mr, Br = oe, Ur = Array.prototype, kr = Ur.splice;
function Hr(e) {
  var t = this.__data__, r = Br(t, e);
  if (r < 0)
    return !1;
  var n = t.length - 1;
  return r == n ? t.pop() : kr.call(t, r, 1), --this.size, !0;
}
var Kr = Hr, qr = oe;
function Gr(e) {
  var t = this.__data__, r = qr(t, e);
  return r < 0 ? void 0 : t[r][1];
}
var zr = Gr, Jr = oe;
function Vr(e) {
  return Jr(this.__data__, e) > -1;
}
var Wr = Vr, Xr = oe;
function Yr(e, t) {
  var r = this.__data__, n = Xr(r, e);
  return n < 0 ? (++this.size, r.push([e, t])) : r[n][1] = t, this;
}
var Qr = Yr, Zr = Lr, en = Kr, tn = zr, rn = Wr, nn = Qr;
function j(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
j.prototype.clear = Zr;
j.prototype.delete = en;
j.prototype.get = tn;
j.prototype.has = rn;
j.prototype.set = nn;
var ce = j, an = ce;
function sn() {
  this.__data__ = new an(), this.size = 0;
}
var on = sn;
function cn(e) {
  var t = this.__data__, r = t.delete(e);
  return this.size = t.size, r;
}
var un = cn;
function ln(e) {
  return this.__data__.get(e);
}
var fn = ln;
function dn(e) {
  return this.__data__.has(e);
}
var pn = dn, hn = typeof Q == "object" && Q && Q.Object === Object && Q, Kt = hn, yn = Kt, mn = typeof self == "object" && self && self.Object === Object && self, bn = yn || mn || Function("return this")(), x = bn, gn = x, _n = gn.Symbol, ue = _n, Qe = ue, qt = Object.prototype, vn = qt.hasOwnProperty, $n = qt.toString, q = Qe ? Qe.toStringTag : void 0;
function Sn(e) {
  var t = vn.call(e, q), r = e[q];
  try {
    e[q] = void 0;
    var n = !0;
  } catch {
  }
  var a = $n.call(e);
  return n && (t ? e[q] = r : delete e[q]), a;
}
var wn = Sn, An = Object.prototype, En = An.toString;
function Tn(e) {
  return En.call(e);
}
var On = Tn, Ze = ue, Cn = wn, Pn = On, Rn = "[object Null]", xn = "[object Undefined]", et = Ze ? Ze.toStringTag : void 0;
function In(e) {
  return e == null ? e === void 0 ? xn : Rn : et && et in Object(e) ? Cn(e) : Pn(e);
}
var J = In;
function Nn(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var le = Nn, Dn = J, Ln = le, Fn = "[object AsyncFunction]", jn = "[object Function]", Mn = "[object GeneratorFunction]", Bn = "[object Proxy]";
function Un(e) {
  if (!Ln(e))
    return !1;
  var t = Dn(e);
  return t == jn || t == Mn || t == Fn || t == Bn;
}
var Gt = Un, kn = x, Hn = kn["__core-js_shared__"], Kn = Hn, ve = Kn, tt = function() {
  var e = /[^.]+$/.exec(ve && ve.keys && ve.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function qn(e) {
  return !!tt && tt in e;
}
var Gn = qn, zn = Function.prototype, Jn = zn.toString;
function Vn(e) {
  if (e != null) {
    try {
      return Jn.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var zt = Vn, Wn = Gt, Xn = Gn, Yn = le, Qn = zt, Zn = /[\\^$.*+?()[\]{}|]/g, ea = /^\[object .+?Constructor\]$/, ta = Function.prototype, ra = Object.prototype, na = ta.toString, aa = ra.hasOwnProperty, sa = RegExp(
  "^" + na.call(aa).replace(Zn, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function ia(e) {
  if (!Yn(e) || Xn(e))
    return !1;
  var t = Wn(e) ? sa : ea;
  return t.test(Qn(e));
}
var oa = ia;
function ca(e, t) {
  return e == null ? void 0 : e[t];
}
var ua = ca, la = oa, fa = ua;
function da(e, t) {
  var r = fa(e, t);
  return la(r) ? r : void 0;
}
var M = da, pa = M, ha = x, ya = pa(ha, "Map"), je = ya, ma = M, ba = ma(Object, "create"), fe = ba, rt = fe;
function ga() {
  this.__data__ = rt ? rt(null) : {}, this.size = 0;
}
var _a = ga;
function va(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var $a = va, Sa = fe, wa = "__lodash_hash_undefined__", Aa = Object.prototype, Ea = Aa.hasOwnProperty;
function Ta(e) {
  var t = this.__data__;
  if (Sa) {
    var r = t[e];
    return r === wa ? void 0 : r;
  }
  return Ea.call(t, e) ? t[e] : void 0;
}
var Oa = Ta, Ca = fe, Pa = Object.prototype, Ra = Pa.hasOwnProperty;
function xa(e) {
  var t = this.__data__;
  return Ca ? t[e] !== void 0 : Ra.call(t, e);
}
var Ia = xa, Na = fe, Da = "__lodash_hash_undefined__";
function La(e, t) {
  var r = this.__data__;
  return this.size += this.has(e) ? 0 : 1, r[e] = Na && t === void 0 ? Da : t, this;
}
var Fa = La, ja = _a, Ma = $a, Ba = Oa, Ua = Ia, ka = Fa;
function B(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
B.prototype.clear = ja;
B.prototype.delete = Ma;
B.prototype.get = Ba;
B.prototype.has = Ua;
B.prototype.set = ka;
var Ha = B, nt = Ha, Ka = ce, qa = je;
function Ga() {
  this.size = 0, this.__data__ = {
    hash: new nt(),
    map: new (qa || Ka)(),
    string: new nt()
  };
}
var za = Ga;
function Ja(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
var Va = Ja, Wa = Va;
function Xa(e, t) {
  var r = e.__data__;
  return Wa(t) ? r[typeof t == "string" ? "string" : "hash"] : r.map;
}
var de = Xa, Ya = de;
function Qa(e) {
  var t = Ya(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
var Za = Qa, es = de;
function ts(e) {
  return es(this, e).get(e);
}
var rs = ts, ns = de;
function as(e) {
  return ns(this, e).has(e);
}
var ss = as, is = de;
function os(e, t) {
  var r = is(this, e), n = r.size;
  return r.set(e, t), this.size += r.size == n ? 0 : 1, this;
}
var cs = os, us = za, ls = Za, fs = rs, ds = ss, ps = cs;
function U(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
U.prototype.clear = us;
U.prototype.delete = ls;
U.prototype.get = fs;
U.prototype.has = ds;
U.prototype.set = ps;
var Me = U, hs = ce, ys = je, ms = Me, bs = 200;
function gs(e, t) {
  var r = this.__data__;
  if (r instanceof hs) {
    var n = r.__data__;
    if (!ys || n.length < bs - 1)
      return n.push([e, t]), this.size = ++r.size, this;
    r = this.__data__ = new ms(n);
  }
  return r.set(e, t), this.size = r.size, this;
}
var _s = gs, vs = ce, $s = on, Ss = un, ws = fn, As = pn, Es = _s;
function k(e) {
  var t = this.__data__ = new vs(e);
  this.size = t.size;
}
k.prototype.clear = $s;
k.prototype.delete = Ss;
k.prototype.get = ws;
k.prototype.has = As;
k.prototype.set = Es;
var Jt = k, Ts = "__lodash_hash_undefined__";
function Os(e) {
  return this.__data__.set(e, Ts), this;
}
var Cs = Os;
function Ps(e) {
  return this.__data__.has(e);
}
var Rs = Ps, xs = Me, Is = Cs, Ns = Rs;
function ne(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.__data__ = new xs(); ++t < r; )
    this.add(e[t]);
}
ne.prototype.add = ne.prototype.push = Is;
ne.prototype.has = Ns;
var Ds = ne;
function Ls(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n; )
    if (t(e[r], r, e))
      return !0;
  return !1;
}
var Fs = Ls;
function js(e, t) {
  return e.has(t);
}
var Ms = js, Bs = Ds, Us = Fs, ks = Ms, Hs = 1, Ks = 2;
function qs(e, t, r, n, a, s) {
  var i = r & Hs, o = e.length, f = t.length;
  if (o != f && !(i && f > o))
    return !1;
  var l = s.get(e), d = s.get(t);
  if (l && d)
    return l == t && d == e;
  var u = -1, y = !0, v = r & Ks ? new Bs() : void 0;
  for (s.set(e, t), s.set(t, e); ++u < o; ) {
    var p = e[u], h = t[u];
    if (n)
      var b = i ? n(h, p, u, t, e, s) : n(p, h, u, e, t, s);
    if (b !== void 0) {
      if (b)
        continue;
      y = !1;
      break;
    }
    if (v) {
      if (!Us(t, function(g, S) {
        if (!ks(v, S) && (p === g || a(p, g, r, n, s)))
          return v.push(S);
      })) {
        y = !1;
        break;
      }
    } else if (!(p === h || a(p, h, r, n, s))) {
      y = !1;
      break;
    }
  }
  return s.delete(e), s.delete(t), y;
}
var Vt = qs, Gs = x, zs = Gs.Uint8Array, Js = zs;
function Vs(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n, a) {
    r[++t] = [a, n];
  }), r;
}
var Ws = Vs;
function Xs(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n) {
    r[++t] = n;
  }), r;
}
var Ys = Xs, at = ue, st = Js, Qs = Ht, Zs = Vt, ei = Ws, ti = Ys, ri = 1, ni = 2, ai = "[object Boolean]", si = "[object Date]", ii = "[object Error]", oi = "[object Map]", ci = "[object Number]", ui = "[object RegExp]", li = "[object Set]", fi = "[object String]", di = "[object Symbol]", pi = "[object ArrayBuffer]", hi = "[object DataView]", it = at ? at.prototype : void 0, $e = it ? it.valueOf : void 0;
function yi(e, t, r, n, a, s, i) {
  switch (r) {
    case hi:
      if (e.byteLength != t.byteLength || e.byteOffset != t.byteOffset)
        return !1;
      e = e.buffer, t = t.buffer;
    case pi:
      return !(e.byteLength != t.byteLength || !s(new st(e), new st(t)));
    case ai:
    case si:
    case ci:
      return Qs(+e, +t);
    case ii:
      return e.name == t.name && e.message == t.message;
    case ui:
    case fi:
      return e == t + "";
    case oi:
      var o = ei;
    case li:
      var f = n & ri;
      if (o || (o = ti), e.size != t.size && !f)
        return !1;
      var l = i.get(e);
      if (l)
        return l == t;
      n |= ni, i.set(e, t);
      var d = Zs(o(e), o(t), n, a, s, i);
      return i.delete(e), d;
    case di:
      if ($e)
        return $e.call(e) == $e.call(t);
  }
  return !1;
}
var mi = yi;
function bi(e, t) {
  for (var r = -1, n = t.length, a = e.length; ++r < n; )
    e[a + r] = t[r];
  return e;
}
var gi = bi, _i = Array.isArray, D = _i, vi = gi, $i = D;
function Si(e, t, r) {
  var n = t(e);
  return $i(e) ? n : vi(n, r(e));
}
var wi = Si;
function Ai(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length, a = 0, s = []; ++r < n; ) {
    var i = e[r];
    t(i, r, e) && (s[a++] = i);
  }
  return s;
}
var Ei = Ai;
function Ti() {
  return [];
}
var Oi = Ti, Ci = Ei, Pi = Oi, Ri = Object.prototype, xi = Ri.propertyIsEnumerable, ot = Object.getOwnPropertySymbols, Ii = ot ? function(e) {
  return e == null ? [] : (e = Object(e), Ci(ot(e), function(t) {
    return xi.call(e, t);
  }));
} : Pi, Ni = Ii;
function Di(e, t) {
  for (var r = -1, n = Array(e); ++r < e; )
    n[r] = t(r);
  return n;
}
var Li = Di;
function Fi(e) {
  return e != null && typeof e == "object";
}
var V = Fi, ji = J, Mi = V, Bi = "[object Arguments]";
function Ui(e) {
  return Mi(e) && ji(e) == Bi;
}
var ki = Ui, ct = ki, Hi = V, Wt = Object.prototype, Ki = Wt.hasOwnProperty, qi = Wt.propertyIsEnumerable, Gi = ct(/* @__PURE__ */ function() {
  return arguments;
}()) ? ct : function(e) {
  return Hi(e) && Ki.call(e, "callee") && !qi.call(e, "callee");
}, Xt = Gi, ae = { exports: {} };
function zi() {
  return !1;
}
var Ji = zi;
ae.exports;
(function(e, t) {
  var r = x, n = Ji, a = t && !t.nodeType && t, s = a && !0 && e && !e.nodeType && e, i = s && s.exports === a, o = i ? r.Buffer : void 0, f = o ? o.isBuffer : void 0, l = f || n;
  e.exports = l;
})(ae, ae.exports);
var Yt = ae.exports, Vi = 9007199254740991, Wi = /^(?:0|[1-9]\d*)$/;
function Xi(e, t) {
  var r = typeof e;
  return t = t ?? Vi, !!t && (r == "number" || r != "symbol" && Wi.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
var Qt = Xi, Yi = 9007199254740991;
function Qi(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= Yi;
}
var Be = Qi, Zi = J, eo = Be, to = V, ro = "[object Arguments]", no = "[object Array]", ao = "[object Boolean]", so = "[object Date]", io = "[object Error]", oo = "[object Function]", co = "[object Map]", uo = "[object Number]", lo = "[object Object]", fo = "[object RegExp]", po = "[object Set]", ho = "[object String]", yo = "[object WeakMap]", mo = "[object ArrayBuffer]", bo = "[object DataView]", go = "[object Float32Array]", _o = "[object Float64Array]", vo = "[object Int8Array]", $o = "[object Int16Array]", So = "[object Int32Array]", wo = "[object Uint8Array]", Ao = "[object Uint8ClampedArray]", Eo = "[object Uint16Array]", To = "[object Uint32Array]", _ = {};
_[go] = _[_o] = _[vo] = _[$o] = _[So] = _[wo] = _[Ao] = _[Eo] = _[To] = !0;
_[ro] = _[no] = _[mo] = _[ao] = _[bo] = _[so] = _[io] = _[oo] = _[co] = _[uo] = _[lo] = _[fo] = _[po] = _[ho] = _[yo] = !1;
function Oo(e) {
  return to(e) && eo(e.length) && !!_[Zi(e)];
}
var Co = Oo;
function Po(e) {
  return function(t) {
    return e(t);
  };
}
var Ro = Po, se = { exports: {} };
se.exports;
(function(e, t) {
  var r = Kt, n = t && !t.nodeType && t, a = n && !0 && e && !e.nodeType && e, s = a && a.exports === n, i = s && r.process, o = function() {
    try {
      var f = a && a.require && a.require("util").types;
      return f || i && i.binding && i.binding("util");
    } catch {
    }
  }();
  e.exports = o;
})(se, se.exports);
var xo = se.exports, Io = Co, No = Ro, ut = xo, lt = ut && ut.isTypedArray, Do = lt ? No(lt) : Io, Zt = Do, Lo = Li, Fo = Xt, jo = D, Mo = Yt, Bo = Qt, Uo = Zt, ko = Object.prototype, Ho = ko.hasOwnProperty;
function Ko(e, t) {
  var r = jo(e), n = !r && Fo(e), a = !r && !n && Mo(e), s = !r && !n && !a && Uo(e), i = r || n || a || s, o = i ? Lo(e.length, String) : [], f = o.length;
  for (var l in e)
    (t || Ho.call(e, l)) && !(i && // Safari 9 has enumerable `arguments.length` in strict mode.
    (l == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    a && (l == "offset" || l == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    s && (l == "buffer" || l == "byteLength" || l == "byteOffset") || // Skip index properties.
    Bo(l, f))) && o.push(l);
  return o;
}
var qo = Ko, Go = Object.prototype;
function zo(e) {
  var t = e && e.constructor, r = typeof t == "function" && t.prototype || Go;
  return e === r;
}
var Jo = zo;
function Vo(e, t) {
  return function(r) {
    return e(t(r));
  };
}
var Wo = Vo, Xo = Wo, Yo = Xo(Object.keys, Object), Qo = Yo, Zo = Jo, ec = Qo, tc = Object.prototype, rc = tc.hasOwnProperty;
function nc(e) {
  if (!Zo(e))
    return ec(e);
  var t = [];
  for (var r in Object(e))
    rc.call(e, r) && r != "constructor" && t.push(r);
  return t;
}
var ac = nc, sc = Gt, ic = Be;
function oc(e) {
  return e != null && ic(e.length) && !sc(e);
}
var er = oc, cc = qo, uc = ac, lc = er;
function fc(e) {
  return lc(e) ? cc(e) : uc(e);
}
var Ue = fc, dc = wi, pc = Ni, hc = Ue;
function yc(e) {
  return dc(e, hc, pc);
}
var mc = yc, ft = mc, bc = 1, gc = Object.prototype, _c = gc.hasOwnProperty;
function vc(e, t, r, n, a, s) {
  var i = r & bc, o = ft(e), f = o.length, l = ft(t), d = l.length;
  if (f != d && !i)
    return !1;
  for (var u = f; u--; ) {
    var y = o[u];
    if (!(i ? y in t : _c.call(t, y)))
      return !1;
  }
  var v = s.get(e), p = s.get(t);
  if (v && p)
    return v == t && p == e;
  var h = !0;
  s.set(e, t), s.set(t, e);
  for (var b = i; ++u < f; ) {
    y = o[u];
    var g = e[y], S = t[y];
    if (n)
      var w = i ? n(S, g, y, t, e, s) : n(g, S, y, e, t, s);
    if (!(w === void 0 ? g === S || a(g, S, r, n, s) : w)) {
      h = !1;
      break;
    }
    b || (b = y == "constructor");
  }
  if (h && !b) {
    var I = e.constructor, Y = t.constructor;
    I != Y && "constructor" in e && "constructor" in t && !(typeof I == "function" && I instanceof I && typeof Y == "function" && Y instanceof Y) && (h = !1);
  }
  return s.delete(e), s.delete(t), h;
}
var $c = vc, Sc = M, wc = x, Ac = Sc(wc, "DataView"), Ec = Ac, Tc = M, Oc = x, Cc = Tc(Oc, "Promise"), Pc = Cc, Rc = M, xc = x, Ic = Rc(xc, "Set"), Nc = Ic, Dc = M, Lc = x, Fc = Dc(Lc, "WeakMap"), jc = Fc, Oe = Ec, Ce = je, Pe = Pc, Re = Nc, xe = jc, tr = J, H = zt, dt = "[object Map]", Mc = "[object Object]", pt = "[object Promise]", ht = "[object Set]", yt = "[object WeakMap]", mt = "[object DataView]", Bc = H(Oe), Uc = H(Ce), kc = H(Pe), Hc = H(Re), Kc = H(xe), L = tr;
(Oe && L(new Oe(new ArrayBuffer(1))) != mt || Ce && L(new Ce()) != dt || Pe && L(Pe.resolve()) != pt || Re && L(new Re()) != ht || xe && L(new xe()) != yt) && (L = function(e) {
  var t = tr(e), r = t == Mc ? e.constructor : void 0, n = r ? H(r) : "";
  if (n)
    switch (n) {
      case Bc:
        return mt;
      case Uc:
        return dt;
      case kc:
        return pt;
      case Hc:
        return ht;
      case Kc:
        return yt;
    }
  return t;
});
var qc = L, Se = Jt, Gc = Vt, zc = mi, Jc = $c, bt = qc, gt = D, _t = Yt, Vc = Zt, Wc = 1, vt = "[object Arguments]", $t = "[object Array]", Z = "[object Object]", Xc = Object.prototype, St = Xc.hasOwnProperty;
function Yc(e, t, r, n, a, s) {
  var i = gt(e), o = gt(t), f = i ? $t : bt(e), l = o ? $t : bt(t);
  f = f == vt ? Z : f, l = l == vt ? Z : l;
  var d = f == Z, u = l == Z, y = f == l;
  if (y && _t(e)) {
    if (!_t(t))
      return !1;
    i = !0, d = !1;
  }
  if (y && !d)
    return s || (s = new Se()), i || Vc(e) ? Gc(e, t, r, n, a, s) : zc(e, t, f, r, n, a, s);
  if (!(r & Wc)) {
    var v = d && St.call(e, "__wrapped__"), p = u && St.call(t, "__wrapped__");
    if (v || p) {
      var h = v ? e.value() : e, b = p ? t.value() : t;
      return s || (s = new Se()), a(h, b, r, n, s);
    }
  }
  return y ? (s || (s = new Se()), Jc(e, t, r, n, a, s)) : !1;
}
var Qc = Yc, Zc = Qc, wt = V;
function rr(e, t, r, n, a) {
  return e === t ? !0 : e == null || t == null || !wt(e) && !wt(t) ? e !== e && t !== t : Zc(e, t, r, n, rr, a);
}
var nr = rr, eu = Jt, tu = nr, ru = 1, nu = 2;
function au(e, t, r, n) {
  var a = r.length, s = a, i = !n;
  if (e == null)
    return !s;
  for (e = Object(e); a--; ) {
    var o = r[a];
    if (i && o[2] ? o[1] !== e[o[0]] : !(o[0] in e))
      return !1;
  }
  for (; ++a < s; ) {
    o = r[a];
    var f = o[0], l = e[f], d = o[1];
    if (i && o[2]) {
      if (l === void 0 && !(f in e))
        return !1;
    } else {
      var u = new eu();
      if (n)
        var y = n(l, d, f, e, t, u);
      if (!(y === void 0 ? tu(d, l, ru | nu, n, u) : y))
        return !1;
    }
  }
  return !0;
}
var su = au, iu = le;
function ou(e) {
  return e === e && !iu(e);
}
var ar = ou, cu = ar, uu = Ue;
function lu(e) {
  for (var t = uu(e), r = t.length; r--; ) {
    var n = t[r], a = e[n];
    t[r] = [n, a, cu(a)];
  }
  return t;
}
var fu = lu;
function du(e, t) {
  return function(r) {
    return r == null ? !1 : r[e] === t && (t !== void 0 || e in Object(r));
  };
}
var sr = du, pu = su, hu = fu, yu = sr;
function mu(e) {
  var t = hu(e);
  return t.length == 1 && t[0][2] ? yu(t[0][0], t[0][1]) : function(r) {
    return r === e || pu(r, e, t);
  };
}
var bu = mu, gu = J, _u = V, vu = "[object Symbol]";
function $u(e) {
  return typeof e == "symbol" || _u(e) && gu(e) == vu;
}
var pe = $u, Su = D, wu = pe, Au = /\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/, Eu = /^\w*$/;
function Tu(e, t) {
  if (Su(e))
    return !1;
  var r = typeof e;
  return r == "number" || r == "symbol" || r == "boolean" || e == null || wu(e) ? !0 : Eu.test(e) || !Au.test(e) || t != null && e in Object(t);
}
var ke = Tu, ir = Me, Ou = "Expected a function";
function He(e, t) {
  if (typeof e != "function" || t != null && typeof t != "function")
    throw new TypeError(Ou);
  var r = function() {
    var n = arguments, a = t ? t.apply(this, n) : n[0], s = r.cache;
    if (s.has(a))
      return s.get(a);
    var i = e.apply(this, n);
    return r.cache = s.set(a, i) || s, i;
  };
  return r.cache = new (He.Cache || ir)(), r;
}
He.Cache = ir;
var or = He;
const sp = /* @__PURE__ */ kt(or);
var Cu = or, Pu = 500;
function Ru(e) {
  var t = Cu(e, function(n) {
    return r.size === Pu && r.clear(), n;
  }), r = t.cache;
  return t;
}
var xu = Ru, Iu = xu, Nu = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g, Du = /\\(\\)?/g, Lu = Iu(function(e) {
  var t = [];
  return e.charCodeAt(0) === 46 && t.push(""), e.replace(Nu, function(r, n, a, s) {
    t.push(a ? s.replace(Du, "$1") : n || r);
  }), t;
}), Fu = Lu;
function ju(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length, a = Array(n); ++r < n; )
    a[r] = t(e[r], r, e);
  return a;
}
var Mu = ju, At = ue, Bu = Mu, Uu = D, ku = pe, Hu = 1 / 0, Et = At ? At.prototype : void 0, Tt = Et ? Et.toString : void 0;
function cr(e) {
  if (typeof e == "string")
    return e;
  if (Uu(e))
    return Bu(e, cr) + "";
  if (ku(e))
    return Tt ? Tt.call(e) : "";
  var t = e + "";
  return t == "0" && 1 / e == -Hu ? "-0" : t;
}
var Ku = cr, qu = Ku;
function Gu(e) {
  return e == null ? "" : qu(e);
}
var zu = Gu, Ju = D, Vu = ke, Wu = Fu, Xu = zu;
function Yu(e, t) {
  return Ju(e) ? e : Vu(e, t) ? [e] : Wu(Xu(e));
}
var ur = Yu, Qu = pe, Zu = 1 / 0;
function el(e) {
  if (typeof e == "string" || Qu(e))
    return e;
  var t = e + "";
  return t == "0" && 1 / e == -Zu ? "-0" : t;
}
var he = el, tl = ur, rl = he;
function nl(e, t) {
  t = tl(t, e);
  for (var r = 0, n = t.length; e != null && r < n; )
    e = e[rl(t[r++])];
  return r && r == n ? e : void 0;
}
var lr = nl, al = lr;
function sl(e, t, r) {
  var n = e == null ? void 0 : al(e, t);
  return n === void 0 ? r : n;
}
var il = sl;
function ol(e, t) {
  return e != null && t in Object(e);
}
var cl = ol, ul = ur, ll = Xt, fl = D, dl = Qt, pl = Be, hl = he;
function yl(e, t, r) {
  t = ul(t, e);
  for (var n = -1, a = t.length, s = !1; ++n < a; ) {
    var i = hl(t[n]);
    if (!(s = e != null && r(e, i)))
      break;
    e = e[i];
  }
  return s || ++n != a ? s : (a = e == null ? 0 : e.length, !!a && pl(a) && dl(i, a) && (fl(e) || ll(e)));
}
var ml = yl, bl = cl, gl = ml;
function _l(e, t) {
  return e != null && gl(e, t, bl);
}
var vl = _l, $l = nr, Sl = il, wl = vl, Al = ke, El = ar, Tl = sr, Ol = he, Cl = 1, Pl = 2;
function Rl(e, t) {
  return Al(e) && El(t) ? Tl(Ol(e), t) : function(r) {
    var n = Sl(r, e);
    return n === void 0 && n === t ? wl(r, e) : $l(t, n, Cl | Pl);
  };
}
var xl = Rl;
function Il(e) {
  return e;
}
var Nl = Il;
function Dl(e) {
  return function(t) {
    return t == null ? void 0 : t[e];
  };
}
var Ll = Dl, Fl = lr;
function jl(e) {
  return function(t) {
    return Fl(t, e);
  };
}
var Ml = jl, Bl = Ll, Ul = Ml, kl = ke, Hl = he;
function Kl(e) {
  return kl(e) ? Bl(Hl(e)) : Ul(e);
}
var ql = Kl, Gl = bu, zl = xl, Jl = Nl, Vl = D, Wl = ql;
function Xl(e) {
  return typeof e == "function" ? e : e == null ? Jl : typeof e == "object" ? Vl(e) ? zl(e[0], e[1]) : Gl(e) : Wl(e);
}
var fr = Xl, Yl = fr, Ql = er, Zl = Ue;
function ef(e) {
  return function(t, r, n) {
    var a = Object(t);
    if (!Ql(t)) {
      var s = Yl(r);
      t = Zl(t), r = function(o) {
        return s(a[o], o, a);
      };
    }
    var i = e(t, r, n);
    return i > -1 ? a[s ? t[i] : i] : void 0;
  };
}
var tf = ef;
function rf(e, t, r, n) {
  for (var a = e.length, s = r + (n ? 1 : -1); n ? s-- : ++s < a; )
    if (t(e[s], s, e))
      return s;
  return -1;
}
var nf = rf, af = /\s/;
function sf(e) {
  for (var t = e.length; t-- && af.test(e.charAt(t)); )
    ;
  return t;
}
var of = sf, cf = of, uf = /^\s+/;
function lf(e) {
  return e && e.slice(0, cf(e) + 1).replace(uf, "");
}
var ff = lf, df = ff, Ot = le, pf = pe, Ct = NaN, hf = /^[-+]0x[0-9a-f]+$/i, yf = /^0b[01]+$/i, mf = /^0o[0-7]+$/i, bf = parseInt;
function gf(e) {
  if (typeof e == "number")
    return e;
  if (pf(e))
    return Ct;
  if (Ot(e)) {
    var t = typeof e.valueOf == "function" ? e.valueOf() : e;
    e = Ot(t) ? t + "" : t;
  }
  if (typeof e != "string")
    return e === 0 ? e : +e;
  e = df(e);
  var r = yf.test(e);
  return r || mf.test(e) ? bf(e.slice(2), r ? 2 : 8) : hf.test(e) ? Ct : +e;
}
var _f = gf, vf = _f, Pt = 1 / 0, $f = 17976931348623157e292;
function Sf(e) {
  if (!e)
    return e === 0 ? e : 0;
  if (e = vf(e), e === Pt || e === -Pt) {
    var t = e < 0 ? -1 : 1;
    return t * $f;
  }
  return e === e ? e : 0;
}
var wf = Sf, Af = wf;
function Ef(e) {
  var t = Af(e), r = t % 1;
  return t === t ? r ? t - r : t : 0;
}
var Tf = Ef, Of = nf, Cf = fr, Pf = Tf, Rf = Math.max;
function xf(e, t, r) {
  var n = e == null ? 0 : e.length;
  if (!n)
    return -1;
  var a = r == null ? 0 : Pf(r);
  return a < 0 && (a = Rf(n + a, 0)), Of(e, Cf(t), a);
}
var If = xf, Nf = tf, Df = If, Lf = Nf(Df), Ff = Lf;
const Ke = /* @__PURE__ */ kt(Ff), E = P.enum([
  "all",
  "blog",
  "fb",
  "fb_person",
  "ig",
  "other",
  "tiktok",
  "yt",
  "twitter"
]), C = P.enum([
  "all",
  "blog",
  "facebook",
  "instagram",
  "other",
  "tiktok",
  "youtube",
  "twitter"
]);
var dr = /* @__PURE__ */ ((e) => (e[e.All = -1] = "All", e[e.YouTube = 0] = "YouTube", e[e.Facebook = 1] = "Facebook", e[e.Instagram = 3] = "Instagram", e[e.TikTok = 4] = "TikTok", e[e.Twitter = 5] = "Twitter", e[e.Other = 999] = "Other", e))(dr || {});
const jf = P.nativeEnum(dr);
P.object({
  code: C,
  shortcode: E,
  title: P.string(),
  type: P.optional(jf),
  icon: P.optional(Ye),
  inactiveIcon: P.optional(Ye)
});
const qe = [
  {
    code: C.enum.all,
    shortcode: E.enum.all,
    title: "All"
  },
  {
    type: 999,
    code: C.enum.other,
    shortcode: E.enum.other,
    title: "Other"
  },
  {
    code: C.enum.blog,
    shortcode: E.enum.blog,
    title: "Blog"
  },
  {
    code: C.enum.tiktok,
    shortcode: E.enum.tiktok,
    title: "TikTok",
    type: 4
    /* TikTok */
  },
  {
    code: C.enum.facebook,
    shortcode: E.enum.fb,
    title: "Facebook",
    type: 1,
    icon: "brand-facebook-filled",
    inactiveIcon: "brand-facebook-inactive-filled"
  },
  {
    code: C.enum.instagram,
    shortcode: E.enum.ig,
    title: "Instagram",
    type: 3,
    icon: "brand-instagram-filled",
    inactiveIcon: "brand-instagram-inactive-filled"
  },
  {
    code: C.enum.youtube,
    shortcode: E.enum.yt,
    title: "YouTube",
    type: 0,
    icon: "brand-youtube-filled",
    inactiveIcon: "brand-youtube-inactive-filled"
  },
  {
    code: C.enum.twitter,
    shortcode: E.enum.twitter,
    title: "Twitter",
    type: 5
    /* Twitter */
  }
], ip = (e) => Ke(qe, { code: e }), op = (e = "") => {
  const t = E.safeParse(e);
  return t.success ? Ke(qe, { shortcode: t.data }) : void 0;
}, cp = (e) => Ke(qe, { type: e }), Mf = {
  beauty: {
    id: "beauty",
    netSuiteValue: "BT 美容業",
    i18nKey: "general:ad_industry_bt",
    isDeprecated: !1
  },
  medicalHealthcare: {
    id: "medicalHealthcare",
    netSuiteValue: "MH 醫療生技及保健業",
    i18nKey: "general:ad_industry_mh",
    isDeprecated: !1
  },
  foodBeverage: {
    id: "foodBeverage",
    netSuiteValue: "FB 食品飲料業",
    i18nKey: "general:ad_industry_fb",
    isDeprecated: !1
  },
  retailWholesale: {
    id: "retailWholesale",
    netSuiteValue: "RW 批發及零售業",
    i18nKey: "general:ad_industry_rw",
    isDeprecated: !1
  },
  eCommerce: {
    id: "eCommerce",
    netSuiteValue: "EC 電子商務業",
    i18nKey: "general:ad_industry_ec",
    isDeprecated: !1
  },
  financial: {
    id: "financial",
    netSuiteValue: "FN 金融業",
    i18nKey: "general:ad_industry_fn",
    isDeprecated: !1
  },
  computersElectronics: {
    id: "computersElectronics",
    netSuiteValue: "CE 資訊電子工業",
    i18nKey: "general:ad_industry_ce",
    isDeprecated: !1
  },
  travelLeisure: {
    id: "travelLeisure",
    netSuiteValue: "TL 觀光餐旅業",
    i18nKey: "general:ad_industry_tl",
    isDeprecated: !1
  },
  gaming: {
    id: "gaming",
    netSuiteValue: "GM 遊戲業",
    i18nKey: "general:ad_industry_gm",
    isDeprecated: !1
  },
  telecommunications: {
    id: "telecommunications",
    netSuiteValue: "TC 通訊業",
    i18nKey: "general:ad_industry_tc",
    isDeprecated: !1
  },
  entertainment: {
    id: "entertainment",
    netSuiteValue: "EN 娛樂媒體業",
    i18nKey: "general:ad_industry_en",
    isDeprecated: !1
  },
  education: {
    id: "education",
    netSuiteValue: "ED 教育業",
    i18nKey: "general:ad_industry_ed",
    isDeprecated: !1
  },
  govNonProfit: {
    id: "govNonProfit",
    netSuiteValue: "GN 政府及社服業",
    i18nKey: "general:ad_industry_gn",
    isDeprecated: !1
  },
  advertisingMarketing: {
    id: "advertisingMarketing",
    netSuiteValue: "AM 廣告行銷業",
    i18nKey: "general:ad_industry_am",
    isDeprecated: !1
  },
  realEstate: {
    id: "realEstate",
    netSuiteValue: "RE 營造及不動產業",
    i18nKey: "general:ad_industry_re",
    isDeprecated: !1
  },
  others: {
    id: "others",
    netSuiteValue: "OT 其他",
    i18nKey: "general:ad_industry_ot",
    isDeprecated: !1
  },
  // 這些只有舊的使用者才會有，新註冊的人不能讓它選
  softwareInternet: {
    id: "softwareInternet",
    netSuiteValue: "SI 資訊軟體業",
    i18nKey: "general:ad_industry_si",
    isDeprecated: !0
  },
  otherManufacturing: {
    id: "otherManufacturing",
    netSuiteValue: "OM 其他製造業",
    i18nKey: "general:ad_industry_om",
    isDeprecated: !0
  }
}, Bf = {
  beautyCare: "beauty",
  healthFood: "medicalHealthcare",
  food: "foodBeverage",
  retail: "retailWholesale",
  "e-commerce": "eCommerce",
  babyCare: "retailWholesale",
  finance: "financial",
  technology: "computersElectronics",
  tourism: "travelLeisure",
  gaming: "gaming",
  sport: "travelLeisure",
  telecom: "telecommunications",
  media: "entertainment",
  education: "education",
  npo: "govNonProfit",
  marketing: "advertisingMarketing",
  realEstate: "realEstate",
  freelancer: "others",
  others: "others"
};
function pr(e, t) {
  return function() {
    return e.apply(t, arguments);
  };
}
const { toString: Uf } = Object.prototype, { getPrototypeOf: Ge } = Object, ye = /* @__PURE__ */ ((e) => (t) => {
  const r = Uf.call(t);
  return e[r] || (e[r] = r.slice(8, -1).toLowerCase());
})(/* @__PURE__ */ Object.create(null)), O = (e) => (e = e.toLowerCase(), (t) => ye(t) === e), me = (e) => (t) => typeof t === e, { isArray: K } = Array, z = me("undefined");
function kf(e) {
  return e !== null && !z(e) && e.constructor !== null && !z(e.constructor) && A(e.constructor.isBuffer) && e.constructor.isBuffer(e);
}
const hr = O("ArrayBuffer");
function Hf(e) {
  let t;
  return typeof ArrayBuffer < "u" && ArrayBuffer.isView ? t = ArrayBuffer.isView(e) : t = e && e.buffer && hr(e.buffer), t;
}
const Kf = me("string"), A = me("function"), yr = me("number"), be = (e) => e !== null && typeof e == "object", qf = (e) => e === !0 || e === !1, ee = (e) => {
  if (ye(e) !== "object")
    return !1;
  const t = Ge(e);
  return (t === null || t === Object.prototype || Object.getPrototypeOf(t) === null) && !(Symbol.toStringTag in e) && !(Symbol.iterator in e);
}, Gf = O("Date"), zf = O("File"), Jf = O("Blob"), Vf = O("FileList"), Wf = (e) => be(e) && A(e.pipe), Xf = (e) => {
  let t;
  return e && (typeof FormData == "function" && e instanceof FormData || A(e.append) && ((t = ye(e)) === "formdata" || // detect form-data instance
  t === "object" && A(e.toString) && e.toString() === "[object FormData]"));
}, Yf = O("URLSearchParams"), Qf = (e) => e.trim ? e.trim() : e.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
function W(e, t, { allOwnKeys: r = !1 } = {}) {
  if (e === null || typeof e > "u")
    return;
  let n, a;
  if (typeof e != "object" && (e = [e]), K(e))
    for (n = 0, a = e.length; n < a; n++)
      t.call(null, e[n], n, e);
  else {
    const s = r ? Object.getOwnPropertyNames(e) : Object.keys(e), i = s.length;
    let o;
    for (n = 0; n < i; n++)
      o = s[n], t.call(null, e[o], o, e);
  }
}
function mr(e, t) {
  t = t.toLowerCase();
  const r = Object.keys(e);
  let n = r.length, a;
  for (; n-- > 0; )
    if (a = r[n], t === a.toLowerCase())
      return a;
  return null;
}
const br = typeof globalThis < "u" ? globalThis : typeof self < "u" ? self : typeof window < "u" ? window : global, gr = (e) => !z(e) && e !== br;
function Ie() {
  const { caseless: e } = gr(this) && this || {}, t = {}, r = (n, a) => {
    const s = e && mr(t, a) || a;
    ee(t[s]) && ee(n) ? t[s] = Ie(t[s], n) : ee(n) ? t[s] = Ie({}, n) : K(n) ? t[s] = n.slice() : t[s] = n;
  };
  for (let n = 0, a = arguments.length; n < a; n++)
    arguments[n] && W(arguments[n], r);
  return t;
}
const Zf = (e, t, r, { allOwnKeys: n } = {}) => (W(t, (a, s) => {
  r && A(a) ? e[s] = pr(a, r) : e[s] = a;
}, { allOwnKeys: n }), e), ed = (e) => (e.charCodeAt(0) === 65279 && (e = e.slice(1)), e), td = (e, t, r, n) => {
  e.prototype = Object.create(t.prototype, n), e.prototype.constructor = e, Object.defineProperty(e, "super", {
    value: t.prototype
  }), r && Object.assign(e.prototype, r);
}, rd = (e, t, r, n) => {
  let a, s, i;
  const o = {};
  if (t = t || {}, e == null)
    return t;
  do {
    for (a = Object.getOwnPropertyNames(e), s = a.length; s-- > 0; )
      i = a[s], (!n || n(i, e, t)) && !o[i] && (t[i] = e[i], o[i] = !0);
    e = r !== !1 && Ge(e);
  } while (e && (!r || r(e, t)) && e !== Object.prototype);
  return t;
}, nd = (e, t, r) => {
  e = String(e), (r === void 0 || r > e.length) && (r = e.length), r -= t.length;
  const n = e.indexOf(t, r);
  return n !== -1 && n === r;
}, ad = (e) => {
  if (!e)
    return null;
  if (K(e))
    return e;
  let t = e.length;
  if (!yr(t))
    return null;
  const r = new Array(t);
  for (; t-- > 0; )
    r[t] = e[t];
  return r;
}, sd = /* @__PURE__ */ ((e) => (t) => e && t instanceof e)(typeof Uint8Array < "u" && Ge(Uint8Array)), id = (e, t) => {
  const n = (e && e[Symbol.iterator]).call(e);
  let a;
  for (; (a = n.next()) && !a.done; ) {
    const s = a.value;
    t.call(e, s[0], s[1]);
  }
}, od = (e, t) => {
  let r;
  const n = [];
  for (; (r = e.exec(t)) !== null; )
    n.push(r);
  return n;
}, cd = O("HTMLFormElement"), ud = (e) => e.toLowerCase().replace(
  /[-_\s]([a-z\d])(\w*)/g,
  function(r, n, a) {
    return n.toUpperCase() + a;
  }
), Rt = (({ hasOwnProperty: e }) => (t, r) => e.call(t, r))(Object.prototype), ld = O("RegExp"), _r = (e, t) => {
  const r = Object.getOwnPropertyDescriptors(e), n = {};
  W(r, (a, s) => {
    let i;
    (i = t(a, s, e)) !== !1 && (n[s] = i || a);
  }), Object.defineProperties(e, n);
}, fd = (e) => {
  _r(e, (t, r) => {
    if (A(e) && ["arguments", "caller", "callee"].indexOf(r) !== -1)
      return !1;
    const n = e[r];
    if (A(n)) {
      if (t.enumerable = !1, "writable" in t) {
        t.writable = !1;
        return;
      }
      t.set || (t.set = () => {
        throw Error("Can not rewrite read-only method '" + r + "'");
      });
    }
  });
}, dd = (e, t) => {
  const r = {}, n = (a) => {
    a.forEach((s) => {
      r[s] = !0;
    });
  };
  return K(e) ? n(e) : n(String(e).split(t)), r;
}, pd = () => {
}, hd = (e, t) => (e = +e, Number.isFinite(e) ? e : t), we = "abcdefghijklmnopqrstuvwxyz", xt = "0123456789", vr = {
  DIGIT: xt,
  ALPHA: we,
  ALPHA_DIGIT: we + we.toUpperCase() + xt
}, yd = (e = 16, t = vr.ALPHA_DIGIT) => {
  let r = "";
  const { length: n } = t;
  for (; e--; )
    r += t[Math.random() * n | 0];
  return r;
};
function md(e) {
  return !!(e && A(e.append) && e[Symbol.toStringTag] === "FormData" && e[Symbol.iterator]);
}
const bd = (e) => {
  const t = new Array(10), r = (n, a) => {
    if (be(n)) {
      if (t.indexOf(n) >= 0)
        return;
      if (!("toJSON" in n)) {
        t[a] = n;
        const s = K(n) ? [] : {};
        return W(n, (i, o) => {
          const f = r(i, a + 1);
          !z(f) && (s[o] = f);
        }), t[a] = void 0, s;
      }
    }
    return n;
  };
  return r(e, 0);
}, gd = O("AsyncFunction"), _d = (e) => e && (be(e) || A(e)) && A(e.then) && A(e.catch), c = {
  isArray: K,
  isArrayBuffer: hr,
  isBuffer: kf,
  isFormData: Xf,
  isArrayBufferView: Hf,
  isString: Kf,
  isNumber: yr,
  isBoolean: qf,
  isObject: be,
  isPlainObject: ee,
  isUndefined: z,
  isDate: Gf,
  isFile: zf,
  isBlob: Jf,
  isRegExp: ld,
  isFunction: A,
  isStream: Wf,
  isURLSearchParams: Yf,
  isTypedArray: sd,
  isFileList: Vf,
  forEach: W,
  merge: Ie,
  extend: Zf,
  trim: Qf,
  stripBOM: ed,
  inherits: td,
  toFlatObject: rd,
  kindOf: ye,
  kindOfTest: O,
  endsWith: nd,
  toArray: ad,
  forEachEntry: id,
  matchAll: od,
  isHTMLForm: cd,
  hasOwnProperty: Rt,
  hasOwnProp: Rt,
  // an alias to avoid ESLint no-prototype-builtins detection
  reduceDescriptors: _r,
  freezeMethods: fd,
  toObjectSet: dd,
  toCamelCase: ud,
  noop: pd,
  toFiniteNumber: hd,
  findKey: mr,
  global: br,
  isContextDefined: gr,
  ALPHABET: vr,
  generateString: yd,
  isSpecCompliantForm: md,
  toJSONObject: bd,
  isAsyncFn: gd,
  isThenable: _d
};
function m(e, t, r, n, a) {
  Error.call(this), Error.captureStackTrace ? Error.captureStackTrace(this, this.constructor) : this.stack = new Error().stack, this.message = e, this.name = "AxiosError", t && (this.code = t), r && (this.config = r), n && (this.request = n), a && (this.response = a);
}
c.inherits(m, Error, {
  toJSON: function() {
    return {
      // Standard
      message: this.message,
      name: this.name,
      // Microsoft
      description: this.description,
      number: this.number,
      // Mozilla
      fileName: this.fileName,
      lineNumber: this.lineNumber,
      columnNumber: this.columnNumber,
      stack: this.stack,
      // Axios
      config: c.toJSONObject(this.config),
      code: this.code,
      status: this.response && this.response.status ? this.response.status : null
    };
  }
});
const $r = m.prototype, Sr = {};
[
  "ERR_BAD_OPTION_VALUE",
  "ERR_BAD_OPTION",
  "ECONNABORTED",
  "ETIMEDOUT",
  "ERR_NETWORK",
  "ERR_FR_TOO_MANY_REDIRECTS",
  "ERR_DEPRECATED",
  "ERR_BAD_RESPONSE",
  "ERR_BAD_REQUEST",
  "ERR_CANCELED",
  "ERR_NOT_SUPPORT",
  "ERR_INVALID_URL"
  // eslint-disable-next-line func-names
].forEach((e) => {
  Sr[e] = { value: e };
});
Object.defineProperties(m, Sr);
Object.defineProperty($r, "isAxiosError", { value: !0 });
m.from = (e, t, r, n, a, s) => {
  const i = Object.create($r);
  return c.toFlatObject(e, i, function(f) {
    return f !== Error.prototype;
  }, (o) => o !== "isAxiosError"), m.call(i, e.message, t, r, n, a), i.cause = e, i.name = e.name, s && Object.assign(i, s), i;
};
const vd = null;
function Ne(e) {
  return c.isPlainObject(e) || c.isArray(e);
}
function wr(e) {
  return c.endsWith(e, "[]") ? e.slice(0, -2) : e;
}
function It(e, t, r) {
  return e ? e.concat(t).map(function(a, s) {
    return a = wr(a), !r && s ? "[" + a + "]" : a;
  }).join(r ? "." : "") : t;
}
function $d(e) {
  return c.isArray(e) && !e.some(Ne);
}
const Sd = c.toFlatObject(c, {}, null, function(t) {
  return /^is[A-Z]/.test(t);
});
function ge(e, t, r) {
  if (!c.isObject(e))
    throw new TypeError("target must be an object");
  t = t || new FormData(), r = c.toFlatObject(r, {
    metaTokens: !0,
    dots: !1,
    indexes: !1
  }, !1, function(h, b) {
    return !c.isUndefined(b[h]);
  });
  const n = r.metaTokens, a = r.visitor || d, s = r.dots, i = r.indexes, f = (r.Blob || typeof Blob < "u" && Blob) && c.isSpecCompliantForm(t);
  if (!c.isFunction(a))
    throw new TypeError("visitor must be a function");
  function l(p) {
    if (p === null)
      return "";
    if (c.isDate(p))
      return p.toISOString();
    if (!f && c.isBlob(p))
      throw new m("Blob is not supported. Use a Buffer instead.");
    return c.isArrayBuffer(p) || c.isTypedArray(p) ? f && typeof Blob == "function" ? new Blob([p]) : Buffer.from(p) : p;
  }
  function d(p, h, b) {
    let g = p;
    if (p && !b && typeof p == "object") {
      if (c.endsWith(h, "{}"))
        h = n ? h : h.slice(0, -2), p = JSON.stringify(p);
      else if (c.isArray(p) && $d(p) || (c.isFileList(p) || c.endsWith(h, "[]")) && (g = c.toArray(p)))
        return h = wr(h), g.forEach(function(w, I) {
          !(c.isUndefined(w) || w === null) && t.append(
            // eslint-disable-next-line no-nested-ternary
            i === !0 ? It([h], I, s) : i === null ? h : h + "[]",
            l(w)
          );
        }), !1;
    }
    return Ne(p) ? !0 : (t.append(It(b, h, s), l(p)), !1);
  }
  const u = [], y = Object.assign(Sd, {
    defaultVisitor: d,
    convertValue: l,
    isVisitable: Ne
  });
  function v(p, h) {
    if (!c.isUndefined(p)) {
      if (u.indexOf(p) !== -1)
        throw Error("Circular reference detected in " + h.join("."));
      u.push(p), c.forEach(p, function(g, S) {
        (!(c.isUndefined(g) || g === null) && a.call(
          t,
          g,
          c.isString(S) ? S.trim() : S,
          h,
          y
        )) === !0 && v(g, h ? h.concat(S) : [S]);
      }), u.pop();
    }
  }
  if (!c.isObject(e))
    throw new TypeError("data must be an object");
  return v(e), t;
}
function Nt(e) {
  const t = {
    "!": "%21",
    "'": "%27",
    "(": "%28",
    ")": "%29",
    "~": "%7E",
    "%20": "+",
    "%00": "\0"
  };
  return encodeURIComponent(e).replace(/[!'()~]|%20|%00/g, function(n) {
    return t[n];
  });
}
function ze(e, t) {
  this._pairs = [], e && ge(e, this, t);
}
const Ar = ze.prototype;
Ar.append = function(t, r) {
  this._pairs.push([t, r]);
};
Ar.toString = function(t) {
  const r = t ? function(n) {
    return t.call(this, n, Nt);
  } : Nt;
  return this._pairs.map(function(a) {
    return r(a[0]) + "=" + r(a[1]);
  }, "").join("&");
};
function wd(e) {
  return encodeURIComponent(e).replace(/%3A/gi, ":").replace(/%24/g, "$").replace(/%2C/gi, ",").replace(/%20/g, "+").replace(/%5B/gi, "[").replace(/%5D/gi, "]");
}
function Er(e, t, r) {
  if (!t)
    return e;
  const n = r && r.encode || wd, a = r && r.serialize;
  let s;
  if (a ? s = a(t, r) : s = c.isURLSearchParams(t) ? t.toString() : new ze(t, r).toString(n), s) {
    const i = e.indexOf("#");
    i !== -1 && (e = e.slice(0, i)), e += (e.indexOf("?") === -1 ? "?" : "&") + s;
  }
  return e;
}
class Dt {
  constructor() {
    this.handlers = [];
  }
  /**
   * Add a new interceptor to the stack
   *
   * @param {Function} fulfilled The function to handle `then` for a `Promise`
   * @param {Function} rejected The function to handle `reject` for a `Promise`
   *
   * @return {Number} An ID used to remove interceptor later
   */
  use(t, r, n) {
    return this.handlers.push({
      fulfilled: t,
      rejected: r,
      synchronous: n ? n.synchronous : !1,
      runWhen: n ? n.runWhen : null
    }), this.handlers.length - 1;
  }
  /**
   * Remove an interceptor from the stack
   *
   * @param {Number} id The ID that was returned by `use`
   *
   * @returns {Boolean} `true` if the interceptor was removed, `false` otherwise
   */
  eject(t) {
    this.handlers[t] && (this.handlers[t] = null);
  }
  /**
   * Clear all interceptors from the stack
   *
   * @returns {void}
   */
  clear() {
    this.handlers && (this.handlers = []);
  }
  /**
   * Iterate over all the registered interceptors
   *
   * This method is particularly useful for skipping over any
   * interceptors that may have become `null` calling `eject`.
   *
   * @param {Function} fn The function to call for each interceptor
   *
   * @returns {void}
   */
  forEach(t) {
    c.forEach(this.handlers, function(n) {
      n !== null && t(n);
    });
  }
}
const Tr = {
  silentJSONParsing: !0,
  forcedJSONParsing: !0,
  clarifyTimeoutError: !1
}, Ad = typeof URLSearchParams < "u" ? URLSearchParams : ze, Ed = typeof FormData < "u" ? FormData : null, Td = typeof Blob < "u" ? Blob : null, Od = {
  isBrowser: !0,
  classes: {
    URLSearchParams: Ad,
    FormData: Ed,
    Blob: Td
  },
  protocols: ["http", "https", "file", "blob", "url", "data"]
}, Or = typeof window < "u" && typeof document < "u", Cd = ((e) => Or && ["ReactNative", "NativeScript", "NS"].indexOf(e) < 0)(typeof navigator < "u" && navigator.product), Pd = typeof WorkerGlobalScope < "u" && // eslint-disable-next-line no-undef
self instanceof WorkerGlobalScope && typeof self.importScripts == "function", Rd = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  hasBrowserEnv: Or,
  hasStandardBrowserEnv: Cd,
  hasStandardBrowserWebWorkerEnv: Pd
}, Symbol.toStringTag, { value: "Module" })), T = {
  ...Rd,
  ...Od
};
function xd(e, t) {
  return ge(e, new T.classes.URLSearchParams(), Object.assign({
    visitor: function(r, n, a, s) {
      return T.isNode && c.isBuffer(r) ? (this.append(n, r.toString("base64")), !1) : s.defaultVisitor.apply(this, arguments);
    }
  }, t));
}
function Id(e) {
  return c.matchAll(/\w+|\[(\w*)]/g, e).map((t) => t[0] === "[]" ? "" : t[1] || t[0]);
}
function Nd(e) {
  const t = {}, r = Object.keys(e);
  let n;
  const a = r.length;
  let s;
  for (n = 0; n < a; n++)
    s = r[n], t[s] = e[s];
  return t;
}
function Cr(e) {
  function t(r, n, a, s) {
    let i = r[s++];
    const o = Number.isFinite(+i), f = s >= r.length;
    return i = !i && c.isArray(a) ? a.length : i, f ? (c.hasOwnProp(a, i) ? a[i] = [a[i], n] : a[i] = n, !o) : ((!a[i] || !c.isObject(a[i])) && (a[i] = []), t(r, n, a[i], s) && c.isArray(a[i]) && (a[i] = Nd(a[i])), !o);
  }
  if (c.isFormData(e) && c.isFunction(e.entries)) {
    const r = {};
    return c.forEachEntry(e, (n, a) => {
      t(Id(n), a, r, 0);
    }), r;
  }
  return null;
}
function Dd(e, t, r) {
  if (c.isString(e))
    try {
      return (t || JSON.parse)(e), c.trim(e);
    } catch (n) {
      if (n.name !== "SyntaxError")
        throw n;
    }
  return (r || JSON.stringify)(e);
}
const Je = {
  transitional: Tr,
  adapter: ["xhr", "http"],
  transformRequest: [function(t, r) {
    const n = r.getContentType() || "", a = n.indexOf("application/json") > -1, s = c.isObject(t);
    if (s && c.isHTMLForm(t) && (t = new FormData(t)), c.isFormData(t))
      return a && a ? JSON.stringify(Cr(t)) : t;
    if (c.isArrayBuffer(t) || c.isBuffer(t) || c.isStream(t) || c.isFile(t) || c.isBlob(t))
      return t;
    if (c.isArrayBufferView(t))
      return t.buffer;
    if (c.isURLSearchParams(t))
      return r.setContentType("application/x-www-form-urlencoded;charset=utf-8", !1), t.toString();
    let o;
    if (s) {
      if (n.indexOf("application/x-www-form-urlencoded") > -1)
        return xd(t, this.formSerializer).toString();
      if ((o = c.isFileList(t)) || n.indexOf("multipart/form-data") > -1) {
        const f = this.env && this.env.FormData;
        return ge(
          o ? { "files[]": t } : t,
          f && new f(),
          this.formSerializer
        );
      }
    }
    return s || a ? (r.setContentType("application/json", !1), Dd(t)) : t;
  }],
  transformResponse: [function(t) {
    const r = this.transitional || Je.transitional, n = r && r.forcedJSONParsing, a = this.responseType === "json";
    if (t && c.isString(t) && (n && !this.responseType || a)) {
      const i = !(r && r.silentJSONParsing) && a;
      try {
        return JSON.parse(t);
      } catch (o) {
        if (i)
          throw o.name === "SyntaxError" ? m.from(o, m.ERR_BAD_RESPONSE, this, null, this.response) : o;
      }
    }
    return t;
  }],
  /**
   * A timeout in milliseconds to abort a request. If set to 0 (default) a
   * timeout is not created.
   */
  timeout: 0,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  maxContentLength: -1,
  maxBodyLength: -1,
  env: {
    FormData: T.classes.FormData,
    Blob: T.classes.Blob
  },
  validateStatus: function(t) {
    return t >= 200 && t < 300;
  },
  headers: {
    common: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": void 0
    }
  }
};
c.forEach(["delete", "get", "head", "post", "put", "patch"], (e) => {
  Je.headers[e] = {};
});
const Ve = Je, Ld = c.toObjectSet([
  "age",
  "authorization",
  "content-length",
  "content-type",
  "etag",
  "expires",
  "from",
  "host",
  "if-modified-since",
  "if-unmodified-since",
  "last-modified",
  "location",
  "max-forwards",
  "proxy-authorization",
  "referer",
  "retry-after",
  "user-agent"
]), Fd = (e) => {
  const t = {};
  let r, n, a;
  return e && e.split(`
`).forEach(function(i) {
    a = i.indexOf(":"), r = i.substring(0, a).trim().toLowerCase(), n = i.substring(a + 1).trim(), !(!r || t[r] && Ld[r]) && (r === "set-cookie" ? t[r] ? t[r].push(n) : t[r] = [n] : t[r] = t[r] ? t[r] + ", " + n : n);
  }), t;
}, Lt = Symbol("internals");
function G(e) {
  return e && String(e).trim().toLowerCase();
}
function te(e) {
  return e === !1 || e == null ? e : c.isArray(e) ? e.map(te) : String(e);
}
function jd(e) {
  const t = /* @__PURE__ */ Object.create(null), r = /([^\s,;=]+)\s*(?:=\s*([^,;]+))?/g;
  let n;
  for (; n = r.exec(e); )
    t[n[1]] = n[2];
  return t;
}
const Md = (e) => /^[-_a-zA-Z0-9^`|~,!#$%&'*+.]+$/.test(e.trim());
function Ae(e, t, r, n, a) {
  if (c.isFunction(n))
    return n.call(this, t, r);
  if (a && (t = r), !!c.isString(t)) {
    if (c.isString(n))
      return t.indexOf(n) !== -1;
    if (c.isRegExp(n))
      return n.test(t);
  }
}
function Bd(e) {
  return e.trim().toLowerCase().replace(/([a-z\d])(\w*)/g, (t, r, n) => r.toUpperCase() + n);
}
function Ud(e, t) {
  const r = c.toCamelCase(" " + t);
  ["get", "set", "has"].forEach((n) => {
    Object.defineProperty(e, n + r, {
      value: function(a, s, i) {
        return this[n].call(this, t, a, s, i);
      },
      configurable: !0
    });
  });
}
class _e {
  constructor(t) {
    t && this.set(t);
  }
  set(t, r, n) {
    const a = this;
    function s(o, f, l) {
      const d = G(f);
      if (!d)
        throw new Error("header name must be a non-empty string");
      const u = c.findKey(a, d);
      (!u || a[u] === void 0 || l === !0 || l === void 0 && a[u] !== !1) && (a[u || f] = te(o));
    }
    const i = (o, f) => c.forEach(o, (l, d) => s(l, d, f));
    return c.isPlainObject(t) || t instanceof this.constructor ? i(t, r) : c.isString(t) && (t = t.trim()) && !Md(t) ? i(Fd(t), r) : t != null && s(r, t, n), this;
  }
  get(t, r) {
    if (t = G(t), t) {
      const n = c.findKey(this, t);
      if (n) {
        const a = this[n];
        if (!r)
          return a;
        if (r === !0)
          return jd(a);
        if (c.isFunction(r))
          return r.call(this, a, n);
        if (c.isRegExp(r))
          return r.exec(a);
        throw new TypeError("parser must be boolean|regexp|function");
      }
    }
  }
  has(t, r) {
    if (t = G(t), t) {
      const n = c.findKey(this, t);
      return !!(n && this[n] !== void 0 && (!r || Ae(this, this[n], n, r)));
    }
    return !1;
  }
  delete(t, r) {
    const n = this;
    let a = !1;
    function s(i) {
      if (i = G(i), i) {
        const o = c.findKey(n, i);
        o && (!r || Ae(n, n[o], o, r)) && (delete n[o], a = !0);
      }
    }
    return c.isArray(t) ? t.forEach(s) : s(t), a;
  }
  clear(t) {
    const r = Object.keys(this);
    let n = r.length, a = !1;
    for (; n--; ) {
      const s = r[n];
      (!t || Ae(this, this[s], s, t, !0)) && (delete this[s], a = !0);
    }
    return a;
  }
  normalize(t) {
    const r = this, n = {};
    return c.forEach(this, (a, s) => {
      const i = c.findKey(n, s);
      if (i) {
        r[i] = te(a), delete r[s];
        return;
      }
      const o = t ? Bd(s) : String(s).trim();
      o !== s && delete r[s], r[o] = te(a), n[o] = !0;
    }), this;
  }
  concat(...t) {
    return this.constructor.concat(this, ...t);
  }
  toJSON(t) {
    const r = /* @__PURE__ */ Object.create(null);
    return c.forEach(this, (n, a) => {
      n != null && n !== !1 && (r[a] = t && c.isArray(n) ? n.join(", ") : n);
    }), r;
  }
  [Symbol.iterator]() {
    return Object.entries(this.toJSON())[Symbol.iterator]();
  }
  toString() {
    return Object.entries(this.toJSON()).map(([t, r]) => t + ": " + r).join(`
`);
  }
  get [Symbol.toStringTag]() {
    return "AxiosHeaders";
  }
  static from(t) {
    return t instanceof this ? t : new this(t);
  }
  static concat(t, ...r) {
    const n = new this(t);
    return r.forEach((a) => n.set(a)), n;
  }
  static accessor(t) {
    const n = (this[Lt] = this[Lt] = {
      accessors: {}
    }).accessors, a = this.prototype;
    function s(i) {
      const o = G(i);
      n[o] || (Ud(a, i), n[o] = !0);
    }
    return c.isArray(t) ? t.forEach(s) : s(t), this;
  }
}
_e.accessor(["Content-Type", "Content-Length", "Accept", "Accept-Encoding", "User-Agent", "Authorization"]);
c.reduceDescriptors(_e.prototype, ({ value: e }, t) => {
  let r = t[0].toUpperCase() + t.slice(1);
  return {
    get: () => e,
    set(n) {
      this[r] = n;
    }
  };
});
c.freezeMethods(_e);
const R = _e;
function Ee(e, t) {
  const r = this || Ve, n = t || r, a = R.from(n.headers);
  let s = n.data;
  return c.forEach(e, function(o) {
    s = o.call(r, s, a.normalize(), t ? t.status : void 0);
  }), a.normalize(), s;
}
function Pr(e) {
  return !!(e && e.__CANCEL__);
}
function X(e, t, r) {
  m.call(this, e ?? "canceled", m.ERR_CANCELED, t, r), this.name = "CanceledError";
}
c.inherits(X, m, {
  __CANCEL__: !0
});
function kd(e, t, r) {
  const n = r.config.validateStatus;
  !r.status || !n || n(r.status) ? e(r) : t(new m(
    "Request failed with status code " + r.status,
    [m.ERR_BAD_REQUEST, m.ERR_BAD_RESPONSE][Math.floor(r.status / 100) - 4],
    r.config,
    r.request,
    r
  ));
}
const Hd = T.hasStandardBrowserEnv ? (
  // Standard browser envs support document.cookie
  {
    write(e, t, r, n, a, s) {
      const i = [e + "=" + encodeURIComponent(t)];
      c.isNumber(r) && i.push("expires=" + new Date(r).toGMTString()), c.isString(n) && i.push("path=" + n), c.isString(a) && i.push("domain=" + a), s === !0 && i.push("secure"), document.cookie = i.join("; ");
    },
    read(e) {
      const t = document.cookie.match(new RegExp("(^|;\\s*)(" + e + ")=([^;]*)"));
      return t ? decodeURIComponent(t[3]) : null;
    },
    remove(e) {
      this.write(e, "", Date.now() - 864e5);
    }
  }
) : (
  // Non-standard browser env (web workers, react-native) lack needed support.
  {
    write() {
    },
    read() {
      return null;
    },
    remove() {
    }
  }
);
function Kd(e) {
  return /^([a-z][a-z\d+\-.]*:)?\/\//i.test(e);
}
function qd(e, t) {
  return t ? e.replace(/\/+$/, "") + "/" + t.replace(/^\/+/, "") : e;
}
function Rr(e, t) {
  return e && !Kd(t) ? qd(e, t) : t;
}
const Gd = T.hasStandardBrowserEnv ? (
  // Standard browser envs have full support of the APIs needed to test
  // whether the request URL is of the same origin as current location.
  function() {
    const t = /(msie|trident)/i.test(navigator.userAgent), r = document.createElement("a");
    let n;
    function a(s) {
      let i = s;
      return t && (r.setAttribute("href", i), i = r.href), r.setAttribute("href", i), {
        href: r.href,
        protocol: r.protocol ? r.protocol.replace(/:$/, "") : "",
        host: r.host,
        search: r.search ? r.search.replace(/^\?/, "") : "",
        hash: r.hash ? r.hash.replace(/^#/, "") : "",
        hostname: r.hostname,
        port: r.port,
        pathname: r.pathname.charAt(0) === "/" ? r.pathname : "/" + r.pathname
      };
    }
    return n = a(window.location.href), function(i) {
      const o = c.isString(i) ? a(i) : i;
      return o.protocol === n.protocol && o.host === n.host;
    };
  }()
) : (
  // Non standard browser envs (web workers, react-native) lack needed support.
  /* @__PURE__ */ function() {
    return function() {
      return !0;
    };
  }()
);
function zd(e) {
  const t = /^([-+\w]{1,25})(:?\/\/|:)/.exec(e);
  return t && t[1] || "";
}
function Jd(e, t) {
  e = e || 10;
  const r = new Array(e), n = new Array(e);
  let a = 0, s = 0, i;
  return t = t !== void 0 ? t : 1e3, function(f) {
    const l = Date.now(), d = n[s];
    i || (i = l), r[a] = f, n[a] = l;
    let u = s, y = 0;
    for (; u !== a; )
      y += r[u++], u = u % e;
    if (a = (a + 1) % e, a === s && (s = (s + 1) % e), l - i < t)
      return;
    const v = d && l - d;
    return v ? Math.round(y * 1e3 / v) : void 0;
  };
}
function Ft(e, t) {
  let r = 0;
  const n = Jd(50, 250);
  return (a) => {
    const s = a.loaded, i = a.lengthComputable ? a.total : void 0, o = s - r, f = n(o), l = s <= i;
    r = s;
    const d = {
      loaded: s,
      total: i,
      progress: i ? s / i : void 0,
      bytes: o,
      rate: f || void 0,
      estimated: f && i && l ? (i - s) / f : void 0,
      event: a
    };
    d[t ? "download" : "upload"] = !0, e(d);
  };
}
const Vd = typeof XMLHttpRequest < "u", Wd = Vd && function(e) {
  return new Promise(function(r, n) {
    let a = e.data;
    const s = R.from(e.headers).normalize();
    let { responseType: i, withXSRFToken: o } = e, f;
    function l() {
      e.cancelToken && e.cancelToken.unsubscribe(f), e.signal && e.signal.removeEventListener("abort", f);
    }
    let d;
    if (c.isFormData(a)) {
      if (T.hasStandardBrowserEnv || T.hasStandardBrowserWebWorkerEnv)
        s.setContentType(!1);
      else if ((d = s.getContentType()) !== !1) {
        const [h, ...b] = d ? d.split(";").map((g) => g.trim()).filter(Boolean) : [];
        s.setContentType([h || "multipart/form-data", ...b].join("; "));
      }
    }
    let u = new XMLHttpRequest();
    if (e.auth) {
      const h = e.auth.username || "", b = e.auth.password ? unescape(encodeURIComponent(e.auth.password)) : "";
      s.set("Authorization", "Basic " + btoa(h + ":" + b));
    }
    const y = Rr(e.baseURL, e.url);
    u.open(e.method.toUpperCase(), Er(y, e.params, e.paramsSerializer), !0), u.timeout = e.timeout;
    function v() {
      if (!u)
        return;
      const h = R.from(
        "getAllResponseHeaders" in u && u.getAllResponseHeaders()
      ), g = {
        data: !i || i === "text" || i === "json" ? u.responseText : u.response,
        status: u.status,
        statusText: u.statusText,
        headers: h,
        config: e,
        request: u
      };
      kd(function(w) {
        r(w), l();
      }, function(w) {
        n(w), l();
      }, g), u = null;
    }
    if ("onloadend" in u ? u.onloadend = v : u.onreadystatechange = function() {
      !u || u.readyState !== 4 || u.status === 0 && !(u.responseURL && u.responseURL.indexOf("file:") === 0) || setTimeout(v);
    }, u.onabort = function() {
      u && (n(new m("Request aborted", m.ECONNABORTED, e, u)), u = null);
    }, u.onerror = function() {
      n(new m("Network Error", m.ERR_NETWORK, e, u)), u = null;
    }, u.ontimeout = function() {
      let b = e.timeout ? "timeout of " + e.timeout + "ms exceeded" : "timeout exceeded";
      const g = e.transitional || Tr;
      e.timeoutErrorMessage && (b = e.timeoutErrorMessage), n(new m(
        b,
        g.clarifyTimeoutError ? m.ETIMEDOUT : m.ECONNABORTED,
        e,
        u
      )), u = null;
    }, T.hasStandardBrowserEnv && (o && c.isFunction(o) && (o = o(e)), o || o !== !1 && Gd(y))) {
      const h = e.xsrfHeaderName && e.xsrfCookieName && Hd.read(e.xsrfCookieName);
      h && s.set(e.xsrfHeaderName, h);
    }
    a === void 0 && s.setContentType(null), "setRequestHeader" in u && c.forEach(s.toJSON(), function(b, g) {
      u.setRequestHeader(g, b);
    }), c.isUndefined(e.withCredentials) || (u.withCredentials = !!e.withCredentials), i && i !== "json" && (u.responseType = e.responseType), typeof e.onDownloadProgress == "function" && u.addEventListener("progress", Ft(e.onDownloadProgress, !0)), typeof e.onUploadProgress == "function" && u.upload && u.upload.addEventListener("progress", Ft(e.onUploadProgress)), (e.cancelToken || e.signal) && (f = (h) => {
      u && (n(!h || h.type ? new X(null, e, u) : h), u.abort(), u = null);
    }, e.cancelToken && e.cancelToken.subscribe(f), e.signal && (e.signal.aborted ? f() : e.signal.addEventListener("abort", f)));
    const p = zd(y);
    if (p && T.protocols.indexOf(p) === -1) {
      n(new m("Unsupported protocol " + p + ":", m.ERR_BAD_REQUEST, e));
      return;
    }
    u.send(a || null);
  });
}, De = {
  http: vd,
  xhr: Wd
};
c.forEach(De, (e, t) => {
  if (e) {
    try {
      Object.defineProperty(e, "name", { value: t });
    } catch {
    }
    Object.defineProperty(e, "adapterName", { value: t });
  }
});
const jt = (e) => `- ${e}`, Xd = (e) => c.isFunction(e) || e === null || e === !1, xr = {
  getAdapter: (e) => {
    e = c.isArray(e) ? e : [e];
    const { length: t } = e;
    let r, n;
    const a = {};
    for (let s = 0; s < t; s++) {
      r = e[s];
      let i;
      if (n = r, !Xd(r) && (n = De[(i = String(r)).toLowerCase()], n === void 0))
        throw new m(`Unknown adapter '${i}'`);
      if (n)
        break;
      a[i || "#" + s] = n;
    }
    if (!n) {
      const s = Object.entries(a).map(
        ([o, f]) => `adapter ${o} ` + (f === !1 ? "is not supported by the environment" : "is not available in the build")
      );
      let i = t ? s.length > 1 ? `since :
` + s.map(jt).join(`
`) : " " + jt(s[0]) : "as no adapter specified";
      throw new m(
        "There is no suitable adapter to dispatch the request " + i,
        "ERR_NOT_SUPPORT"
      );
    }
    return n;
  },
  adapters: De
};
function Te(e) {
  if (e.cancelToken && e.cancelToken.throwIfRequested(), e.signal && e.signal.aborted)
    throw new X(null, e);
}
function Mt(e) {
  return Te(e), e.headers = R.from(e.headers), e.data = Ee.call(
    e,
    e.transformRequest
  ), ["post", "put", "patch"].indexOf(e.method) !== -1 && e.headers.setContentType("application/x-www-form-urlencoded", !1), xr.getAdapter(e.adapter || Ve.adapter)(e).then(function(n) {
    return Te(e), n.data = Ee.call(
      e,
      e.transformResponse,
      n
    ), n.headers = R.from(n.headers), n;
  }, function(n) {
    return Pr(n) || (Te(e), n && n.response && (n.response.data = Ee.call(
      e,
      e.transformResponse,
      n.response
    ), n.response.headers = R.from(n.response.headers))), Promise.reject(n);
  });
}
const Bt = (e) => e instanceof R ? e.toJSON() : e;
function F(e, t) {
  t = t || {};
  const r = {};
  function n(l, d, u) {
    return c.isPlainObject(l) && c.isPlainObject(d) ? c.merge.call({ caseless: u }, l, d) : c.isPlainObject(d) ? c.merge({}, d) : c.isArray(d) ? d.slice() : d;
  }
  function a(l, d, u) {
    if (c.isUndefined(d)) {
      if (!c.isUndefined(l))
        return n(void 0, l, u);
    } else
      return n(l, d, u);
  }
  function s(l, d) {
    if (!c.isUndefined(d))
      return n(void 0, d);
  }
  function i(l, d) {
    if (c.isUndefined(d)) {
      if (!c.isUndefined(l))
        return n(void 0, l);
    } else
      return n(void 0, d);
  }
  function o(l, d, u) {
    if (u in t)
      return n(l, d);
    if (u in e)
      return n(void 0, l);
  }
  const f = {
    url: s,
    method: s,
    data: s,
    baseURL: i,
    transformRequest: i,
    transformResponse: i,
    paramsSerializer: i,
    timeout: i,
    timeoutMessage: i,
    withCredentials: i,
    withXSRFToken: i,
    adapter: i,
    responseType: i,
    xsrfCookieName: i,
    xsrfHeaderName: i,
    onUploadProgress: i,
    onDownloadProgress: i,
    decompress: i,
    maxContentLength: i,
    maxBodyLength: i,
    beforeRedirect: i,
    transport: i,
    httpAgent: i,
    httpsAgent: i,
    cancelToken: i,
    socketPath: i,
    responseEncoding: i,
    validateStatus: o,
    headers: (l, d) => a(Bt(l), Bt(d), !0)
  };
  return c.forEach(Object.keys(Object.assign({}, e, t)), function(d) {
    const u = f[d] || a, y = u(e[d], t[d], d);
    c.isUndefined(y) && u !== o || (r[d] = y);
  }), r;
}
const Ir = "1.6.2", We = {};
["object", "boolean", "number", "function", "string", "symbol"].forEach((e, t) => {
  We[e] = function(n) {
    return typeof n === e || "a" + (t < 1 ? "n " : " ") + e;
  };
});
const Ut = {};
We.transitional = function(t, r, n) {
  function a(s, i) {
    return "[Axios v" + Ir + "] Transitional option '" + s + "'" + i + (n ? ". " + n : "");
  }
  return (s, i, o) => {
    if (t === !1)
      throw new m(
        a(i, " has been removed" + (r ? " in " + r : "")),
        m.ERR_DEPRECATED
      );
    return r && !Ut[i] && (Ut[i] = !0, console.warn(
      a(
        i,
        " has been deprecated since v" + r + " and will be removed in the near future"
      )
    )), t ? t(s, i, o) : !0;
  };
};
function Yd(e, t, r) {
  if (typeof e != "object")
    throw new m("options must be an object", m.ERR_BAD_OPTION_VALUE);
  const n = Object.keys(e);
  let a = n.length;
  for (; a-- > 0; ) {
    const s = n[a], i = t[s];
    if (i) {
      const o = e[s], f = o === void 0 || i(o, s, e);
      if (f !== !0)
        throw new m("option " + s + " must be " + f, m.ERR_BAD_OPTION_VALUE);
      continue;
    }
    if (r !== !0)
      throw new m("Unknown option " + s, m.ERR_BAD_OPTION);
  }
}
const Le = {
  assertOptions: Yd,
  validators: We
}, N = Le.validators;
class ie {
  constructor(t) {
    this.defaults = t, this.interceptors = {
      request: new Dt(),
      response: new Dt()
    };
  }
  /**
   * Dispatch a request
   *
   * @param {String|Object} configOrUrl The config specific for this request (merged with this.defaults)
   * @param {?Object} config
   *
   * @returns {Promise} The Promise to be fulfilled
   */
  request(t, r) {
    typeof t == "string" ? (r = r || {}, r.url = t) : r = t || {}, r = F(this.defaults, r);
    const { transitional: n, paramsSerializer: a, headers: s } = r;
    n !== void 0 && Le.assertOptions(n, {
      silentJSONParsing: N.transitional(N.boolean),
      forcedJSONParsing: N.transitional(N.boolean),
      clarifyTimeoutError: N.transitional(N.boolean)
    }, !1), a != null && (c.isFunction(a) ? r.paramsSerializer = {
      serialize: a
    } : Le.assertOptions(a, {
      encode: N.function,
      serialize: N.function
    }, !0)), r.method = (r.method || this.defaults.method || "get").toLowerCase();
    let i = s && c.merge(
      s.common,
      s[r.method]
    );
    s && c.forEach(
      ["delete", "get", "head", "post", "put", "patch", "common"],
      (p) => {
        delete s[p];
      }
    ), r.headers = R.concat(i, s);
    const o = [];
    let f = !0;
    this.interceptors.request.forEach(function(h) {
      typeof h.runWhen == "function" && h.runWhen(r) === !1 || (f = f && h.synchronous, o.unshift(h.fulfilled, h.rejected));
    });
    const l = [];
    this.interceptors.response.forEach(function(h) {
      l.push(h.fulfilled, h.rejected);
    });
    let d, u = 0, y;
    if (!f) {
      const p = [Mt.bind(this), void 0];
      for (p.unshift.apply(p, o), p.push.apply(p, l), y = p.length, d = Promise.resolve(r); u < y; )
        d = d.then(p[u++], p[u++]);
      return d;
    }
    y = o.length;
    let v = r;
    for (u = 0; u < y; ) {
      const p = o[u++], h = o[u++];
      try {
        v = p(v);
      } catch (b) {
        h.call(this, b);
        break;
      }
    }
    try {
      d = Mt.call(this, v);
    } catch (p) {
      return Promise.reject(p);
    }
    for (u = 0, y = l.length; u < y; )
      d = d.then(l[u++], l[u++]);
    return d;
  }
  getUri(t) {
    t = F(this.defaults, t);
    const r = Rr(t.baseURL, t.url);
    return Er(r, t.params, t.paramsSerializer);
  }
}
c.forEach(["delete", "get", "head", "options"], function(t) {
  ie.prototype[t] = function(r, n) {
    return this.request(F(n || {}, {
      method: t,
      url: r,
      data: (n || {}).data
    }));
  };
});
c.forEach(["post", "put", "patch"], function(t) {
  function r(n) {
    return function(s, i, o) {
      return this.request(F(o || {}, {
        method: t,
        headers: n ? {
          "Content-Type": "multipart/form-data"
        } : {},
        url: s,
        data: i
      }));
    };
  }
  ie.prototype[t] = r(), ie.prototype[t + "Form"] = r(!0);
});
const re = ie;
class Xe {
  constructor(t) {
    if (typeof t != "function")
      throw new TypeError("executor must be a function.");
    let r;
    this.promise = new Promise(function(s) {
      r = s;
    });
    const n = this;
    this.promise.then((a) => {
      if (!n._listeners)
        return;
      let s = n._listeners.length;
      for (; s-- > 0; )
        n._listeners[s](a);
      n._listeners = null;
    }), this.promise.then = (a) => {
      let s;
      const i = new Promise((o) => {
        n.subscribe(o), s = o;
      }).then(a);
      return i.cancel = function() {
        n.unsubscribe(s);
      }, i;
    }, t(function(s, i, o) {
      n.reason || (n.reason = new X(s, i, o), r(n.reason));
    });
  }
  /**
   * Throws a `CanceledError` if cancellation has been requested.
   */
  throwIfRequested() {
    if (this.reason)
      throw this.reason;
  }
  /**
   * Subscribe to the cancel signal
   */
  subscribe(t) {
    if (this.reason) {
      t(this.reason);
      return;
    }
    this._listeners ? this._listeners.push(t) : this._listeners = [t];
  }
  /**
   * Unsubscribe from the cancel signal
   */
  unsubscribe(t) {
    if (!this._listeners)
      return;
    const r = this._listeners.indexOf(t);
    r !== -1 && this._listeners.splice(r, 1);
  }
  /**
   * Returns an object that contains a new `CancelToken` and a function that, when called,
   * cancels the `CancelToken`.
   */
  static source() {
    let t;
    return {
      token: new Xe(function(a) {
        t = a;
      }),
      cancel: t
    };
  }
}
const Qd = Xe;
function Zd(e) {
  return function(r) {
    return e.apply(null, r);
  };
}
function ep(e) {
  return c.isObject(e) && e.isAxiosError === !0;
}
const Fe = {
  Continue: 100,
  SwitchingProtocols: 101,
  Processing: 102,
  EarlyHints: 103,
  Ok: 200,
  Created: 201,
  Accepted: 202,
  NonAuthoritativeInformation: 203,
  NoContent: 204,
  ResetContent: 205,
  PartialContent: 206,
  MultiStatus: 207,
  AlreadyReported: 208,
  ImUsed: 226,
  MultipleChoices: 300,
  MovedPermanently: 301,
  Found: 302,
  SeeOther: 303,
  NotModified: 304,
  UseProxy: 305,
  Unused: 306,
  TemporaryRedirect: 307,
  PermanentRedirect: 308,
  BadRequest: 400,
  Unauthorized: 401,
  PaymentRequired: 402,
  Forbidden: 403,
  NotFound: 404,
  MethodNotAllowed: 405,
  NotAcceptable: 406,
  ProxyAuthenticationRequired: 407,
  RequestTimeout: 408,
  Conflict: 409,
  Gone: 410,
  LengthRequired: 411,
  PreconditionFailed: 412,
  PayloadTooLarge: 413,
  UriTooLong: 414,
  UnsupportedMediaType: 415,
  RangeNotSatisfiable: 416,
  ExpectationFailed: 417,
  ImATeapot: 418,
  MisdirectedRequest: 421,
  UnprocessableEntity: 422,
  Locked: 423,
  FailedDependency: 424,
  TooEarly: 425,
  UpgradeRequired: 426,
  PreconditionRequired: 428,
  TooManyRequests: 429,
  RequestHeaderFieldsTooLarge: 431,
  UnavailableForLegalReasons: 451,
  InternalServerError: 500,
  NotImplemented: 501,
  BadGateway: 502,
  ServiceUnavailable: 503,
  GatewayTimeout: 504,
  HttpVersionNotSupported: 505,
  VariantAlsoNegotiates: 506,
  InsufficientStorage: 507,
  LoopDetected: 508,
  NotExtended: 510,
  NetworkAuthenticationRequired: 511
};
Object.entries(Fe).forEach(([e, t]) => {
  Fe[t] = e;
});
const tp = Fe;
function Nr(e) {
  const t = new re(e), r = pr(re.prototype.request, t);
  return c.extend(r, re.prototype, t, { allOwnKeys: !0 }), c.extend(r, t, null, { allOwnKeys: !0 }), r.create = function(a) {
    return Nr(F(e, a));
  }, r;
}
const $ = Nr(Ve);
$.Axios = re;
$.CanceledError = X;
$.CancelToken = Qd;
$.isCancel = Pr;
$.VERSION = Ir;
$.toFormData = ge;
$.AxiosError = m;
$.Cancel = $.CanceledError;
$.all = function(t) {
  return Promise.all(t);
};
$.spread = Zd;
$.isAxiosError = ep;
$.mergeConfig = F;
$.AxiosHeaders = R;
$.formToJSON = (e) => Cr(c.isHTMLForm(e) ? new FormData(e) : e);
$.getAdapter = xr.getAdapter;
$.HttpStatusCode = tp;
$.default = $;
const rp = async (e, t) => {
  const r = t ? {
    script: "840",
    deploy: "1",
    compid: "6085217_SB2",
    h: "5fd6b130b6edf8fd6b7a"
  } : {
    script: "839",
    deploy: "1",
    compid: "6085217",
    h: "85343b65892ecc79b1db"
  }, n = t ? "7AmsQ7YWdw99FCtEsYDB" : "s7M6k2g8Ca9Rf3gQpxUm", a = new URLSearchParams(r), s = $.create({
    baseURL: t ? "https://6085217-sb2.extforms.netsuite.com" : "https://6085217.extforms.netsuite.com"
  }), i = `/app/site/hosting/scriptlet.nl?${a}`, {
    companyName: o = "無",
    email: f = "",
    contactName: l = "無",
    contactJobTitle: d = "無",
    contactPhone: u = "無",
    category: y = "無",
    country: v = "無",
    currency: p = "無",
    budget: h = "無",
    note: b = "無",
    schedule: g = "無",
    source: S,
    otherInfo: w = "無"
  } = e, I = {
    apikey: n,
    IKALA_BU: "KOL Radar",
    ExternalID: `${(/* @__PURE__ */ new Date()).toISOString()}-${Math.ceil(
      Math.random() * 1e4
    )}`,
    // 外部資料庫的 id，因為我們用不到，但又是必填，所以亂數產一組無意義的數字，目的是不要重複，不然 net suite 會回傳錯誤
    Name: o,
    Company_Name: o,
    Contact_Email: f,
    Email: f,
    Contact_Name: l,
    Contact_Job_Title: d,
    Contact_Phone: u,
    Category: Mf[y].netSuiteValue,
    Country: v,
    Primary_Currency: p,
    LS_Estimated_Budget: h,
    LS_Require_Detail: b,
    LS_Time_Frame: g,
    LS_Lead_Source: S,
    LS_Name: w
  };
  return await s.post(i, I, {
    headers: {
      "content-type": "text/plain;charset=UTF-8",
      "User-Agent": "Mozilla/5.0"
      // API 規定的 User-Agent
    }
  });
}, up = { create: rp }, lp = (e) => {
  if (e !== void 0)
    return Bf[e] ?? e;
};
export {
  qc as A,
  V as B,
  xo as C,
  Ro as D,
  Jt as E,
  Yt as F,
  mc as G,
  D as H,
  pe as I,
  fr as J,
  ip as K,
  C as L,
  jf as M,
  dr as P,
  M as _,
  Mf as a,
  Q as b,
  cp as c,
  kt as d,
  Ye as e,
  op as f,
  lp as g,
  ap as h,
  Ht as i,
  le as j,
  Ue as k,
  Jo as l,
  sp as m,
  up as n,
  Bf as o,
  E as p,
  qo as q,
  er as r,
  x as s,
  Ni as t,
  Wo as u,
  gi as v,
  Oi as w,
  wi as x,
  Js as y,
  ue as z
};
