import compact from 'lodash-es/compact'
import {
  BUSINESS_GENERAL_TAG_CODE_RECORD,
  BusinessGeneralOption,
} from '@/constants/business-type'

const getFilterOfficialValue = (
  order: BusinessGeneralOption['order'],
): BusinessGeneralOption['ampliType'] | undefined => {
  const found = Object.values(BUSINESS_GENERAL_TAG_CODE_RECORD).find((item) => {
    return item.order === order
  })

  return found?.ampliType
}

export const getFilterOfficialValues = (
  filterKolType?: string,
): BusinessGeneralOption['ampliType'][] => {
  if (!filterKolType) {
    return []
  }

  if (filterKolType === 'all') {
    return ['All']
  }

  const orders = filterKolType.split(',').map(Number)

  return compact(orders.map(getFilterOfficialValue))
}
