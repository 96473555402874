import { CurrencyCode } from '@buggy/shared'
import { isNull } from 'lodash-es'
import { StringParam, useQueryParams } from 'use-query-params'
import { Workspace } from '@/api/user-api'
import { useAuth } from '@/hooks/use-auth'
import { SupportedLanguages } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'
import {
  KolSchemaModel,
  PriceRange,
  Quotations,
  QuotationsByLang,
  QuotationType,
  SupportedQuotationLang,
} from '@/types/schema/kol-schema'

export const isQuotationsByLang = (
  quotations: KolSchemaModel['quotations'],
): quotations is QuotationsByLang => {
  return quotations?.hasOwnProperty(SupportedLanguages.zhTW) || false
}

/** 非 中文、日文的選項預計都拿鎂元 */
export const mapSupportedQuotationLang = (
  lang?: string,
): SupportedQuotationLang => {
  switch (lang) {
    case SupportedLanguages.zhTW:
    case SupportedLanguages.ja:
      return lang
    default:
      return SupportedLanguages.enUS
  }
}

export const mapQuotationCurrencyByLang = (
  lang?: string,
): SupportedQuotationCurrency => {
  switch (lang) {
    case SupportedLanguages.zhTW:
      return CurrencyCode.TWD
    case SupportedLanguages.ja:
      return CurrencyCode.JPY
    default:
      return CurrencyCode.USD
  }
}

export type SupportedQuotationCurrency =
  | CurrencyCode.TWD
  | CurrencyCode.JPY
  | CurrencyCode.USD

export const mapKolQuotationCurrency = (
  currency?: Workspace['currencyCode'],
): SupportedQuotationCurrency => {
  switch (currency) {
    case CurrencyCode.TWD:
    case CurrencyCode.JPY:
      return currency
    default:
      return CurrencyCode.USD
  }
}

interface QuotationsTypeRange {
  postQuotationRange: string
  videoQuotationRange: string
}

const useKolQuotationsRange = (
  kolQuotations?: KolSchemaModel['quotations'],
): QuotationsTypeRange => {
  const { userInfo } = useAuth()
  const { formatNumber, formatMessage } = useIntl()
  const [{ lang }] = useQueryParams({
    lang: StringParam,
  })

  const getPriceRange = ({ minPrice, maxPrice }: PriceRange): string => {
    return isNull(maxPrice)
      ? `$${formatMessage(
          { id: 'campaign:accept_price_minimum' },
          { minPrice: formatNumber(minPrice) },
        )}`
      : `$${formatNumber(minPrice)} - $${formatNumber(maxPrice)}`
  }

  const quotationLang = mapSupportedQuotationLang(
    userInfo?.language || lang || SupportedLanguages.zhTW,
  )

  const quotations: Quotations | undefined =
    kolQuotations && isQuotationsByLang(kolQuotations)
      ? kolQuotations?.[quotationLang]
      : kolQuotations

  if (!quotations) {
    return {
      postQuotationRange: '$ ******',
      videoQuotationRange: '$ ******',
    }
  }

  return {
    postQuotationRange: getPriceRange(quotations[QuotationType.Post]),
    videoQuotationRange: getPriceRange(quotations[QuotationType.Video]),
  }
}

export default useKolQuotationsRange
