import { CommunityKol } from '@/components/community/community-search/community-table'
import { APIStatus } from '@/types/api-status'
import { KolSchemaModel } from '@/types/schema/kol-schema'

const NAMESPACE = 'irm'

interface API {
  getCommunity: APIStatus
  getSetting: APIStatus
}

export interface State {
  apiStatus: API
  drawerVisible: boolean
  importModalVisible: {
    uuid: boolean
    link: boolean
    radar: boolean
    add: boolean
    report: boolean
    campaign: boolean
  }
  tag: {
    all: string[]
    kol: string[]
    irm: string[]
  }
  community: {
    total: number
    currentTotal: number
    kols: CommunityKol[]
    anchor: number | null
  }
  searchParams: {
    page: number
    gender?: string
    countryCode?: string
    platform?: string
    recommend?: boolean
    followerRange?: string
    engagementRateMin?: number
    engagementRateMax?: number
    fansUpRateMin?: number
    fansUpRateMax?: number
    videoViewRateMin?: number
    videoViewRateMax?: number
    kolName?: string
    customizedTags?: string
    reports?: { id: number; name: string }[]
    campaigns?: { id: number; name: string }[]
    collections?: { id: number; name: string }[]
    orderBy?: string
    cending?: string
  }
  setting: {
    resultDisplay: {
      [key: string]: boolean
    }
  }
  selectedKOLIDs: string[]
  selectedKOLs: CommunityKol[]
  selectedKolList: KolSchemaModel[]
}

export const ACTION_TYPES = {
  TOGGLE_DRAWER_VISIBLE: `${NAMESPACE}#toggleDrawerVisibile`,
  GET_KOL_TAGS: `${NAMESPACE}#getKOLTags`,
  SET_KOL_TAGS: `${NAMESPACE}#setKOLTags`,
  GET_ALL_TAGS: `${NAMESPACE}#getAllTags`,
  SET_ALL_TAGS: `${NAMESPACE}#setAllTags`,
  GET_IRM_TAGS: `${NAMESPACE}#getIRMTags`,
  SET_IRM_TAGS: `${NAMESPACE}#setIRMTags`,
  SET_IMPORT_MODAL_VISIBLE: `${NAMESPACE}#setImportModalVisible`,
  CLEAN_COMMUNITY_KOLS: `${NAMESPACE}#cleanCommunityKOLs`,
  GET_COMMUNITY_KOLS: `${NAMESPACE}#getCommunityKOLs`,
  SET_COMMUNITY_KOLS: `${NAMESPACE}#setCommunityKOLs`,
  SET_SEARCH_PARAMS: `${NAMESPACE}#setSearchParams`,
  SET_RESULT_DISPLAY: `${NAMESPACE}#setResultDisplay`,
  CLEAN_SEARCH_PARAMS: `${NAMESPACE}#cleanSearchParams`,
  RESET_SEARCH_PARAMS: `${NAMESPACE}#resetSearchParams`,
  CLEAN_SEARCH_RESULT: `${NAMESPACE}#cleanSearchResult`,
  SET_SELECTED_KOL_IDS: `${NAMESPACE}#setSelectedKOLIDs`,
  SET_SELECTED_KOL_LIST: `${NAMESPACE}#setSelectedKolList`,
  GET_COMMUNITY_SETTING: `${NAMESPACE}#getCommunitySetting`,
  ADD_SELECTED_KOLS: `${NAMESPACE}#addSelectedKOLs`,
  REMOVE_SELECTED_KOLS: `${NAMESPACE}#removeSelectedKOLs`,
}

export interface ActionPayload {
  TOGGLE_DRAWER_VISIBLE: void
  SET_KOL_TAGS: string[]
  SET_ALL_TAGS: string[]
  SET_IRM_TAGS: string[]
  GET_KOL_TAGS: string
  GET_ALL_TAGS: void
  GET_IRM_TAGS: void
  SET_IMPORT_MODAL_VISIBLE: {
    modal: keyof State['importModalVisible']
    visible: boolean
  }
  GET_COMMUNITY_KOLS: void
  SET_COMMUNITY_KOLS: {
    total: number
    list: any[]
    anchor: number | null
  }
  SET_SEARCH_PARAMS: Partial<State['searchParams']>
  CLEAN_SEARCH_PARAMS: void
  RESET_SEARCH_PARAMS: void
  SET_RESULT_DISPLAY: State['setting']['resultDisplay']
  SET_SELECTED_KOL_IDS: State['selectedKOLIDs']
  SET_SELECTED_KOL_LIST: State['selectedKolList']
  GET_COMMUNITY_SETTING: void
  ADD_SELECTED_KOLS: State['selectedKOLs']
  REMOVE_SELECTED_KOLS: State['selectedKOLs']
  CLEAN_COMMUNITY_KOLS: void
  CLEAN_SEARCH_RESULT: void
}

export interface Action {
  TOGGLE_DRAWER_VISIBLE: {
    type: typeof ACTION_TYPES.TOGGLE_DRAWER_VISIBLE
    payload: ActionPayload['TOGGLE_DRAWER_VISIBLE']
  }
  SET_KOL_TAGS: {
    type: typeof ACTION_TYPES.SET_KOL_TAGS
    payload: ActionPayload['SET_KOL_TAGS']
  }
  SET_ALL_TAGS: {
    type: typeof ACTION_TYPES.SET_ALL_TAGS
    payload: ActionPayload['SET_ALL_TAGS']
  }
  SET_IRM_TAGS: {
    type: typeof ACTION_TYPES.SET_IRM_TAGS
    payload: ActionPayload['SET_IRM_TAGS']
  }
  GET_KOL_TAGS: {
    type: typeof ACTION_TYPES.GET_KOL_TAGS
    payload: ActionPayload['GET_KOL_TAGS']
  }
  GET_ALL_TAGS: {
    type: typeof ACTION_TYPES.GET_ALL_TAGS
    payload: ActionPayload['GET_ALL_TAGS']
  }
  GET_IRM_TAGS: {
    type: typeof ACTION_TYPES.GET_IRM_TAGS
    payload: ActionPayload['GET_IRM_TAGS']
  }
  SET_IMPORT_MODAL_VISIBLE: {
    type: typeof ACTION_TYPES.SET_IMPORT_MODAL_VISIBLE
    payload: ActionPayload['SET_IMPORT_MODAL_VISIBLE']
  }
  GET_COMMUNITY_KOLS: {
    type: typeof ACTION_TYPES.GET_COMMUNITY_KOLS
    payload: ActionPayload['GET_COMMUNITY_KOLS']
  }
  SET_COMMUNITY_KOLS: {
    type: typeof ACTION_TYPES.SET_COMMUNITY_KOLS
    payload: ActionPayload['SET_COMMUNITY_KOLS']
  }
  SET_SEARCH_PARAMS: {
    type: typeof ACTION_TYPES.SET_SEARCH_PARAMS
    payload: ActionPayload['SET_SEARCH_PARAMS']
  }
  SET_RESULT_DISPLAY: {
    type: typeof ACTION_TYPES.SET_RESULT_DISPLAY
    payload: ActionPayload['SET_RESULT_DISPLAY']
  }
  CLEAN_SEARCH_PARAMS: {
    type: typeof ACTION_TYPES.CLEAN_SEARCH_PARAMS
    payload: ActionPayload['CLEAN_SEARCH_PARAMS']
  }
  RESET_SEARCH_PARAMS: {
    type: typeof ACTION_TYPES.RESET_SEARCH_PARAMS
    payload: ActionPayload['RESET_SEARCH_PARAMS']
  }
  SET_SELECTED_KOL_IDS: {
    type: typeof ACTION_TYPES.SET_SELECTED_KOL_IDS
    payload: ActionPayload['SET_SELECTED_KOL_IDS']
  }
  SET_SELECTED_KOL_LIST: {
    type: typeof ACTION_TYPES.SET_SELECTED_KOL_LIST
    payload: ActionPayload['SET_SELECTED_KOL_LIST']
  }
  GET_COMMUNITY_SETTING: {
    type: typeof ACTION_TYPES.GET_COMMUNITY_SETTING
    payload: ActionPayload['GET_COMMUNITY_SETTING']
  }
  ADD_SELECTED_KOLS: {
    type: typeof ACTION_TYPES.ADD_SELECTED_KOLS
    payload: ActionPayload['ADD_SELECTED_KOLS']
  }
  REMOVE_SELECTED_KOLS: {
    type: typeof ACTION_TYPES.REMOVE_SELECTED_KOLS
    payload: ActionPayload['REMOVE_SELECTED_KOLS']
  }
  CLEAN_COMMUNITY_KOLS: {
    type: typeof ACTION_TYPES.CLEAN_COMMUNITY_KOLS
    payload: ActionPayload['CLEAN_COMMUNITY_KOLS']
  }
  CLEAN_SEARCH_RESULT: {
    type: typeof ACTION_TYPES.CLEAN_SEARCH_RESULT
    payload: ActionPayload['CLEAN_SEARCH_RESULT']
  }
}
