import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAsync, useMount } from 'react-use'
import { AppContext } from '@/api/executor/app-context'
import { useLazyFetchAgentInfoQuery } from '@/api/influencer-api'
import {
  useLazyFetchUserInfoQuery,
  useLazyFetchUserStatusQuery,
} from '@/api/user-api'
import {
  Page,
  PageAuthentication,
  UserType,
} from '@/hooks/use-authorization/constants'
import getPage from '@/hooks/use-authorization/get-page'
import getPageAuthentication from '@/hooks/use-authorization/get-page-authentication'
import getRegistrationStatusUrl from '@/hooks/use-authorization/get-registration-status-url'
import getUserDefaultPage from '@/hooks/use-authorization/get-user-default-page'
import isFullyAuthorized from '@/hooks/use-authorization/is-fully-authorized'
import isUserAuthorizedForPage from '@/hooks/use-authorization/is-user-authorized-for-page'
import refreshTokenIfNeeded from '@/hooks/use-authorization/refresh-token-if-needed'
import { logout } from '@/store/me'
import getRedirectUrl from '@/utils/get-redirect-url'
import { goToPage } from '@/utils/routes/go-to-page'
import { tokenProvider } from '@/utils/token-provider'

interface UseAuthorization {
  (params: { pathname?: string; redirectUrl?: string }): {
    isAuthorized: boolean
    isAuthorizing: boolean
  }
}

const useAuthorization: UseAuthorization = ({ pathname, redirectUrl }) => {
  const [fetchUserInfo] = useLazyFetchUserInfoQuery()
  const [fetchWorkspaceStatus] = useLazyFetchUserStatusQuery()
  const [fetchAgentInfo] = useLazyFetchAgentInfoQuery()
  const jwt = tokenProvider()
  const router = useRouter()
  const dispatch = useDispatch()

  const authorizationUrl = redirectUrl || pathname || router.pathname
  const currentPage = getPage(authorizationUrl) || Page.Default

  const pageAuthentication = getPageAuthentication(currentPage)
  const [isAuthorized, setIsAuthorized] = useState(
    pageAuthentication === PageAuthentication.Ignore,
  )
  const [isAuthorizing, setIsAuthorizing] = useState(false)

  useEffect(() => {
    const handleRouterChange: VoidFunction = () => {
      setIsAuthorizing(false)
      setIsAuthorized(true)
    }

    router.events.on('routeChangeComplete', handleRouterChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouterChange)
    }
  }, [router.events])

  const authorized = (targetUrl?: string): Promise<boolean> | void => {
    if (targetUrl) {
      return goToPage(targetUrl, { replace: true })
    }

    if (redirectUrl) {
      return goToPage(redirectUrl, { replace: true })
    }

    setIsAuthorized(true)
    setIsAuthorizing(false)
  }

  const authorize = async (): Promise<void> => {
    setIsAuthorizing(true)

    const pageAuthentication = getPageAuthentication(currentPage)

    if (pageAuthentication === PageAuthentication.Authenticated) {
      await refreshTokenIfNeeded()
      let user
      try {
        // hack: 這邊使用 RTK 的話會拿到舊的 cache，清掉 cache 也沒用，所以改用 api executor，see: https://www.notion.so/ikala/Prod-SSO-32cdafc9ce314931bf68645ca09cf3c3?pvs=4 & https://github.com/reduxjs/redux-toolkit/issues/2802
        user = await AppContext.ApiExecutor.getUserInfo()
      } catch (e) {
        console.error('Fetch user info error', e)
        dispatch(logout())
        authorized(getRedirectUrl(router.asPath))
        return
      }

      if (user) {
        try {
          const isAd = user.type === UserType.NormalAd
          const isInfluencer = [UserType.Agency, UserType.NormalKol].includes(
            user.type,
          )
          const workspaceStatus = isAd
            ? await fetchWorkspaceStatus().unwrap()
            : undefined
          const agentInfo = isInfluencer
            ? await fetchAgentInfo().unwrap()
            : undefined

          if (isFullyAuthorized(user, workspaceStatus)) {
            if (isUserAuthorizedForPage(currentPage, user.type)) {
              authorized()
              return
            }

            authorized(
              getUserDefaultPage(user.type, workspaceStatus, agentInfo),
            )
            return
          }

          authorized(getRegistrationStatusUrl(user))
          return
        } catch (e) {
          console.error('Fetch user info error', e)

          authorized(Page.Login)
          return
        }
      }

      authorized(getRedirectUrl(router.asPath))
      return
    }

    if (pageAuthentication === PageAuthentication.Unauthenticated) {
      await refreshTokenIfNeeded()
      let user
      try {
        user = await fetchUserInfo().unwrap()
      } catch (e) {
        console.error('Fetch user info error', e)
        dispatch(logout())
        authorized(Page.Login)
        return
      }

      if (user) {
        try {
          const isAd = user.type === UserType.NormalAd
          const isInfluencer = [UserType.Agency, UserType.NormalKol].includes(
            user.type,
          )
          const workspaceStatus = isAd
            ? await fetchWorkspaceStatus().unwrap()
            : undefined
          const agentInfo = isInfluencer
            ? await fetchAgentInfo().unwrap()
            : undefined

          if (isFullyAuthorized(user, workspaceStatus)) {
            authorized(
              getUserDefaultPage(user.type, workspaceStatus, agentInfo),
            )
            return
          }

          authorized(getRegistrationStatusUrl(user))
          return
        } catch (e) {
          console.error('Fetch user info error', e)

          authorized(Page.Login)
          return
        }
      }

      authorized()
      return
    }

    authorized()
  }

  useAsync(async () => {
    if (!jwt) {
      return
    }

    try {
      const user = await fetchUserInfo().unwrap()
      if (!user) {
        return
      }

      const isAd = user.type === UserType.NormalAd
      if (isAd) {
        fetchWorkspaceStatus()
      } else {
        fetchAgentInfo()
      }
    } catch (e) {
      console.error(e)
    }
  }, [jwt])

  useMount(() => {
    return authorize()
  })

  return { isAuthorized, isAuthorizing }
}

export default useAuthorization
