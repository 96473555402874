import { MessageEnvelope, MessagePayload } from '@pubnub/react-chat-components'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { forIn, keyBy, last, noop, sortBy } from 'lodash-es'
import {
  ChannelMetadataObject,
  FetchMessagesParameters,
  FileEvent,
  MessageEvent,
  ObjectCustom,
  SetChannelMetadataParameters,
} from 'pubnub'
import {
  failureAPIStatus,
  initialAPIStatus,
  requestingAPIStatus,
  successAPIStatus,
} from '@/constants/api-status'
import { APIStatus } from '@/types/api-status'
import { CampaignCase } from '@/types/campaign-case'
import {
  Channel,
  GetChannelListRequest,
  GetNotificationtRequest,
  Notification,
} from '@/types/mock-api-types'
import { parseAsNumber } from '@/utils/parse-as-number'

export const pubNubClientNotDefinedError = new Error('PubNub is not defined')

export type MessageCountsResponse = { channel: string; count: number }

export type Conversation = {
  campaignCase?: CampaignCase
  channel?: Channel
  id: string
  lastReadTime: number
  latestMessage?: MessageEnvelope
  metadata?: ChannelMetadataObject<ObjectCustom>
  unreadMessageCount: number
}

type UnreadNotification = {
  notificationTimestamp: number
  channelId: string
  campaignId?: number
  campaignName?: string
  kol: {
    uuid?: string
    name?: string
  }
}

interface ChatroomState {
  apiStatus: {
    fetchChannelList: APIStatus
    fetchLatestMessage: APIStatus
    fetchNotifications: APIStatus
  }
  conversationList: {
    [id: string]: Conversation
  }
  currentConversationId?: string
  messageComposeVisible: boolean
  lastReadTimeKey?: string
  temporaryConversation?: Conversation
  notification: {
    unreadMessages: UnreadNotification[]
    page: number
    perPage: number
    total: number
    totalPage: number
  }
}

const initialState: ChatroomState = {
  apiStatus: {
    fetchChannelList: initialAPIStatus(),
    fetchLatestMessage: initialAPIStatus(),
    fetchNotifications: initialAPIStatus(),
  },
  currentConversationId: undefined,
  conversationList: {},
  messageComposeVisible: false,
  lastReadTimeKey: undefined,
  temporaryConversation: undefined,
  notification: {
    unreadMessages: [],
    page: 0,
    perPage: 0,
    total: 0,
    totalPage: 0,
  },
}

const chatroomSlice = createSlice({
  name: 'chatroom',
  initialState,
  reducers: {
    fetchChannelList: (
      state,
      action: PayloadAction<Omit<GetChannelListRequest, 'workspaceId'>>,
    ) => {
      state.apiStatus.fetchChannelList = requestingAPIStatus()
      noop(action)
    },
    fetchChannelListFailure: (state) => {
      state.apiStatus.fetchChannelList = failureAPIStatus()
    },
    fetchChannelListSuccess: (state, action: PayloadAction<Channel[]>) => {
      state.apiStatus.fetchChannelList = successAPIStatus()
      noop(action)
    },
    fetchLatestMessage: (state, action: PayloadAction<string[]>) => {
      state.apiStatus.fetchLatestMessage = requestingAPIStatus()
      noop(action)
    },
    fetchMessageCount: (state, action: PayloadAction<string[]>) => {
      noop(state, action)
    },
    fetchMessageCountFinished: (
      state,
      action: PayloadAction<PromiseSettledResult<MessageCountsResponse>[]>,
    ) => {
      action.payload.forEach((result) => {
        if (result.status === 'fulfilled') {
          const conversation = state.conversationList[result.value.channel]
          if (conversation) {
            conversation.unreadMessageCount = result.value.count
          }
        }
      })
    },
    fetchMessages: (state, action: PayloadAction<FetchMessagesParameters>) => {
      noop(state, action)
    },
    fetchMessagesFailure: (state, action: PayloadAction<Error>) => {
      state.apiStatus.fetchLatestMessage = failureAPIStatus()
      noop(state, action)
    },
    fetchMessagesSuccess: (
      state,
      action: PayloadAction<{ [channel: string]: MessageEnvelope[] }>,
    ) => {
      forIn(action.payload, (messages, channelId) => {
        const conversation = state.conversationList[channelId]
        if (conversation) {
          conversation.latestMessage = last(
            sortBy(messages, (message) => message.timetoken),
          )
        }
      })
      state.apiStatus.fetchLatestMessage = successAPIStatus()
    },
    fetchNotifications: (
      state,
      action: PayloadAction<Omit<GetNotificationtRequest, 'workspaceId'>>,
    ) => {
      state.apiStatus.fetchNotifications = requestingAPIStatus()
      noop(action)
    },
    fetchNotificationsFailure: (state) => {
      state.apiStatus.fetchNotifications = failureAPIStatus()
    },
    fetchNotificationsSuccess: (
      state,
      action: PayloadAction<{
        data: Notification
        page: number
      }>,
    ) => {
      const { notificationInfo, ...res } = action.payload.data

      const unreadMessages =
        notificationInfo?.map((info) => {
          const {
            notificationTimestamp,
            channelId,
            campaignId,
            campaignName,
            kol,
          } = info

          return {
            notificationTimestamp,
            channelId,
            campaignId,
            campaignName,
            kol: {
              uuid: kol.uuid,
              name: kol.name[0],
            },
          }
        }) || []

      state.notification = {
        ...res,
        unreadMessages: [
          ...(action.payload.page === 1
            ? []
            : state.notification.unreadMessages),
          ...unreadMessages,
        ],
      }
      state.apiStatus.fetchNotifications = successAPIStatus()
    },
    insertUnreadMessage: (state, action: PayloadAction<MessageEnvelope>) => {
      const messagePayload = action.payload.message as MessagePayload

      if (
        (action.payload.channel === state.currentConversationId &&
          state.messageComposeVisible) ||
        !messagePayload?.sender
      ) {
        return
      }

      state.notification.unreadMessages = [
        {
          notificationTimestamp: parseInt(action.payload.timetoken as string),
          channelId: action.payload.channel || '',
          campaignId: !!messagePayload.sender?.custom?.campaignId
            ? parseInt(messagePayload.sender.custom.campaignId as string)
            : undefined,
          campaignName: messagePayload.sender?.custom?.campaignName?.toString(),
          kol: {
            uuid: messagePayload.sender?.custom?.kolUuid?.toString(),
            name: messagePayload.sender?.custom?.kolName?.toString(),
          },
        },
        ...state.notification.unreadMessages.filter(
          (message) => message.channelId !== action.payload.channel,
        ),
      ]
    },
    removeUnreadMessage: (state, action: PayloadAction<string>) => {
      state.notification.unreadMessages = [
        ...state.notification.unreadMessages.filter(
          (message) => message.channelId !== action.payload,
        ),
      ]
    },
    updatePubNubChannelLastReadTime: (state, action: PayloadAction<string>) => {
      noop(state, action)
    },
    markMessageAsRead: (state, action: PayloadAction<string>) => {
      noop(state, action)
    },
    receiveFileEvent: (state, action: PayloadAction<FileEvent>) => {
      noop(state, action)
    },
    receiveMessageEvent: (state, action: PayloadAction<MessageEvent>) => {
      noop(state, action)
    },
    fetchPubNubChannelMetadata: (state, action: PayloadAction<string[]>) => {
      noop(state, action)
    },
    fetchPubNubChannelMetadataFailure: (
      state,
      action: PayloadAction<Error>,
    ) => {
      noop(state, action)
    },
    fetchPubNubChannelMetadataSuccess: (
      state,
      action: PayloadAction<ChannelMetadataObject<ObjectCustom>[]>,
    ) => {
      const lastReadTimeKey = state.lastReadTimeKey

      if (!lastReadTimeKey) {
        return
      }

      action.payload.forEach((metadata) => {
        const conversation = state.conversationList[metadata.id]

        if (!conversation) {
          return
        }

        if (
          conversation.metadata &&
          dayjs(metadata.updated).isBefore(conversation.metadata.updated)
        ) {
          return
        }

        conversation.metadata = metadata
        conversation.lastReadTime = parseAsNumber(
          metadata.custom?.[lastReadTimeKey],
          1,
        )
      })
    },
    setLastReadTimeKey: (state, action: PayloadAction<string>) => {
      state.lastReadTimeKey = action.payload
    },
    setTemporaryConversation: (
      state,
      action: PayloadAction<Conversation | undefined>,
    ) => {
      state.temporaryConversation = action.payload
    },
    setConversationList: (state, action: PayloadAction<Conversation[]>) => {
      state.conversationList = keyBy(
        action.payload,
        (conversation) => conversation.id,
      )
    },
    setMessageComposeVisible: (state, action: PayloadAction<boolean>) => {
      state.messageComposeVisible = action.payload
    },
    switchConversation: (state, action: PayloadAction<string>) => {
      state.temporaryConversation = undefined
      state.currentConversationId = action.payload
    },
    toggleMessageComposeVisible: (state) => {
      state.messageComposeVisible = !state.messageComposeVisible
    },
    updatePubNubChannelMetadata: (
      state,
      action: PayloadAction<SetChannelMetadataParameters<ObjectCustom>>,
    ) => {
      noop(state, action)
    },
    updatePubNubChannelMetadataFailure: (
      state,
      action: PayloadAction<Error>,
    ) => {
      noop(state, action)
    },
  },
})

export const {
  fetchChannelList,
  fetchChannelListFailure,
  fetchChannelListSuccess,
  fetchLatestMessage,
  fetchMessageCount,
  fetchMessageCountFinished,
  fetchMessages,
  fetchMessagesFailure,
  fetchMessagesSuccess,
  fetchNotifications,
  fetchNotificationsFailure,
  fetchNotificationsSuccess,
  insertUnreadMessage,
  removeUnreadMessage,
  fetchPubNubChannelMetadata,
  fetchPubNubChannelMetadataFailure,
  fetchPubNubChannelMetadataSuccess,
  markMessageAsRead,
  updatePubNubChannelLastReadTime,
  receiveFileEvent,
  receiveMessageEvent,
  setConversationList,
  setMessageComposeVisible,
  setLastReadTimeKey,
  setTemporaryConversation,
  switchConversation,
  toggleMessageComposeVisible,
  updatePubNubChannelMetadata,
  updatePubNubChannelMetadataFailure,
} = chatroomSlice.actions

export default chatroomSlice.reducer
