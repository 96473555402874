import { APIStatus } from '@/types/api-status'

export const initialAPIStatus = (): APIStatus => {
  return {
    failure: false,
    failureCode: undefined,
    requesting: false,
    success: false,
  }
}

export const requestingAPIStatus = (): APIStatus => {
  return {
    failure: false,
    failureCode: undefined,
    requesting: true,
    success: false,
  }
}

export const successAPIStatus = (): APIStatus => {
  return {
    failure: false,
    failureCode: undefined,
    requesting: false,
    success: true,
  }
}

export const failureAPIStatus = (failureCode?: number): APIStatus => {
  return {
    failure: true,
    failureCode,
    requesting: false,
    success: false,
  }
}

export const doneAPIStatus = (): APIStatus => {
  return initialAPIStatus()
}
