import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import CheckboxGroup from 'antd/lib/checkbox/Group'
import { isEmpty, isEqual, isNil, reduce } from 'lodash-es'
import React, { FunctionComponent, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import {
  ANY_KOL_TYPE_CODE,
  EXCLUDED_ALL_KOL_TYPE_CODE,
  ExcludeType,
  getSelectedExcludedBusiness,
  useExclusionFilter,
} from '@/components/search/filters/hooks/use-exclusion-filter'
import { BUSINESS_OPTIONS } from '@/constants/business-type'
import { useIntl } from '@/i18n/hooks/use-intl'
import { Condition } from '@/types/api/search'

interface ExclusionFilterProps {
  filterValues: Condition
  onValuesChange: (changedValues: Condition) => void
}

const ExclusionFilterContent: FunctionComponent<ExclusionFilterProps> = ({
  filterValues,
  onValuesChange,
}) => {
  const { formatMessage } = useIntl()
  const filterValuesRef = React.useRef<Condition>(filterValues)
  const { excludeOptions, allExcludeOptionsKeys } = useExclusionFilter()
  const [excludedOptions, setExcludedOptions] = useState<ExcludeType[]>(() =>
    allExcludeOptionsKeys.filter((key) => !isNil(filterValues[key])),
  )

  const [excludedBusiness, setExcludedBusiness] = useState<string[]>(() =>
    getSelectedExcludedBusiness(filterValues.filter_kol_type),
  )

  const businessOptions = BUSINESS_OPTIONS.map(({ value, label }) => ({
    label: formatMessage({ id: label }),
    value,
  }))

  const selectedKolType = excludedOptions.includes(ExcludeType.FilterKolType)

  const handleExcludeOptionChange = (
    value: ExcludeType,
    checked: boolean,
  ): void => {
    if (value === ExcludeType.FilterKolType) {
      handleExcludeBusinessChange(
        checked ? BUSINESS_OPTIONS.map(({ value }) => value) : [],
      )
    }

    const values = checked
      ? [...excludedOptions, value]
      : excludedOptions.filter((v) => v !== value)

    setExcludedOptions(values)
  }

  const handleExcludeBusinessChange = (values: string[]): void => {
    if (isEmpty(values)) {
      setExcludedOptions(
        excludedOptions.filter((v) => v !== ExcludeType.FilterKolType),
      )
    }
    setExcludedBusiness(values)
  }

  const getKolTypeParams = (): string | undefined => {
    if (BUSINESS_OPTIONS.length === excludedBusiness.length) {
      return EXCLUDED_ALL_KOL_TYPE_CODE
    }

    return isEmpty(excludedBusiness)
      ? ANY_KOL_TYPE_CODE
      : excludedBusiness.join(',')
  }

  const applyChanges = (): void => {
    const excludedOptionsParams = reduce(
      allExcludeOptionsKeys,
      (result, key) => {
        if (key === ExcludeType.FilterKolType) {
          result[key] = getKolTypeParams()
        } else {
          result[key] = excludedOptions.includes(key) ? true : undefined
        }
        return result
      },
      {},
    )

    onValuesChange(excludedOptionsParams)
  }

  useUpdateEffect(() => {
    if (!isEqual(filterValuesRef.current, filterValues)) {
      filterValuesRef.current = filterValues
      setExcludedOptions(
        allExcludeOptionsKeys.filter(
          (key) =>
            filterValues[key] !== ANY_KOL_TYPE_CODE &&
            !isNil(filterValues[key]),
        ),
      )
      setExcludedBusiness(
        getSelectedExcludedBusiness(filterValues.filter_kol_type),
      )
    }
  }, [filterValues, excludedOptions, excludedBusiness])

  useUpdateEffect(() => {
    applyChanges()
  }, [excludedOptions, excludedBusiness])

  return (
    <SelectWrapper>
      <div>
        <CheckboxGroup value={excludedOptions}>
          {excludeOptions.map(({ value, label }) => (
            <Checkbox
              key={value}
              value={value}
              onChange={(e: CheckboxChangeEvent): void =>
                handleExcludeOptionChange(value, e.target.checked)
              }
            >
              {label}
            </Checkbox>
          ))}
        </CheckboxGroup>
        {selectedKolType && (
          <StyledBusinessCheckboxGroup
            options={businessOptions}
            value={excludedBusiness}
            onChange={handleExcludeBusinessChange}
          />
        )}
      </div>
    </SelectWrapper>
  )
}

const StyledBusinessCheckboxGroup = styled(Checkbox.Group)`
  padding-left: 20px;
`

const StyledCheckAll = styled(Checkbox)``

const SelectWrapper = styled.div`
  padding: 0 4px;
  overflow-y: auto;

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    align-content: space-between;

    .ant-checkbox-wrapper {
      :last-child {
        margin-bottom: 14px;
      }

      margin: 10px;
    }
  }

  ${StyledCheckAll} {
    margin: 10px 0 14px;
  }
`

export default ExclusionFilterContent
