import { Button, Col, Modal, Row } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FunctionComponent, ReactNode, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import useIrmContentContext from '@/components/layout/hooks/use-irm-content-context'
import VipButton from '@/components/vip-button'
import { MAX_Z_INDEX } from '@/constants/css'
import routes from '@/hooks/use-authorization/routes'
import { useSelector } from '@/hooks/use-selector'
import useTrial from '@/hooks/use-trial'
import { useIntl } from '@/i18n/hooks/use-intl'
import { reduxAPI } from '@/store/redux-api'
import { actions } from '@/store/state.modal'
import { ampli, ClickUpgradePlanProperties } from '@/utils/ampli'

/**
 * @todo extend ModalProps
 */
interface VipOnlyModalProps {
  /**
   * @todo change to required
   */
  section?: ClickUpgradePlanProperties['section']
  visible?: boolean
  onClose?: VoidFunction
  title?: ReactNode
  isEnterprise?: boolean
  onlyQuota?: boolean
  canContinueBrowsingFromProp?: boolean
}

export const VipOnlyModal: FunctionComponent<VipOnlyModalProps> = ({
  section,
  visible,
  onClose,
  title,
  isEnterprise,
  onlyQuota = false,
  canContinueBrowsingFromProp,
}) => {
  const router = useRouter()
  const irmContentContext = useIrmContentContext()
  const containerRef = useRef<HTMLDivElement>(null)

  const { formatMessage } = useIntl()
  const { trial } = useTrial()
  const {
    title: stateTitle,
    canContinueBrowsing: canContinueBrowsingFromRedux,
  } = useSelector((state) => state.modal.vipOnly)
  const canContinueBrowsing =
    canContinueBrowsingFromProp ?? canContinueBrowsingFromRedux
  const vipOnlyModal = reduxAPI.state.modal.hooks.useVisible('vipOnly')
  const dispatch = useDispatch()

  const toggleVisible: VoidFunction = () => vipOnlyModal.toggleVisible()

  useUpdateEffect(() => {
    if (visible ?? vipOnlyModal.visible) {
      ampli.blockedVipOnly()
    } else {
      dispatch(actions.vipOnly.setTitle(null))
    }
  }, [vipOnlyModal.visible, visible])

  const handleClose = (): void => {
    if (onClose) {
      onClose()
      return
    }

    toggleVisible()
  }

  return (
    <div ref={containerRef}>
      <Modal
        closable={canContinueBrowsing}
        footer={null}
        getContainer={
          irmContentContext?.containerRef?.current ??
          containerRef.current ??
          undefined
        }
        open={visible ?? vipOnlyModal.visible}
        zIndex={MAX_Z_INDEX}
        onCancel={handleClose}
      >
        <>
          <Row justify='center'>
            <Col>
              {title && <Title>{title}</Title>}
              {!title &&
                (stateTitle ? (
                  <Title>{stateTitle}</Title>
                ) : (
                  <Title>
                    {formatMessage({
                      id: isEnterprise
                        ? 'vip:free_popup_enterprise_only_title'
                        : 'search:free_popup_vip_only_title',
                    })}
                  </Title>
                ))}
            </Col>
          </Row>
          <Row gutter={24} justify='center'>
            <StyledCol>
              {trial.length === 0 && !canContinueBrowsing ? (
                <Link
                  href={routes.workspacePlan.stringifyUrl({})}
                  onClick={(): void => {
                    handleClose()
                    ampli.clickWorkspacePlan({ path: router.asPath })
                  }}
                >
                  <Button>
                    {formatMessage({ id: 'account:apply_premium_trial' })}
                  </Button>
                </Link>
              ) : (
                <Button onClick={handleClose}>
                  {formatMessage({ id: 'search:free_popop_vip_only_read_btn' })}
                </Button>
              )}
            </StyledCol>
            <Col>
              <Link
                href={routes.workspacePlan.stringifyUrl({})}
                onClick={(): void => {
                  handleClose()
                  ampli.clickWorkspacePlan({ path: router.asPath })
                }}
              >
                <VipButton section={section}>
                  {onlyQuota
                    ? formatMessage({ id: 'kol:btn_go_to_purchase' })
                    : formatMessage({ id: 'vip:upgrade_plan_btn' })}
                </VipButton>
              </Link>
            </Col>
          </Row>
        </>
      </Modal>
    </div>
  )
}

const Title = styled.div`
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: bold;
  color: #606266;
  text-align: center;
`

const StyledCol = styled(Col)`
  margin-bottom: 8px;
`
