import { useCallback, useState } from 'react'
import { useMount } from 'react-use'
import ZeusEnvironment from '@/constants/zeus-environment'
import useSyncRadarUserProps from '@/hooks/use-sync-radar-user-props'
import { ampli } from '@/utils/ampli'
import logger from '@/utils/logger'
import { tokenProvider } from '@/utils/token-provider'
import GeneralEventPropertiesPlugin from '@/utils/tracking/general-event-properties-plugin'
import SendGoogleAnalyticsEventPlugin from '@/utils/tracking/send-google-analytics-event-plugin'

const isDev =
  process.env.NEXT_PUBLIC_ZEUS_ENVIRONMENT !== ZeusEnvironment.Production

type UseAmplitudeInit = () => void

const useAmplitudeInit: UseAmplitudeInit = () => {
  const [amplitudeReady, setAmplitudeReady] = useState(false)

  useSyncRadarUserProps({
    enabled: amplitudeReady,
    syncFn: useCallback(
      (userProps) => ampli.identify(userProps.userId, userProps),
      [],
    ),
  })

  useMount(async (): Promise<void> => {
    const isLogin = !!tokenProvider() // 如果有 token，我們就假設使用者為登入狀態，但這不涵蓋 token 有效性的判斷

    try {
      await ampli.load({
        environment: isDev ? 'development' : ZeusEnvironment.Production,
        client: {
          configuration: {
            appVersion: process.env.NEXT_PUBLIC_VERSION,
            defaultTracking: {
              pageViews: isLogin,
              fileDownloads: false,
              sessions: false,
              formInteractions: false,
            },
          },
        },
      }).promise

      if (!process.env.NEXT_PUBLIC_GA4_ID?.length) {
        console.error('GA4_ID should not be undefined or empty.')
      } else {
        ampli.client.add(new SendGoogleAnalyticsEventPlugin())
      }

      ampli.client.add(new GeneralEventPropertiesPlugin())

      setAmplitudeReady(true)
    } catch (e) {
      logger.error(e)
    }
  })
}

export default useAmplitudeInit
