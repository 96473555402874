import { Word } from '@buggy/shared'
import { ColumnFilterItem } from 'antd/lib/table/interface'
import { APIStatus } from '@/types/api-status'
import { Nullable } from '@/types/nullable'
import {
  ChartRowData,
  CommentRawData,
  Statistics,
  Term,
} from '@/types/schema/chart-data-schema'
import { Report, ReportContent } from '@/types/schema/report-schema'

const NAMESPACE = 'report'

export interface CrawlerTask {
  id: Nullable<number>
  createdAt: Nullable<string>
  updatedAt: Nullable<string>
  deletedAt: Nullable<string>
  uuid: Nullable<string>
  crawlerJobId: Nullable<number>
  status: Nullable<string>
  traceableId: Nullable<number>
  traceableType: Nullable<string>
  mediaType: string
  exception: string
}
interface CrawlerJob {
  id: Nullable<number>
  createdAt: Nullable<string>
  updatedAt: Nullable<string>
  deletedAt: Nullable<string>
  uuid: Nullable<string>
  status: Nullable<string>
  tasks: CrawlerTask[]
  crawlerJobReport: {
    isRead: boolean
  }
}

interface StateReport {
  needRefresh: boolean
  list: Report[]
  total: number
  currentTotal: number
  report?: Report
  crawling: boolean
  chartData: {
    [section: string]: ChartRowData[]
  }
  allChartData: {
    [section: string]: ChartRowData[]
  }
  commentData?: CommentRawData[]
  terms: Term[]
  frequency: Word[]
  ownReport?: boolean
  crawlerJobs: CrawlerJob[]
}

interface SectionIsLoading {
  section: string
  isLoading: boolean
}

export type SummaryFilterSection =
  | 'score'
  | 'comment'
  | 'kol'
  | 'post'
  | 'platform'

interface SummaryFilter {
  date: any[]
  kol: string[]
  platform: string[]
}

interface Tab {
  index: number
  id: number
}

interface API {
  getReports: APIStatus
  triggerCrawler: APIStatus
}

interface SummaryFilterSettings {
  currentReportID: number | 'demo'
  filterByBoard: Record<SummaryFilterSection, SummaryFilter>
}

export type ReportMode = 'preview' | 'edit' | ''

export interface State {
  data: StateReport
  editGuestVisibility: {
    [key: string]: boolean
  }
  error: Error | null
  loading: boolean
  isCommentsLoading: boolean
  isFrequencyLoading: boolean
  summaryFilter: SummaryFilterSettings
  editingReportContent?: ReportContent
  statistics: Statistics
  mode: ReportMode
  apiStatus: API
  ownerList: ColumnFilterItem[]
  unreadReport: boolean
}

export const ACTION_TYPES = {
  CLEAN_REPORT: `${NAMESPACE}#cleanReport`,
  CLEAN_REPORT_CONTENT: `${NAMESPACE}#cleanReportContent`,
  SET_ALL_CHART_DATA: `${NAMESPACE}#setAllChartData`,
  SET_CHART_DATA: `${NAMESPACE}#setChartData`,
  SET_COMMENT_DATA: `${NAMESPACE}#setCommentData`,
  SET_FREQUENCY: `${NAMESPACE}#setFrequency`,
  SET_IS_LOADING: `${NAMESPACE}#setIsLoading`,
  SET_REPORT: `${NAMESPACE}#setReport`,
  ADD_REPORT_KOLS: `${NAMESPACE}#addReportKOLs`,
  DELETE_REPORT_KOL: `${NAMESPACE}#deleteReportKOL`,
  SET_REPORT_CONTENT: `${NAMESPACE}#setReportContent`,
  REFRESH_LIST: `${NAMESPACE}#refreshList`,
  GET_REPORT_LIST: `${NAMESPACE}#getReportList`,
  SET_REPORT_LIST: `${NAMESPACE}#setReportList`,
  SET_STATISTIC: `${NAMESPACE}#setStatistics`,
  SET_SUMMARY_FILTER: `${NAMESPACE}#setSummaryFilter`,
  SET_TERMS: `${NAMESPACE}#setTerms`,
  SET_MODE: `${NAMESPACE}#setMode`,
  SET_GUEST_VISIBILITY: `${NAMESPACE}#setGuestVisibility`,
  SET_VISIBILITY: `${NAMESPACE}#setVisibility`,
  TRIGGER_CRAWLER: `${NAMESPACE}#triggerCrawler`,
  TRIGGERED_CRAWLER: `${NAMESPACE}#triggeredCrawler`,
  GET_OWNER_LIST: `${NAMESPACE}#getOwnerList`,
  SET_OWNER_LIST: `${NAMESPACE}#setOwnerList`,
  GET_REPORT_CRAWLING_STATUS: `${NAMESPACE}#getReportCrawlingStatus`,
  SET_REPORT_CRAWLING_STATUS: `${NAMESPACE}#setReportCrawlingStatus`,
  GET_REPORT_CRAWLER_JOBS: `${NAMESPACE}#getReportCrawlerJobs`,
  SET_REPORT_CRAWLER_JOBS: `${NAMESPACE}#setReportCrawlerJobs`,
  GET_UNREAD_REPORT_STATUS: `${NAMESPACE}#getUnreadReportStatus`,
  SET_UNREAD_REPORT_STATUS: `${NAMESPACE}#setUnreadReportStatus`,
}
type ReportId = number | 'demo'

export interface ActionPayload {
  SET_ALL_CHART_DATA: {
    data: ChartRowData[]
  }
  SET_CHART_DATA: {
    section: SummaryFilterSection
    data: ChartRowData[]
  }
  SET_COMMENT_DATA: CommentRawData[]
  SET_FREQUENCY: Word[]
  SET_IS_LOADING: SectionIsLoading
  SET_REPORT: Report | undefined
  SET_REPORT_CONTENT: ReportContent
  GET_REPORT_LIST: {
    page: number
    workspaceID?: number
    name?: string
    ownerIds?: number[]
  }
  SET_REPORT_LIST: {
    list: Report[]
    total: number
  }
  SET_STATISTIC: Statistics
  SET_SUMMARY_FILTER: {
    currentReportID: number | 'demo'
    section: SummaryFilterSection
    data: SummaryFilter
  }
  SET_TAB: Tab
  SET_TERMS: Term[]
  ADD_REPORT_KOLS: any[]
  DELETE_REPORT_KOL: { id: number }
  SET_MODE: ReportMode
  SET_GUEST_VISIBILITY: { [key: string]: boolean }
  SET_VISIBILITY: { [key: string]: boolean }
  TRIGGER_CRAWLER: { reportID: ReportId; workspaceID?: number }
  TRIGGERED_CRAWLER: {
    reportID: ReportId
    workspaceID?: number
  }
  GET_OWNER_LIST: {
    workspaceID?: number
  }
  SET_OWNER_LIST: ColumnFilterItem[]
  GET_REPORT_CRAWLING_STATUS: {
    reportID: ReportId
    workspaceID?: number
  }
  SET_REPORT_CRAWLING_STATUS: boolean
  GET_REPORT_CRAWLER_JOBS: {
    reportID: ReportId
    workspaceID?: number
  }
  SET_REPORT_CRAWLER_JOBS: any[]
  GET_UNREAD_REPORT_STATUS: {
    workspaceID?: number
  }
  SET_UNREAD_REPORT_STATUS: boolean
}

export interface Action {
  GET_REPORT_LIST: {
    type: typeof ACTION_TYPES.GET_REPORT_LIST
    payload: ActionPayload['GET_REPORT_LIST']
  }
  SET_REPORT_LIST: {
    type: typeof ACTION_TYPES.SET_REPORT_LIST
    payload: ActionPayload['SET_REPORT_LIST']
  }
  REFRESH_LIST: {
    type: typeof ACTION_TYPES.REFRESH_LIST
  }
  TRIGGER_CRAWLER: {
    type: typeof ACTION_TYPES.TRIGGER_CRAWLER
    payload: ActionPayload['TRIGGER_CRAWLER']
  }
  TRIGGERED_CRAWLER: {
    type: typeof ACTION_TYPES.TRIGGERED_CRAWLER
    payload: ActionPayload['TRIGGERED_CRAWLER']
  }
  GET_OWNER_LIST: {
    type: typeof ACTION_TYPES.GET_OWNER_LIST
    payload: ActionPayload['GET_OWNER_LIST']
  }
  SET_OWNER_LIST: {
    type: typeof ACTION_TYPES.SET_OWNER_LIST
    payload: ActionPayload['SET_OWNER_LIST']
  }
  GET_REPORT_CRAWLING_STATUS: {
    type: typeof ACTION_TYPES.GET_REPORT_CRAWLING_STATUS
    payload: ActionPayload['GET_REPORT_CRAWLING_STATUS']
  }
  SET_REPORT_CRAWLING_STATUS: {
    type: typeof ACTION_TYPES.SET_REPORT_CRAWLING_STATUS
    payload: ActionPayload['SET_REPORT_CRAWLING_STATUS']
  }
  GET_REPORT_CRAWLER_JOBS: {
    type: typeof ACTION_TYPES.GET_REPORT_CRAWLER_JOBS
    payload: ActionPayload['GET_REPORT_CRAWLER_JOBS']
  }
  SET_REPORT_CRAWLER_JOBS: {
    type: typeof ACTION_TYPES.SET_REPORT_CRAWLER_JOBS
    payload: ActionPayload['SET_REPORT_CRAWLER_JOBS']
  }
  GET_UNREAD_REPORT_STATUS: {
    type: typeof ACTION_TYPES.GET_UNREAD_REPORT_STATUS
    payload: ActionPayload['GET_UNREAD_REPORT_STATUS']
  }
  SET_UNREAD_REPORT_STATUS: {
    type: typeof ACTION_TYPES.SET_UNREAD_REPORT_STATUS
    payload: ActionPayload['SET_UNREAD_REPORT_STATUS']
  }
  SET_REPORT: {
    type: typeof ACTION_TYPES.SET_REPORT
    payload: ActionPayload['SET_REPORT']
  }
  SET_ALL_CHART_DATA: {
    type: typeof ACTION_TYPES.SET_ALL_CHART_DATA
    payload: ActionPayload['SET_ALL_CHART_DATA']
  }
}
