import { z } from 'zod'
import { Duration } from '@/components/common/duration-select'
import { tabSchema } from '@/components/kol/hooks/use-section-tabs'
import { CountryCode } from '@/constants/country-currency'
import { Page } from '@/hooks/use-authorization/constants'
import searchRoute from '@/hooks/use-authorization/search-route'
import { DEFAULT_DURATION } from '@/hooks/use-kol-detail-duration'
import { CampaignKolListTab, CampaignTab } from '@/store/campaign'
import { kolPostListSorterSchema } from '@/types/schema/api/kol'
import { postTypeSchema } from '@/types/schema/post-type'
import { VisitKolDetailProperties } from '@/utils/ampli'
import { UNLIMITED_COUNTRY_CODE } from '@/utils/check-allowed-country-code'
import { PlatformShortcode } from '@/utils/convert-platform'
import makeRoute, { emptySchema } from '@/utils/routes/make-route'

const kolDetailDurationSchema = z.enum([
  Duration.ThreeMonths,
  Duration.SixMonths,
])

export type KolDetailDuration = z.infer<typeof kolDetailDurationSchema>

export const DURATION_MONTH_INTEGER_MAP: Record<KolDetailDuration, number> = {
  [Duration.ThreeMonths]: 3,
  [Duration.SixMonths]: 6,
}

export const kolDetailPostListAdvancedFilterSchema = z.object({
  postListCustomizedTagsNames: z.string().array().optional(),
  postListPostTypes: postTypeSchema.array().optional(),
  postListDateRangeStart: z.string().datetime().optional(),
  postListDateRangeEnd: z.string().datetime().optional(),
})

const kolDetailSearchParamsSchema = z
  .object({
    platform: z.nativeEnum(PlatformShortcode).optional(),
    duration: kolDetailDurationSchema.optional().default(DEFAULT_DURATION),
    lang: z.string().optional(),
    country_code: z
      .nativeEnum(CountryCode)
      .or(z.literal(UNLIMITED_COUNTRY_CODE))
      .optional(),
    keyword: z.string().optional(),
    postListSort: kolPostListSorterSchema.optional().default('publishTime'),
    postListShowSponsoredPost: z.coerce.boolean().optional(),
    postListNextPagingToken: z.string().optional(),
    postListPlatform: z.nativeEnum(PlatformShortcode).optional(),
  })
  .merge(kolDetailPostListAdvancedFilterSchema)

export const kolDetailFragmentIdentifierSchema = z.enum([
  ...tabSchema.options,
  'postList',
])

const routes = {
  login: makeRoute(
    Page.Login,
    emptySchema,
    z.object({
      lang: z.string().optional(),
      identity: z.enum(['ad', 'kol']).optional().default('ad'),
    }),
  ),
  collectionsKolId: makeRoute(
    Page.CollectionsKolId,
    z.object({
      id: z.coerce.number(),
    }),
  ),
  kolId: makeRoute(
    Page.KolId,
    z.object({
      kolId: z.string(),
    }),
    kolDetailSearchParamsSchema.extend({
      from: z.string().optional(),
      fromKolId: z.string().optional(),
      fromKolName: z.string().optional(),
      searchId: z.string().optional(),
      isUnlocked: z.coerce.boolean().optional(),
      isFilterBudget: z.coerce.boolean().optional(),
      aiType: z.custom<VisitKolDetailProperties['aiType']>().optional(),
      aiFilterType: z
        .custom<VisitKolDetailProperties['aiFilterType']>()
        .optional(),
    }),
    kolDetailFragmentIdentifierSchema.optional(),
  ),
  kolSelf: makeRoute(
    Page.KolSelf,
    emptySchema,
    kolDetailSearchParamsSchema,
    kolDetailFragmentIdentifierSchema.optional(),
  ),
  kolAgencyId: makeRoute(
    Page.KolAgencyId,
    z.object({
      kolId: z.string(),
    }),
    kolDetailSearchParamsSchema,
    kolDetailFragmentIdentifierSchema.optional(),
  ),
  kolSettings: makeRoute(
    Page.KolSettings,
    emptySchema,
    z.object({ tab: z.enum(['accounting']).optional() }),
  ),
  publicKolDetail: makeRoute(
    Page.PublicKolDetail,
    z.object({
      kolId: z.string(),
    }),
    kolDetailSearchParamsSchema.extend({
      from: z.string().optional(),
      keywordFrom: z.string().optional(),
      deviceId: z.string().optional(),
    }),
    kolDetailFragmentIdentifierSchema.optional(),
  ),
  campaignListCreateCampaign: makeRoute(
    Page.CampaignListCreateCampaign,
    emptySchema,
  ),
  campaignList: makeRoute(Page.CampaignList, emptySchema),
  campaignListDetail: makeRoute(
    Page.CampaignListDetail,
    emptySchema,
    z.object({
      campaignId: z.coerce.number(),
      tab: z.nativeEnum(CampaignTab).optional().default(CampaignTab.kolList),
      kolListTab: z
        .nativeEnum(CampaignKolListTab)
        .optional()
        .default(CampaignKolListTab.Preparing),
    }),
  ),
  competitiveBrandAnalyses: makeRoute(Page.CompetitiveBrandAnalyses),
  competitiveBrandComprehensiveAnalyses: makeRoute(
    Page.CompetitiveBrandComprehensiveAnalyses,
    z.object({
      id: z.coerce.number(),
    }),
  ),
  competitiveBrandPlatformsAnalyses: makeRoute(
    Page.CompetitiveBrandPlatformsAnalyses,
    z.object({
      id: z.coerce.number(),
    }),
  ),
  competitiveMentionContentAnalyses: makeRoute(
    Page.CompetitiveMentionContentAnalyses,
    z.object({
      id: z.coerce.number(),
    }),
    z.object({
      platform: z
        .nativeEnum(PlatformShortcode)
        .optional()
        .default(PlatformShortcode.Instagram),
      duration: z.nativeEnum(Duration).optional().default(Duration.SixMonths),
      withoutOfficialAccounts: z.coerce.boolean().optional().default(true),
      selectedKolIds: z.string().array().optional(),
      countryCode: z.string().optional(),
    }),
  ),
  competitiveMentionStatisticsAnalyses: makeRoute(
    Page.CompetitiveMentionStatisticsAnalyses,
    z.object({
      id: z.coerce.number(),
    }),
    z.object({
      countryCode: z.string().optional(),
    }),
  ),
  competitiveKeywordContentAnalyses: makeRoute(
    Page.CompetitiveKeywordContentAnalyses,
    z.object({
      id: z.coerce.number(),
    }),
    z.object({
      platform: z
        .nativeEnum(PlatformShortcode)
        .optional()
        .default(PlatformShortcode.Instagram),
      duration: z.nativeEnum(Duration).optional().default(Duration.SixMonths),
      withoutOfficialAccounts: z.coerce.boolean().optional().default(true),
      selectedKeywordIds: z.coerce.number().array().optional(),
      countryCode: z.string().optional(),
    }),
  ),
  competitiveKeywordStatisticsAnalyses: makeRoute(
    Page.CompetitiveKeywordStatisticsAnalyses,
    z.object({
      id: z.coerce.number(),
    }),
    z.object({
      countryCode: z.string().optional(),
    }),
  ),
  collectionsPostId: makeRoute(
    Page.CollectionsPostId,
    z.object({ id: z.coerce.number() }),
  ),
  inviteWorkspace: makeRoute(
    Page.InviteWorkspace,
    emptySchema,
    z.object({
      workspaceId: z.coerce.number().optional(),
      workspaceName: z.string().optional(),
    }),
  ),
  integrations: makeRoute(Page.Integrations, emptySchema),
  search: searchRoute,
  aiSearch: makeRoute(
    Page.AISearch,
    emptySchema,
    z.object({
      searchId: z.string().optional(),
    }),
  ),
  workspacePlan: makeRoute(
    Page.WorkspacePlan,
    emptySchema,
    z.object({ openTrialModal: z.coerce.number().optional() }),
  ),
}

export default routes
