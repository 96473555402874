import { isEmpty } from 'lodash-es'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

export const SafeNewTabLink: FunctionComponent<
  Omit<JSX.IntrinsicElements['a'], 'target'>
> = ({
  onClick: originalOnClick,
  ...props
}: Omit<JSX.IntrinsicElements['a'], 'target'>) => (
  <a
    rel='noopener noreferrer'
    target='_blank'
    onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
      if (isEmpty(props.href)) {
        e.preventDefault()
      }
      originalOnClick?.(e)
    }}
    {...props}
  >
    {props.children}
  </a>
)

export default styled(SafeNewTabLink)``
