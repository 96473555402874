import { z } from 'zod'
import { listResourceSchema } from '@/types/schema/api/list-resource-schema'
import { ownerSchema } from '@/types/schema/api/owner-schema'
import { competitiveStaticHashTagsSchema } from '@/types/schema/competitive-analyses/competitive-brand-analyses'
import { competitiveMentionKeywordKolCustomizedTagsNameChartDataSchema } from '@/types/schema/competitive-analyses/competitive-mention-analyses'
import {
  competitiveAnalysisPostKolSchema,
  competitiveAnalysisPostSchema,
  followerLevelPercentageSchema,
} from '@/types/schema/competitive-analyses/index'
import { Potential } from '@/utils/detail/potential'

export const competitiveKeywordAnalysisSchema = z.object({
  id: z.number(),
  name: z.string(),
  competitive_keyword_count: z.number(),
  updated_at: z.string().datetime(),
  creator: ownerSchema,
})
export type CompetitiveKeywordAnalysis = z.infer<
  typeof competitiveKeywordAnalysisSchema
>

export const competitiveKeywordAnalysisListSchema = listResourceSchema.extend({
  data: competitiveKeywordAnalysisSchema.array(),
})
export type CompetitiveKeywordAnalysisList = z.infer<
  typeof competitiveKeywordAnalysisListSchema
>

export const createCompetitiveKeywordAnalysisResponseSchema = z.object({
  id: z.number(),
})
export type CreateCompetitiveKeywordAnalysisResponse = z.infer<
  typeof createCompetitiveKeywordAnalysisResponseSchema
>

export const competitiveKeywordAnalysesCreatorListSchema = ownerSchema.array()
export type CompetitiveKeywordAnalysesCreatorList = z.infer<
  typeof competitiveKeywordAnalysesCreatorListSchema
>

export const fetchCompetitiveKeywordKolCustomizedTagsNameChartDataResponseSchema =
  z.object({
    mention_keyword_kol_customized_tags_name_charts:
      competitiveMentionKeywordKolCustomizedTagsNameChartDataSchema.array(),
  })

export type FetchCompetitiveKeywordKolCustomizedTagsNameChartDataResponse =
  z.infer<
    typeof fetchCompetitiveKeywordKolCustomizedTagsNameChartDataResponseSchema
  >

const staticMentionSchema = z.object({
  kol_count: z.number(),
  post_count: z.number(),
  social_volume_count: z.number(),
  like_count: z.number(),
  comment_count: z.number(),
  share_count: z.number(),
  engagement_count: z.number(),
  view_count: z.number(),
})

export type StaticMention = z.infer<typeof staticMentionSchema>

const competitiveKeywordAnalysisStatisticSchema = z.object({
  keyword_info: z.object({
    id: z.number(),
    name: z.string(),
    content: z.string(),
    starred: z.boolean(),
    order_no: z.number(),
  }),
  mention: staticMentionSchema,
})

export type CompetitiveKeywordAnalysisStatistic = z.infer<
  typeof competitiveKeywordAnalysisStatisticSchema
>

export const competitiveKeywordAnalysisStatisticList =
  listResourceSchema.extend({
    data: competitiveKeywordAnalysisStatisticSchema.array(),
  })

export type CompetitiveKeywordAnalysisStatisticList = z.infer<
  typeof competitiveKeywordAnalysisStatisticList
>

const competitiveKeywordKolFollowerChartDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  follower_level_percentages: followerLevelPercentageSchema.array(),
  starred_keyword: z.boolean(),
})

export type CompetitiveKeywordKolFollowerChartData = z.infer<
  typeof competitiveKeywordKolFollowerChartDataSchema
>
export const fetchCompetitiveKeywordKolFollowerChartDataResponseSchema =
  z.object({
    follower_percentage_charts:
      competitiveKeywordKolFollowerChartDataSchema.array(),
  })
export type FetchCompetitiveKeywordKolFollowerChartDataResponse = z.infer<
  typeof fetchCompetitiveKeywordKolFollowerChartDataResponseSchema
>

const competitiveKeywordAnalysisKeywordSchema = z.object({
  id: z.number(),
  name: z.string(),
  content: z.string(), // e.g. "'Acer' AND {'Swift' OR 'Aspire Vero' OR 'ASPIREVERO'} blablabla"
  starred_keyword: z.boolean(),
  order_no: z.number(),
})

export type CompetitiveKeywordAnalysisKeyword = z.infer<
  typeof competitiveKeywordAnalysisKeywordSchema
>

export const competitiveKeywordAnalysisKeywordListSchema = z.object({
  keywords: competitiveKeywordAnalysisKeywordSchema.array(),
})

export type CompetitiveKeywordAnalysisKeywordList = z.infer<
  typeof competitiveKeywordAnalysisKeywordListSchema
>

const competitiveKeywordEffectivenessTrendChartTrendDataSchema = z.object({
  key: z.string(),
  from: z.string().datetime().nullable(),
  to: z.string().datetime().nullable(),
  social_volume_count: z.number(),
  post_count: z.number(),
  kol_count: z.number(),
  avg_follower_count: z.number().transform((value) => Math.ceil(value)),
  like_count: z.number(),
  comment_count: z.number(),
  view_count: z.number(),
  share_count: z.number(),
})

const competitiveKeywordEffectivenessTrendChartDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  trend_data: competitiveKeywordEffectivenessTrendChartTrendDataSchema.array(),
})

export const competitiveKeywordEffectivenessTrendList = z.object({
  keyword_infos: competitiveKeywordEffectivenessTrendChartDataSchema.array(),
})

export type CompetitiveKeywordEffectivenessTrendList = z.infer<
  typeof competitiveKeywordEffectivenessTrendList
>

export const competitiveKeywordEffectivenessTrendChartDetailSchema = z.object({
  kol_uuid: z.string(),
  kol_name: z.string(),
  follower_count: z.number(),
  post_id: z.string(),
  post_author_uuid: z.string(),
  post_author_name: z.string(),
  post_title: z.string(),
  post_engagement_count: z.number(),
  post_thumbnail_url: z.string(),
  post_url: z.string(),
  post_crawler_id: z.string(),
  post_type: z.string(),
  post_like_count: z.number(),
  post_comment_count: z.number(),
  post_view_count: z.number(),
  post_share_count: z.number(),
})

export type CompetitiveKeywordEffectivenessTrendChartDetail = z.infer<
  typeof competitiveKeywordEffectivenessTrendChartDetailSchema
>

export type CompetitiveKeywordEffectivenessTrendChartTrendData = z.infer<
  typeof competitiveKeywordEffectivenessTrendChartTrendDataSchema
>

const mentionKeywordSchema = z.object({
  id: z.number(),
  name: z.string(),
  content: z.string(),
})

const competitiveKeywordTopTenPostSchema = competitiveAnalysisPostSchema.extend(
  {
    kol_uuid: z.string(),
    kol_name: z.string(),
    kol_potential: z.nativeEnum(Potential).nullable(),
    kol_customized_tags_names: z.string().array(),
    engagement_count: z.number().nullable(),
    social_volume_count: z.number().nullable(),
    mention_keywords: mentionKeywordSchema.array(),
  },
)
export type CompetitiveKeywordTopTenPost = z.infer<
  typeof competitiveKeywordTopTenPostSchema
>
export const competitiveKeywordTopTenPostListSchema =
  competitiveKeywordTopTenPostSchema.array()
export type CompetitiveKeywordTopTenPostList = z.infer<
  typeof competitiveKeywordTopTenPostListSchema
>

export type CompetitiveKeywordAnalysisHashtagList = z.infer<
  typeof competitiveStaticHashTagsSchema
>

const competitiveKeywordPostKolSchema = competitiveAnalysisPostKolSchema.extend(
  {
    // FIXME: Can be move into competitiveAnalysisKolSchema when mention analyses api is ready
    is_collection_kol: z.boolean(),
    posts: competitiveAnalysisPostSchema
      .extend({ mention_keywords: mentionKeywordSchema.array() })
      .array(),
  },
)
export type CompetitiveKeywordPostKol = z.infer<
  typeof competitiveKeywordPostKolSchema
>

export const fetchCompetitiveKeywordPostKolListResponseSchema = z.object({
  data: competitiveKeywordPostKolSchema.array(),
  paging: z.object({
    cursors: z.object({
      after: z.string().nullable(),
      before: z.string().nullable(),
    }),
  }),
})
export type FetchCompetitiveKeywordPostKolListResponse = z.infer<
  typeof fetchCompetitiveKeywordPostKolListResponseSchema
>
