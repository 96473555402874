import { I18nId } from '@/i18n/config'

export interface FollowerOption {
  value: string
  label: I18nId
  level: number
}

// TODO: 應和 components/kol/common/follower-level-selector.tsx 的 FOLLOWER_COUNT_RANGE_RECORD 的數字對齊
export const FollowerRange: FollowerOption[] = [
  { level: 1, value: '0~9999', label: 'irm:community_filter_kol_level_1' },
  { level: 2, value: '10000~49999', label: 'irm:community_filter_kol_level_2' },
  {
    level: 3,
    value: '50000~499999',
    label: 'irm:community_filter_kol_level_3',
  },
  {
    level: 4,
    value: '500000~999999',
    label: 'irm:community_filter_kol_level_4',
  },
  {
    level: 5,
    value: '1000000~999999999',
    label: 'irm:community_filter_kol_level_5',
  },
]
