import isUndefined from 'lodash-es/isUndefined'
import { z } from 'zod'
import { CUSTOMIZED_TAG_I18N_MAP } from '@/constants/customized-tag'

export interface CustomizedTag {
  customized_tag_name: string
  sub_customized_tag_names: CustomizedTag[]
}

export const customizedTagSchema = z.object({
  customized_tag_name: z.string().refine((val) => {
    return !isUndefined(CUSTOMIZED_TAG_I18N_MAP[val])
  }),
  sub_customized_tag_names: z.array(z.lazy(() => customizedTagSchema)),
})
