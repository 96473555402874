import React, { FunctionComponent, useRef } from 'react'
import { useHoverDirty } from 'react-use'
import styled from 'styled-components'
import KolNameBadge from '@/components/common/kol-name-badge'
import { KolThumbnail } from '@/components/common/kol-thumbnail'
import SearchSimilarKolsLink from '@/components/search/search-similar-kols-link'
import { useIntl } from '@/i18n/hooks/use-intl'
import { QuickSearchKOL } from '@/types/api-v2-types'

interface DropdownKOLDetailProps
  extends Pick<QuickSearchKOL, 'similarKolPlatform'> {
  UUID: string
  name: string
  subNames: string[]
  followerCount: number
  isRadarStar?: boolean
  isVerified?: boolean
}
export const DropdownKolDetail: FunctionComponent<DropdownKOLDetailProps> = ({
  UUID,
  name,
  similarKolPlatform,
  subNames,
  followerCount,
  isVerified,
  isRadarStar,
}) => {
  const { formatNumber, formatMessage } = useIntl()
  const containerRef = useRef<HTMLDivElement>(null)
  const isHovering = useHoverDirty(containerRef)

  return (
    <KOLWrapper ref={containerRef}>
      <ThumbnailWrapper>
        <KolThumbnail kolUuid={UUID} />
      </ThumbnailWrapper>
      <div style={{ flex: 1 }}>
        <KolNameBadge
          isRadarStar={!!isRadarStar}
          isVerified={!!isVerified}
          name={name}
        />
        <SubInfoWrapper>
          {subNames.length > 0 && (
            <KOLSubNames>{subNames.join(' | ')}</KOLSubNames>
          )}
          <Follower>
            {formatNumber(followerCount)}{' '}
            {formatMessage({ id: 'kol:platform_data_follower' })}
          </Follower>
        </SubInfoWrapper>
      </div>
      {isHovering && (
        <SearchSimilarKolsLink
          at='Quick Search'
          kolId={UUID}
          similarKolPlatform={similarKolPlatform}
          style='link'
        />
      )}
    </KOLWrapper>
  )
}

const KOLWrapper = styled.div`
  display: flex;
  padding: 16px 20px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:first-child {
      margin-right: 15px;
    }
  }
`
const SubInfoWrapper = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
`

const KOLSubNames = styled.div`
  font-size: 12px;
  line-height: 17px;
  color: #3a3d4c;
  margin-right: 5px;
`

const Follower = styled.div`
  font-size: 12px;
  line-height: 17px;
  color: #909399;
`

const ThumbnailWrapper = styled.div`
  height: 40px;
  width: 40px;
`
