import { theme } from '@buggy/shared'
import Link from 'next/link'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { H6 } from '@/components/common/typography'
import { Icon } from '@/components/custom-icon'
import { UserDropdown } from '@/components/header/user-dropdown'
import useAgentInfo from '@/hooks/use-agent-info'
import { useAuth } from '@/hooks/use-auth'
import { Page } from '@/hooks/use-authorization/constants'
import routes from '@/hooks/use-authorization/routes'
import { useIntl } from '@/i18n/hooks/use-intl'

const HeaderItemGroup: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const { radarStarAgencyKOL } = useAgentInfo()
  const { isKol, isAgent } = useAuth()

  const fontSize = '24px'

  return (
    <HeaderItemGroupWrapper>
      {!radarStarAgencyKOL && (
        <>
          <HeaderItemWrapper>
            <Link href={Page.CaseList}>
              <Icon style={{ fontSize }} type='caseIcon' />
              <H6>{formatMessage({ id: 'kol:case_list_title' })}</H6>
            </Link>
          </HeaderItemWrapper>
          <HeaderItemWrapper>
            <Link href={Page.CampaignCase}>
              <Icon
                className='bullhorn'
                style={{ fontSize }}
                type='elIconBullhorn'
              />
              <H6>{formatMessage({ id: 'campaign:kol_my_campaign_title' })}</H6>
            </Link>
          </HeaderItemWrapper>
          {isAgent && (
            <HeaderItemWrapper>
              <Link href={Page.SettingsAgent}>
                <Icon
                  style={{ fontSize, color: theme.colors.text.primary }}
                  type='edit'
                />
                <H6>
                  {formatMessage({ id: 'general:navbar_kol_management' })}
                </H6>
              </Link>
            </HeaderItemWrapper>
          )}
          {isKol && (
            <HeaderItemWrapper>
              <Link href={routes.kolSelf.stringifyUrl({})}>
                <Icon
                  style={{ fontSize, color: theme.colors.text.primary }}
                  type='edit'
                />
                <H6>{formatMessage({ id: 'general:navbar_my_kol_file' })}</H6>
              </Link>
            </HeaderItemWrapper>
          )}
        </>
      )}
      <UserDropdown />
    </HeaderItemGroupWrapper>
  )
}

export default HeaderItemGroup

const HeaderItemGroupWrapper = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;

    > * {
      margin-right: 41px;
    }
  }
`

const HeaderItemWrapper = styled.div`
  cursor: pointer;

  ${H6} {
    display: inline-block;
    margin-left: 8px;
  }

  .anticon {
    color: #3e3d3d;
  }

  .bullhorn {
    svg {
      padding-top: 5px;
    }
  }

  &:hover {
    color: ${({ theme }): string => theme.colors.brand.primaryHover};
  }
`
