import { createAction, createReducer } from '@reduxjs/toolkit'
import { isEqual, xorBy } from 'lodash-es'
import {
  doneAPIStatus,
  initialAPIStatus,
  requestingAPIStatus,
} from '@/constants/api-status'
import { ACTION_TYPES, ActionPayload, State } from '@/types/redux/irm'

const initialSearchParams = {
  page: 1,
}

const initialState: State = {
  apiStatus: {
    getCommunity: initialAPIStatus(),
    getSetting: initialAPIStatus(),
  },
  community: {
    anchor: null,
    currentTotal: 0,
    kols: [],
    total: -1,
  },
  drawerVisible: false,
  importModalVisible: {
    uuid: false,
    add: false,
    campaign: false,
    link: false,
    radar: false,
    report: false,
  },
  searchParams: initialSearchParams,
  selectedKOLIDs: [],
  selectedKOLs: [], // type: CommunityKol
  selectedKolList: [], // types: KolSchemaModel
  setting: {
    resultDisplay: {},
  },
  tag: {
    all: [],
    irm: [],
    kol: [],
  },
}

export const actions = {
  addSelectedKOLs: createAction<ActionPayload['ADD_SELECTED_KOLS']>(
    ACTION_TYPES.ADD_SELECTED_KOLS,
  ),
  cleanCommunityKOLs: createAction<ActionPayload['CLEAN_COMMUNITY_KOLS']>(
    ACTION_TYPES.CLEAN_COMMUNITY_KOLS,
  ),
  cleanSearchParams: createAction<ActionPayload['CLEAN_SEARCH_PARAMS']>(
    ACTION_TYPES.CLEAN_SEARCH_PARAMS,
  ),
  resetSearchParams: createAction<ActionPayload['RESET_SEARCH_PARAMS']>(
    ACTION_TYPES.RESET_SEARCH_PARAMS,
  ),
  cleanSearchResult: createAction<ActionPayload['CLEAN_SEARCH_RESULT']>(
    ACTION_TYPES.CLEAN_SEARCH_RESULT,
  ),
  getAllTags: createAction<ActionPayload['GET_ALL_TAGS']>(
    ACTION_TYPES.GET_ALL_TAGS,
  ),
  getCommunityKOLs: createAction<ActionPayload['GET_COMMUNITY_KOLS']>(
    ACTION_TYPES.GET_COMMUNITY_KOLS,
  ),
  getCommunitySetting: createAction<ActionPayload['GET_COMMUNITY_SETTING']>(
    ACTION_TYPES.GET_COMMUNITY_SETTING,
  ),
  getIRMTags: createAction<ActionPayload['GET_IRM_TAGS']>(
    ACTION_TYPES.GET_IRM_TAGS,
  ),
  getKOLTags: createAction<ActionPayload['GET_KOL_TAGS']>(
    ACTION_TYPES.GET_KOL_TAGS,
  ),
  removeSelectedKOLs: createAction<ActionPayload['REMOVE_SELECTED_KOLS']>(
    ACTION_TYPES.REMOVE_SELECTED_KOLS,
  ),
  setAllTags: createAction<ActionPayload['SET_ALL_TAGS']>(
    ACTION_TYPES.SET_ALL_TAGS,
  ),
  setCommunityKOLs: createAction<ActionPayload['SET_COMMUNITY_KOLS']>(
    ACTION_TYPES.SET_COMMUNITY_KOLS,
  ),
  setIRMTags: createAction<ActionPayload['SET_IRM_TAGS']>(
    ACTION_TYPES.SET_IRM_TAGS,
  ),
  setImportModalVisible: createAction<
    ActionPayload['SET_IMPORT_MODAL_VISIBLE']
  >(ACTION_TYPES.SET_IMPORT_MODAL_VISIBLE),
  setKOLTags: createAction<ActionPayload['SET_KOL_TAGS']>(
    ACTION_TYPES.SET_KOL_TAGS,
  ),
  setResultDisplay: createAction<ActionPayload['SET_RESULT_DISPLAY']>(
    ACTION_TYPES.SET_RESULT_DISPLAY,
  ),
  setSearchParams: createAction<ActionPayload['SET_SEARCH_PARAMS']>(
    ACTION_TYPES.SET_SEARCH_PARAMS,
  ),
  setSelectedKolList: createAction<ActionPayload['SET_SELECTED_KOL_LIST']>(
    ACTION_TYPES.SET_SELECTED_KOL_LIST,
  ),
  setSelectedKOLIDs: createAction<ActionPayload['SET_SELECTED_KOL_IDS']>(
    ACTION_TYPES.SET_SELECTED_KOL_IDS,
  ),
  toggleDrawerVisible: createAction<ActionPayload['TOGGLE_DRAWER_VISIBLE']>(
    ACTION_TYPES.TOGGLE_DRAWER_VISIBLE,
  ),
}

export const reducer = createReducer<State>(initialState, (builder) =>
  builder
    .addCase(actions.toggleDrawerVisible, (state) => {
      state.drawerVisible = !state.drawerVisible
    })
    .addCase(actions.setKOLTags, (state, { payload }) => {
      state.tag.kol = payload
    })
    .addCase(actions.setAllTags, (state, { payload }) => {
      state.tag.all = payload
    })
    .addCase(actions.setIRMTags, (state, { payload }) => {
      state.tag.irm = payload
    })
    .addCase(actions.setImportModalVisible, (state, { payload }) => {
      state.importModalVisible[payload.modal] = payload.visible
    })
    .addCase(actions.getCommunityKOLs, (state) => {
      state.apiStatus.getCommunity = requestingAPIStatus()
    })
    .addCase(actions.setSearchParams, (state, { payload }) => {
      state.apiStatus.getCommunity = requestingAPIStatus()
      state.searchParams = { ...state.searchParams, ...payload }
    })
    .addCase(actions.setCommunityKOLs, (state, { payload }) => {
      state.apiStatus.getCommunity = doneAPIStatus()
      const { list, total, anchor } = payload
      if (isEqual(state.searchParams, initialSearchParams)) {
        state.community.total = total
      }

      state.community.kols = list
      state.community.currentTotal = total
      state.community.anchor = anchor
    })
    .addCase(actions.setResultDisplay, (state, { payload }) => {
      state.apiStatus.getSetting = doneAPIStatus()
      state.setting.resultDisplay = {
        ...state.setting.resultDisplay,
        ...payload,
      }
    })
    .addCase(actions.cleanSearchParams, (state) => {
      state.apiStatus.getCommunity = requestingAPIStatus()
      state.searchParams = { page: 1 }
    })
    .addCase(actions.resetSearchParams, (state) => {
      state.apiStatus.getCommunity = requestingAPIStatus()
      state.searchParams = { page: 1 }
    })
    .addCase(actions.cleanSearchResult, (state) => {
      state.community.total = -1
      state.community.kols = []
      state.community.currentTotal = 0
      state.community.anchor = null
    })
    .addCase(actions.setSelectedKOLIDs, (state, { payload }) => {
      state.selectedKOLIDs = payload
    })
    .addCase(actions.setSelectedKolList, (state, { payload }) => {
      state.selectedKolList = payload
    })
    .addCase(actions.getCommunitySetting, (state) => {
      state.apiStatus.getSetting = requestingAPIStatus()
    })
    .addCase(actions.addSelectedKOLs, (state, { payload }) => {
      state.selectedKOLs = [...state.selectedKOLs, ...payload]
    })
    .addCase(actions.removeSelectedKOLs, (state, { payload }) => {
      state.selectedKOLs = xorBy(state.selectedKOLs, payload, 'UUID')
    })
    .addCase(actions.cleanCommunityKOLs, (state) => {
      state.selectedKOLs = []
    }),
)
