import { get } from 'lodash-es'
import { WorkspaceType } from '@/api/user-api'
import { I18nId } from '@/i18n/config'

export const getWorkspaceTypeI18nId = (type = ''): I18nId => {
  const workspaceTypeMap: Record<WorkspaceType, I18nId> = {
    [WorkspaceType.Free]: 'payment:free_plan_type',
    [WorkspaceType.Vip]: 'vip:level_premium',
    [WorkspaceType.VipTrial]: 'vip:level_premium_trail',
    [WorkspaceType.PaymentVip]: 'vip:level_premium',
    [WorkspaceType.Enterprise]: 'vip:level_enterprise',
    [WorkspaceType.EnterpriseTrial]: 'vip:level_enterprise_trail',
    [WorkspaceType.Internal]: 'payment:internal_plan',
    [WorkspaceType.Trial]: 'payment:premium_trail_plan',
    [WorkspaceType.Premium]: 'payment:premium_plan',
    [WorkspaceType.Advanced]: 'payment:advanced_plan',
    [WorkspaceType.Corporate]: 'payment:corporate_plan',
  }

  return get(workspaceTypeMap, type, 'general:empty')
}
