import { SerializedError } from '@reduxjs/toolkit'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import {
  difference,
  head,
  isEmpty,
  isUndefined,
  keys,
  omit,
  pickBy,
  values,
} from 'lodash-es'
import { useRouter } from 'next/router'
import queryString from 'query-string'
import { useCallback, useMemo } from 'react'
import {
  createEnumParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import { v4 as uuid4 } from 'uuid'
import { useFetchUserStatusQuery } from '@/api/user-api'
import {
  PostRadarSearchRequest,
  useKolRadarSearchQuery,
  usePostRadarSearchQuery,
} from '@/api/workspaces-api'
import { PlatformParam } from '@/components/hashtag/common/hashtag-filter'
import { FOLLOWER_COUNT_RANGE_RECORD } from '@/components/kol/common/follower-level-selector'
import {
  ANY_KOL_TYPE_CODE,
  EXCLUDED_ALL_KOL_TYPE_CODE,
} from '@/components/search/filters/hooks/use-exclusion-filter'
import { VerifiedKolParam } from '@/components/search/filters/others-filter-group'
import { Page } from '@/hooks/use-authorization/constants'
import { useSelector } from '@/hooks/use-selector'
import { useIntl } from '@/i18n/hooks/use-intl'
import { Condition } from '@/types/api/search'
import { Optional } from '@/types/optional'
import { PostRadarSearchResponseWithComputeTime } from '@/types/schema/api/radar-search-post'
import { SearchSort } from '@/types/schema/kol-schema'
import { ampli, SearchForKolNoResultProperties } from '@/utils/ampli'
import {
  CountryCodeParam,
  UNLIMITED_COUNTRY_CODE,
} from '@/utils/check-allowed-country-code'
import { PlatformShortcode } from '@/utils/convert-platform'
import { PotentialParam } from '@/utils/detail/potential'
import { getDefaultCountryCode } from '@/utils/get-default-country-code'
import removeNil from '@/utils/remove-nil'
import { getFilterOfficialValues } from '@/utils/tracking/get-filter-official-value'
import searchForKol from '@/utils/tracking/search-for-kol'
import {
  NonNullStringParam,
  NoNullStringArrayParam,
} from '@/utils/use-query-params'

export enum SearchMode {
  Post = 'post',
  Kol = 'kol',
  SimilarKol = 'similarKol',
}

export interface SearchPostQueryParams {
  keyword?: string
  platform_type?: PlatformShortcode
  post_type?: string
  customized_tags_names?: string
  follower_start_from?: number
  follower_end_to?: number
  post_start_time?: string
  post_end_time?: string
  filter_advertorial?: string
  country_code?: string
  filter_kol_type?: string
  sort?: string
  after_page?: string
}

interface SortOption {
  value: NonNullable<SearchSort>
  label: string
  disabled: boolean
  tag?: string
}

export enum KolSortOption {
  Recommend = 'recommend',
  FollowerCount = 'followerCount',
  MaxFansUpRate3Month = 'maxFansUpRate3Month',
  MaxInteractiveRate3Month = 'maxInteractiveRate3Month',
  MaxAvgPvRate3Month = 'maxAvgPvRate3Month',
  MatchedPost = 'matchedPost',
}

export enum PostSortOption {
  Default = 'default',
  FollowerCount = 'follower_count',
  InteractiveCount = 'interactive_count',
  ViewCount = 'view_count',
  LikeCount = 'like_count',
  CommentCount = 'comment_count',
  PublishTime = 'publish_time',
}

const SearchModeParams = createEnumParam(values(SearchMode))

const searchKolDefaultParams = {
  keyword: withDefault(StringParam, undefined),
  keywordFrom: withDefault(StringParam, undefined),
  platform_type: withDefault(PlatformParam, undefined),
  country_code: withDefault(CountryCodeParam, undefined),
  verifiedKol: withDefault(VerifiedKolParam, undefined),
  potential: withDefault(PotentialParam, undefined),
  customized_tags_names: withDefault(StringParam, undefined),
  gender: withDefault(StringParam, undefined),
  avgPvRateBegin: withDefault(NumberParam, undefined),
  avgPvRateEnd: withDefault(NumberParam, undefined),
  interactiveRateBegin: withDefault(NumberParam, undefined),
  interactiveRateEnd: withDefault(NumberParam, undefined),
  followerRangeLevel: withDefault(NumberParam, undefined),
  fansUpRateBegin: withDefault(NumberParam, undefined),
  fansUpRateEnd: withDefault(NumberParam, undefined),
  filter_collection: withDefault(StringParam, undefined),
  filter_cooperation: withDefault(StringParam, undefined),
  filter_unrecommended: withDefault(StringParam, undefined),
  filter_kol_type: withDefault(StringParam, EXCLUDED_ALL_KOL_TYPE_CODE),
  audienceGenderCode: withDefault(StringParam, undefined),
  audienceGenderRateBegin: withDefault(NumberParam, undefined),
  audienceGenderRateEnd: withDefault(NumberParam, undefined),
  audienceAgeCode: withDefault(StringParam, undefined),
  audienceAgeRateBegin: withDefault(NumberParam, undefined),
  audienceAgeRateEnd: withDefault(NumberParam, undefined),
  audienceGeoCode: withDefault(StringParam, undefined),
  audienceGeoRateBegin: withDefault(NumberParam, undefined),
  audienceGeoRateEnd: withDefault(NumberParam, undefined),
  minQuotationPrice: withDefault(NumberParam, undefined),
  maxQuotationPrice: withDefault(NumberParam, undefined),
  follower_start_from: withDefault(NumberParam, undefined),
  follower_end_to: withDefault(NumberParam, undefined),
  post_start_time: withDefault(StringParam, undefined),
  post_end_time: withDefault(StringParam, undefined),
  sort: withDefault(StringParam, KolSortOption.Recommend),
  languages: withDefault(NoNullStringArrayParam, undefined),
  anchor: withDefault(StringParam, undefined),
  from: withDefault(StringParam, undefined),
}

const searchPostDefaultParams = {
  keyword: withDefault(StringParam, undefined),
  platform_type: withDefault(PlatformParam, undefined),
  post_type: withDefault(StringParam, undefined),
  customized_tags_names: withDefault(StringParam, undefined),
  follower_start_from: withDefault(NumberParam, undefined),
  follower_end_to: withDefault(NumberParam, undefined),
  post_start_time: withDefault(StringParam, undefined),
  post_end_time: withDefault(StringParam, undefined),
  filter_advertorial: withDefault(StringParam, undefined),
  country_code: withDefault(CountryCodeParam, undefined),
  filter_collection: withDefault(StringParam, undefined),
  filter_cooperation: withDefault(StringParam, undefined),
  filter_unrecommended: withDefault(StringParam, undefined),
  filter_kol_type: withDefault(StringParam, EXCLUDED_ALL_KOL_TYPE_CODE),
  sort: withDefault(StringParam, PostSortOption.Default),
  after_page: NonNullStringParam,
}

const isSearchPostQueryParams = (
  params: Condition | SearchPostQueryParams,
  mode: SearchMode,
): params is SearchPostQueryParams => {
  return mode === SearchMode.Post || Object(params).hasOwnProperty('page')
}

export const isDefaultSearch = (
  conditions: Condition,
  defaultCountryCode: string,
): boolean => {
  const DEFAULT_KEY_COUNT = 3 // country, sort, filter kol type
  const DEFAULT_SORT = 'recommend'

  const filterConditions = pickBy(conditions, (value) => {
    return !!value
  })

  if (Object.keys(filterConditions).length === 0) {
    return true
  }

  const isDefaultKeyCount =
    Object.keys(filterConditions).length === DEFAULT_KEY_COUNT
  const isDefaultCountry = filterConditions.country_code === defaultCountryCode
  const isDefaultSort = filterConditions.sort === DEFAULT_SORT
  const isDefaultFilterKolType = filterConditions.filter_kol_type === 'all'

  const isDefaultParameter =
    isDefaultKeyCount &&
    isDefaultCountry &&
    isDefaultSort &&
    isDefaultFilterKolType

  return isDefaultParameter
}

type UseSearch = (disabledDefaultSearch?: boolean) => {
  handleSearch: {
    (params: Condition): void
    (params: SearchPostQueryParams): void
  }
  handleSearchKol: (params: Condition) => void
  handleSearchPost: (params: SearchPostQueryParams) => void
  kolQueryParams: Condition
  postQueryParams: SearchPostQueryParams
  kolResult: ReturnType<typeof useKolRadarSearchQuery>
  postResult: {
    data: Optional<PostRadarSearchResponseWithComputeTime>
    isFetching: boolean
    error: Optional<FetchBaseQueryError | SerializedError>
  }
  mode: SearchMode
  sortOptions: SortOption[]
}

const useSearch: UseSearch = () => {
  const { formatMessage } = useIntl()
  const router = useRouter()

  const currentLayout = useSelector((state) => state.search.generalLayout)
  const isOnTourFlow = useSelector((state) => state.tour.tourModalVisible)
  const { data: userStatus } = useFetchUserStatusQuery()
  const defaultCountryCode = getDefaultCountryCode(
    userStatus?.currentWorkspace.countryCode,
    userStatus?.currentWorkspace.countryCode,
    userStatus?.currentWorkspace.availableServices,
    true,
  )

  const kolSortOptions = useCallback(
    (keyword: Optional<string>): SortOption[] => {
      return [
        {
          value: KolSortOption.Recommend,
          label: formatMessage({ id: 'search:sorting_option_kolradar' }),
          disabled: false,
          tag: 'recommend',
        },
        {
          value: KolSortOption.FollowerCount,
          label: formatMessage({ id: 'search:sorting_option_fans_descending' }),
          disabled: false,
          tag: 'follower',
        },
        {
          value: KolSortOption.MaxFansUpRate3Month,
          label: formatMessage({
            id: 'search:sorting_option_growth_descending',
          }),
          disabled: false,
          tag: 'fans-up-rate',
        },
        {
          value: KolSortOption.MaxInteractiveRate3Month,
          label: formatMessage({
            id: 'search:sorting_option_engagement_rate_descending',
          }),
          disabled: false,
          tag: 'interactive-rate',
        },
        {
          value: KolSortOption.MaxAvgPvRate3Month,
          label: formatMessage({ id: 'search:filter_view_rate_label' }),
          disabled: false,
          tag: 'pv-rate',
        },
        {
          value: KolSortOption.MatchedPost,
          label: formatMessage({
            id: 'search:sorting_option_keyword_descending',
          }),
          disabled: !keyword,
          tag: 'matched-post',
        },
      ]
    },
    [formatMessage],
  )

  const postSortOptions: SortOption[] = useMemo((): SortOption[] => {
    return [
      {
        value: PostSortOption.Default,
        label: formatMessage({ id: 'search:sorting_option_kolradar' }),
        disabled: false,
      },
      // TODO: 暫時關閉 https://www.notion.so/ikala/Prod-Search-b807357aed3f4545be868262fe4b0c4a?pvs=4
      // {
      //   value: PostSortOption.FollowerCount,
      //   label: formatMessage({
      //     id: 'search:sorting_option_kol_follower_count',
      //   }),
      //   disabled: false,
      // },
      {
        value: PostSortOption.InteractiveCount,
        label: formatMessage({ id: 'kol:post_option_engagement' }),
        disabled: false,
      },

      {
        value: PostSortOption.ViewCount,
        label: formatMessage({ id: 'kol:post_option_view' }),
        disabled: false,
      },

      {
        value: PostSortOption.LikeCount,
        label: formatMessage({ id: 'general:column_title_like_count' }),
        disabled: false,
      },

      {
        value: PostSortOption.CommentCount,
        label: formatMessage({ id: 'general:column_title_comment_count' }),
        disabled: false,
      },

      {
        value: PostSortOption.PublishTime,
        label: formatMessage({ id: 'search:sorting_option_publish_time' }),
        disabled: false,
      },
    ]
  }, [formatMessage])

  const [uiQuery] = useQueryParams({
    mode: withDefault(SearchModeParams, SearchMode.Kol),
  })
  const mode = uiQuery.mode

  const [postQuery] = useQueryParams(searchPostDefaultParams, {
    searchStringToObject: (searchString) => queryString.parse(searchString),
  })

  const [kolQuery] = useQueryParams(searchKolDefaultParams, {
    searchStringToObject: (searchString) =>
      queryString.parse(searchString, { arrayFormat: 'bracket' }),
  })

  const hasAnyPostRadarSearchCache = useSelector(
    (state) => !!state.api.queries.postRadarSearch,
  )
  const hasAnyKolRadarSearchCache = useSelector(
    (state) => !!state.api.queries.kolRadarSearch,
  )

  const kolQueryParams = {
    ...kolQuery,
    country_code: kolQuery.country_code ?? defaultCountryCode,
    keywordFrom:
      kolQuery.keywordFrom as SearchForKolNoResultProperties['keywordFrom'],
  }

  const postQueryParams = {
    ...postQuery,
    country_code: postQuery.country_code ?? defaultCountryCode,
  }

  const filter_kol_type =
    kolQuery.filter_kol_type === ANY_KOL_TYPE_CODE
      ? undefined
      : kolQuery.filter_kol_type

  const kolResult = useKolRadarSearchQuery(
    mode === SearchMode.Kol &&
      userStatus?.currentWorkspaceId &&
      router.pathname === Page.Search
      ? {
          workspaceId: userStatus.currentWorkspaceId,
          params: removeNil({
            ...kolQueryParams,
            follower_end_to:
              kolQueryParams.follower_end_to ===
              FOLLOWER_COUNT_RANGE_RECORD.level5.followerEndTo
                ? undefined
                : kolQueryParams.follower_end_to,
            filter_kol_type,
            country_code:
              kolQueryParams.country_code === UNLIMITED_COUNTRY_CODE
                ? undefined
                : kolQueryParams.country_code,
            anchor: hasAnyKolRadarSearchCache ? kolQuery.anchor : undefined,
            languages: kolQuery.languages?.join('|'),
          }),
        }
      : skipToken,
  )

  const postResult = usePostRadarSearchQuery(
    mode === SearchMode.Post && userStatus?.currentWorkspaceId
      ? {
          workspaceId: userStatus.currentWorkspaceId,
          params: removeNil<PostRadarSearchRequest['params']>({
            ...postQueryParams,
            follower_end_to:
              kolQueryParams.follower_end_to ===
              FOLLOWER_COUNT_RANGE_RECORD.level5.followerEndTo
                ? undefined
                : kolQueryParams.follower_end_to,
            filter_kol_type,
            country_code:
              postQueryParams.country_code === UNLIMITED_COUNTRY_CODE
                ? undefined
                : postQueryParams.country_code,
            // 如果沒有快取，就假設是從 URL 進來，從 URL 進來則無視分頁 ref: https://www.notion.so/ikala/Staging-Search-3203896f00ab43e39e385d4a53c10cbb?pvs=4
            after_page: hasAnyPostRadarSearchCache
              ? postQuery.after_page
              : undefined,
          }),
        }
      : skipToken,
  )

  const withAvailableSortOption = (
    mode: SearchMode,
    keyword: Optional<string>,
    sort: Optional<string>,
  ): Optional<string> => {
    if (!sort) {
      return sort
    }

    const options =
      mode === SearchMode.Kol ? kolSortOptions(keyword) : postSortOptions
    const defaultOption = head(options)
    const foundOption = options.find((option) => option.value === sort)

    if (foundOption) {
      return foundOption.disabled ? defaultOption?.value : foundOption.value
    }

    return defaultOption?.value
  }

  const withAvailablePlatformOption = (
    mode: SearchMode,
    platform?: PlatformShortcode,
  ): PlatformShortcode | undefined => {
    if (!platform) {
      return undefined
    }

    if (mode === SearchMode.Kol) {
      if (
        ![
          PlatformShortcode.Instagram,
          PlatformShortcode.Facebook,
          PlatformShortcode.YouTube,
          PlatformShortcode.Twitter,
          PlatformShortcode.TikTok,
        ].includes(platform)
      ) {
        return undefined
      }
    } else {
      if (
        ![
          PlatformShortcode.Instagram,
          PlatformShortcode.Facebook,
          PlatformShortcode.YouTube,
        ].includes(platform)
      ) {
        return undefined
      }
    }

    // 程式運行到這邊，代表是受支援的平台
    return platform
  }

  const handleSearchKol = (params: Condition): void => {
    const payload = {
      ...queryString.parse(router.asPath, { arrayFormat: 'bracket' }),
      ...params,
    }
    const searchId = uuid4()
    const keyword = payload.keyword || undefined
    const query = removeNil(
      omit(
        {
          ...payload,
          keyword,
          anchor: params.anchor || undefined,
          mode: SearchMode.Kol,
          searchId,
          platform_type: withAvailablePlatformOption(
            SearchMode.Kol,
            payload.platform_type,
          ),
          sort: withAvailableSortOption(SearchMode.Kol, keyword, payload.sort),
        },
        // 砍掉 post 的參數
        difference(
          Object.keys(searchPostDefaultParams),
          Object.keys(searchKolDefaultParams),
        ),
      ),
    )

    const cleanFilter = omit(removeNil(query), [
      'mode',
      'searchId',
      'from',
      'view',
      'country_code',
      'filter_kol_type',
    ])

    const isDefault =
      isOnTourFlow || isDefaultSearch(cleanFilter, defaultCountryCode)
    searchForKol(query, isDefault, currentLayout)

    void router.push(
      queryString.stringifyUrl(
        {
          url: Page.Search,
          query,
        },
        {
          arrayFormat: 'bracket',
        },
      ),
      undefined,
      {
        scroll: !params.anchor,
      },
    )
  }

  const handleSearchPost = (params: SearchPostQueryParams): void => {
    const payload = { ...router.query, ...params }
    const searchId = uuid4()
    const query = removeNil(
      omit(
        {
          ...payload,
          keyword: payload.keyword || undefined,
          after_page: params.after_page,
          mode: SearchMode.Post,
          searchId,
          sort: withAvailableSortOption(
            SearchMode.Post,
            undefined,
            payload.sort,
          ),
          platform_type: withAvailablePlatformOption(
            SearchMode.Post,
            payload.platform_type,
          ),
        },
        // 砍掉 kol 的參數
        difference(
          Object.keys(searchKolDefaultParams),
          Object.keys(searchPostDefaultParams),
        ),
      ),
    )

    const containsOtherFilter = !isEmpty(
      keys(
        omit(removeNil(query), [
          'from',
          'view',
          'country_code',
          'filter_kol_type',
        ]),
      ),
    )

    const isDefault =
      isOnTourFlow ||
      (!containsOtherFilter &&
        query.country_code === userStatus?.currentWorkspace.countryCode &&
        query.filter_kol_type === EXCLUDED_ALL_KOL_TYPE_CODE)

    ampli.searchForKolContent({
      countryCode: query.country_code,
      followerEndTo: query.follower_end_to,
      followerStartFrom: query.follower_start_from,
      keyword: query.keyword,
      platform: query.platform_type,
      postEndDate: query.post_end_time,
      postStartDate: query.post_start_time,
      postType: query.post_type,
      searchId,
      filterOfficialAccount: getFilterOfficialValues(query.filter_kol_type),
      view: currentLayout,
      postProperty: query.customized_tags_names?.split('|'),
      isDefault,
      isSponsored: !isUndefined(query.filter_advertorial)
        ? query.filter_advertorial === 'true'
        : undefined,
    })

    const newSearch = isUndefined(params.after_page)
    void router.push(
      queryString.stringifyUrl({
        url: Page.Search,
        query,
      }),
      undefined,
      {
        scroll: newSearch,
      },
    )
  }

  const handleSearch: ReturnType<UseSearch>['handleSearch'] = (
    params: Condition | SearchPostQueryParams,
  ) => {
    if (isSearchPostQueryParams(params, mode)) {
      handleSearchPost(params)
    } else {
      handleSearchKol(params)
    }
  }

  return {
    handleSearch,
    kolQueryParams,
    postQueryParams,
    kolResult,
    postResult: {
      data: postResult.data,
      isFetching: postResult.isFetching,
      error: postResult.error,
    },
    mode,
    handleSearchKol,
    handleSearchPost,
    sortOptions: useMemo(
      () =>
        mode === SearchMode.Kol
          ? kolSortOptions(kolQueryParams.keyword)
          : postSortOptions,
      [kolQueryParams.keyword, kolSortOptions, mode, postSortOptions],
    ),
  }
}

export default useSearch
