import { isObject } from 'lodash-es'
import { CountryCode } from '@/constants/country-currency'
import { ModelServiceWithEndAt } from '@/types/mock-api-types'
import { Nullable } from '@/types/nullable'
import {
  allowedDefaultCountryCodes,
  UNLIMITED_COUNTRY_CODE,
} from '@/utils/check-allowed-country-code'

export function getDefaultCountryCode(
  checkCode: unknown,
  workspaceCountryCode?: Nullable<CountryCode>,
  availableServices?: ModelServiceWithEndAt[] | CountryCode[],
  hasUnlimited?: boolean,
): string {
  const usableCountries =
    (isModelServiceWithEndAt(availableServices)
      ? availableServices
          ?.filter((service) => service.serviceCategory === 'database')
          .map((service) => service.code?.split('_')[1] ?? '')
      : availableServices) ?? []
  const ownCountry = workspaceCountryCode || ''
  const defaultPaidCountry =
    usableCountries.length > 0 ? usableCountries[0] : null
  const defaultPaidOwnCountry = usableCountries.find(
    (usableCountry) => usableCountry === ownCountry,
  )
  const defaultAllowedOwnCountry = allowedDefaultCountryCodes.find(
    (allowedDefaultCountry) => allowedDefaultCountry === ownCountry,
  )

  const defaultCode =
    defaultPaidOwnCountry ||
    defaultPaidCountry ||
    defaultAllowedOwnCountry ||
    (hasUnlimited ? UNLIMITED_COUNTRY_CODE : 'tw')

  return usableCountries.some((usableCountry) => usableCountry === checkCode)
    ? (checkCode as string)
    : defaultCode
}

const isModelServiceWithEndAt = (
  availableServices?: ModelServiceWithEndAt[] | CountryCode[],
): availableServices is ModelServiceWithEndAt[] =>
  !!availableServices?.every(
    (service) => isObject(service) && 'endAt' in service,
  )
