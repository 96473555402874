import { createAction, createReducer } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { useSelector } from '@/hooks/use-selector'
import { KolSchemaModel } from '@/types/schema/kol-schema'

/** 作為全局 Modal */
const NAMESPACE = 'modal'

interface State {
  accountingInfo: {
    visible: boolean
  }
  addCampaignPostModalByKOL: {
    visible: boolean
  }
  addCampaignKOL: {
    visible: boolean
  }
  addCollectionKOL: {
    visible: boolean
  }
  addReportKOL: {
    visible: boolean
  }
  contract: {
    visible: boolean
  }
  createReport: {
    visible: boolean
  }
  createReportContent: {
    visible: boolean
  }
  dbPurchase: {
    visible: boolean
  }
  editReportContent: {
    visible: boolean
  }
  editReportKOLContent: {
    visible: boolean
  }
  invoice: {
    visible: boolean
  }
  setPassword: {
    visible: boolean
  }
  vipOnly: {
    title: string | null
    visible: boolean
    canContinueBrowsing: boolean
  }
  reportKOL: {
    visible: boolean
  }
  shareReport: {
    visible: boolean
  }
  purchaseEnterprise: {
    visible: boolean
  }
  searchOperator: {
    visible: boolean
  }
  shipmentInfo: {
    visible: boolean
  }
  createCollectionModal: {
    visible: false
  }
  onboardingModal: {
    visible: boolean
  }
}

const initialState: State = {
  accountingInfo: {
    visible: false,
  },
  addCampaignKOL: {
    visible: false,
  },
  addCampaignPostModalByKOL: {
    visible: false,
  },
  addCollectionKOL: {
    visible: false,
  },
  addReportKOL: {
    visible: false,
  },
  contract: {
    visible: false,
  },
  createReport: {
    visible: false,
  },
  createReportContent: {
    visible: false,
  },
  dbPurchase: {
    visible: false,
  },
  editReportContent: {
    visible: false,
  },
  editReportKOLContent: {
    visible: false,
  },
  invoice: {
    visible: false,
  },
  purchaseEnterprise: {
    visible: false,
  },
  reportKOL: {
    visible: false,
  },
  searchOperator: {
    visible: false,
  },
  setPassword: {
    visible: false,
  },
  shareReport: {
    visible: false,
  },
  shipmentInfo: {
    visible: false,
  },
  vipOnly: { title: null, visible: false, canContinueBrowsing: false },
  createCollectionModal: {
    visible: false,
  },
  onboardingModal: {
    visible: false,
  },
}

export const actions = {
  kolForProject: {
    /** 設定所需要的 Kol 資料 */
    setKol: createAction<KolSchemaModel | null>(`${NAMESPACE}#kolForProject`),
  },
  openModal: createAction</** Modal Name */ string>(`${NAMESPACE}#openModal`),
  /** 開關 Modal（包括但不限於 kolForProjectModal） */
  toggleModal: createAction</** Modal Name */
  string>(`${NAMESPACE}#toggleModal`),
  vipOnly: {
    setTitle: createAction<string | null>(`${NAMESPACE}#vipOnly`),
    setCanContinueBrowsing: createAction<boolean>(
      `${NAMESPACE}#vipOnlyCanContinueBrowsing`,
    ),
  },
}

export const reducer = createReducer<State>(initialState, (builder) =>
  builder
    .addCase(actions.openModal, (state, action) => {
      const modalName = action.payload
      state[modalName].visible = true
    })
    .addCase(actions.toggleModal, (state, action) => {
      const modalName = action.payload
      state[modalName].visible = !state[modalName].visible
    })
    .addCase(actions.vipOnly.setTitle, (state, action) => {
      state.vipOnly.title = action.payload
    })
    .addCase(actions.vipOnly.setCanContinueBrowsing, (state, action) => {
      state.vipOnly.canContinueBrowsing = action.payload
      state.vipOnly.visible = true
    }),
)

export const hooks = {
  /**
   * 開關任何指定的全局 Modal
   */
  useVisible(modalName: keyof State): {
    openModal(): void
    toggleVisible(): void
    visible: boolean
  } {
    const dispatch = useDispatch()
    const visible = useSelector((state) => {
      const modelState = state.modal || {}
      return modelState[modalName]?.visible
    })

    return {
      openModal(): void {
        dispatch(actions.openModal(modalName))
      },
      toggleVisible(): void {
        dispatch(actions.toggleModal(modalName))
      },
      visible,
    }
  },
}
