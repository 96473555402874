import { createEnumParam } from 'use-query-params'

export const UNLIMITED_COUNTRY_CODE = 'unlimit'

export const allowedDefaultCountryCodes = [
  'tw',
  'jp',
  'hk',
  'my',
  'th',
  'vn',
  'sg',
  'kr',
]

export const CountryCodeParam = createEnumParam([
  UNLIMITED_COUNTRY_CODE,
  ...allowedDefaultCountryCodes,
])
