import { api, WorkspacesAPITagType } from '@/api/api'
import { Compute } from '@/types/api-v1-types'
import {
  SearchSimilarKolResponse,
  SearchSimilarKolsRequest,
} from '@/types/api/search'
import { applyKolSchemaModel } from '@/types/schema/kol-schema'
import { camelizeKeys } from '@/utils/humps'
import removeNil from '@/utils/remove-nil'

export const searchApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchSimilarKols: builder.query<
      SearchSimilarKolResponse & Compute,
      SearchSimilarKolsRequest
    >({
      queryFn: async (
        { workspaceId, uuid, ...params },
        _queryApi,
        _extraOptions,
        baseQuery,
      ) => {
        const now = new Date().getTime()
        const response = await baseQuery({
          url: `/workspaces/${workspaceId}/kols/${uuid}/search-similar-kols`,
          params: removeNil(params),
        })

        if (!response.data) {
          return {
            error: response.error ?? {
              status: 'FETCH_ERROR',
              data: undefined,
              error: 'Data is empty',
            },
          }
        }

        const camelizeResponse = camelizeKeys(
          response.data,
        ) as unknown as SearchSimilarKolResponse
        const responseTime = (new Date().getTime() - now) / 1000

        return {
          data: {
            ...camelizeResponse,
            responseTime,
            list: camelizeResponse.list.map((kol) => applyKolSchemaModel(kol)),
          },
        }
      },
      providesTags: [
        WorkspacesAPITagType.SearchSimilarKols,
        WorkspacesAPITagType.KolCollectionKol,
      ],
    }),
  }),
  overrideExisting: false,
})

export const useSearchSimilarKolsQuery =
  searchApi.endpoints.searchSimilarKols.useQuery
