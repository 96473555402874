import { Epic, ofType } from 'redux-observable'
import { from, Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { AppContext } from '@/api/executor/app-context'
import { Action, ACTION_TYPES } from '@/types/redux/report'

const getReports: Epic = (action$) =>
  action$.pipe(
    ofType(ACTION_TYPES.GET_REPORT_LIST),
    switchMap(
      (
        action: Action['GET_REPORT_LIST'],
      ): Observable<Action['SET_REPORT_LIST']> => {
        const { page, workspaceID, name, ownerIds = [] } = action.payload
        return from(
          AppContext.ApiExecutor.getReports(page, workspaceID, name, ownerIds),
        ).pipe(
          map(({ data }) => {
            const { result, total } = data
            return {
              type: ACTION_TYPES.SET_REPORT_LIST,
              payload: { list: result, total },
            }
          }),
          catchError(() => {
            return of({
              type: ACTION_TYPES.SET_REPORT_LIST,
              payload: { list: [], total: 0 },
            })
          }),
        )
      },
    ),
  )

const getReportCrawlingStatus: Epic = (action$) =>
  action$.pipe(
    ofType(ACTION_TYPES.GET_REPORT_CRAWLING_STATUS),
    switchMap(
      (
        action: Action['GET_REPORT_CRAWLING_STATUS'],
      ): Observable<Action['SET_REPORT_CRAWLING_STATUS']> => {
        const { reportID, workspaceID } = action.payload
        return from(
          AppContext.ApiExecutor.getReportCrawlerStatus(reportID, workspaceID),
        ).pipe(
          map(({ data }) => {
            return {
              type: ACTION_TYPES.SET_REPORT_CRAWLING_STATUS,
              payload: data.length > 0,
            }
          }),
          catchError(() => {
            return of({
              type: ACTION_TYPES.SET_REPORT_CRAWLING_STATUS,
              payload: false,
            })
          }),
        )
      },
    ),
  )

const getReportCrawlerStatus: Epic = (action$) =>
  action$.pipe(
    ofType(ACTION_TYPES.GET_REPORT_CRAWLER_JOBS),
    switchMap(
      (
        action: Action['GET_REPORT_CRAWLER_JOBS'],
      ): Observable<Action['SET_REPORT_CRAWLER_JOBS']> => {
        const { reportID, workspaceID } = action.payload
        return from(
          AppContext.ApiExecutor.getReportCrawlerStatus(reportID, workspaceID, [
            'running',
            'failed',
            'succeeded',
          ]),
        ).pipe(
          map(({ data }) => {
            return {
              type: ACTION_TYPES.SET_REPORT_CRAWLER_JOBS,
              payload: data,
            }
          }),
          catchError(() => {
            return of({
              type: ACTION_TYPES.SET_REPORT_CRAWLER_JOBS,
              payload: [],
            })
          }),
        )
      },
    ),
  )

const getTotalReportCrawlerStatus: Epic = (action$) =>
  action$.pipe(
    ofType(ACTION_TYPES.GET_UNREAD_REPORT_STATUS),
    switchMap(
      (
        action: Action['GET_UNREAD_REPORT_STATUS'],
      ): Observable<Action['SET_UNREAD_REPORT_STATUS']> => {
        const { workspaceID } = action.payload
        return from(
          AppContext.ApiExecutor.getTotalReportCrawlerStatus(workspaceID),
        ).pipe(
          map(({ data }) => {
            return {
              type: ACTION_TYPES.SET_UNREAD_REPORT_STATUS,
              payload: data.length > 0,
            }
          }),
          catchError(() => {
            return of({
              type: ACTION_TYPES.SET_UNREAD_REPORT_STATUS,
              payload: false,
            })
          }),
        )
      },
    ),
  )

const triggerCrawler: Epic = (action$) =>
  action$.pipe(
    ofType(ACTION_TYPES.TRIGGER_CRAWLER),
    switchMap(
      (
        action: Action['TRIGGER_CRAWLER'],
      ): Observable<Action['TRIGGERED_CRAWLER']> => {
        const { reportID, workspaceID } = action.payload
        return from(
          AppContext.ApiExecutor.triggerRefreshReport(reportID, workspaceID),
        ).pipe(
          map(() => {
            return {
              type: ACTION_TYPES.TRIGGERED_CRAWLER,
              payload: { reportID, workspaceID },
            }
          }),
          catchError(() => {
            return of({
              type: ACTION_TYPES.TRIGGERED_CRAWLER,
              payload: { reportID, workspaceID },
            })
          }),
        )
      },
    ),
  )

const triggeredCrawler: Epic = (action$) =>
  action$.pipe(
    ofType(ACTION_TYPES.TRIGGERED_CRAWLER),
    switchMap(
      (
        action: Action['TRIGGERED_CRAWLER'],
      ): Observable<Action['GET_REPORT_CRAWLING_STATUS']> => {
        const { reportID, workspaceID } = action.payload
        return of({
          type: ACTION_TYPES.GET_REPORT_CRAWLING_STATUS,
          payload: { reportID, workspaceID },
        })
      },
    ),
  )

const getOwnerList: Epic = (action$) =>
  action$.pipe(
    ofType(ACTION_TYPES.GET_OWNER_LIST),
    switchMap(
      (
        action: Action['GET_OWNER_LIST'],
      ): Observable<Action['SET_OWNER_LIST']> => {
        const { workspaceID } = action.payload
        return from(AppContext.ApiExecutor.getReportOwners(workspaceID)).pipe(
          map(({ data }) => {
            return {
              type: ACTION_TYPES.SET_OWNER_LIST,
              payload:
                data?.map((owner) => ({ text: owner.name, value: owner.id })) ??
                [],
            }
          }),
          catchError(() => {
            return of({
              type: ACTION_TYPES.SET_OWNER_LIST,
              payload: [],
            })
          }),
        )
      },
    ),
  )

const setAllChartData: Epic = (action$) =>
  action$.pipe(
    ofType(ACTION_TYPES.SET_REPORT),
    map((action: Action['SET_REPORT']): Action['SET_ALL_CHART_DATA'] => {
      const data = (action.payload?.reportStatistics as any) ?? []
      return {
        type: ACTION_TYPES.SET_ALL_CHART_DATA,
        payload: { data },
      }
    }),
  )

const reportEpics = [
  getReports,
  triggerCrawler,
  triggeredCrawler,
  getOwnerList,
  getReportCrawlingStatus,
  getReportCrawlerStatus,
  getTotalReportCrawlerStatus,
  setAllChartData,
]

export default reportEpics
