import { filter, isNil, keys } from 'lodash-es'
import { useMemo } from 'react'
import { FeatureFlag } from '@/api/user-api'
import { ALL_BUSINESS_OPTION_VALUES } from '@/constants/business-type'
import useFeatureFlags from '@/hooks/use-feature-flags'
import { usePermissions } from '@/hooks/use-permissions'
import { useIntl } from '@/i18n/hooks/use-intl'

export const EXCLUDED_ALL_KOL_TYPE_CODE = 'all'
export const ANY_KOL_TYPE_CODE = 'any'

export const getSelectedExcludedBusiness = (
  filterKolType?: string,
): string[] => {
  if (!isNil(filterKolType)) {
    return filterKolType === EXCLUDED_ALL_KOL_TYPE_CODE
      ? ALL_BUSINESS_OPTION_VALUES
      : filterKolType.split(',')
  }
  return []
}

export enum ExcludeType {
  FilterCollection = 'filter_collection',
  FilterCooperation = 'filter_cooperation',
  FilterUnrecommend = 'filter_unrecommended',
  FilterKolType = 'filter_kol_type',
}

interface ExcludeOption {
  label: string
  value: ExcludeType
  hasPermission: boolean
}

interface UseExclusionFilter {
  excludeOptions: ExcludeOption[]
  allExcludeOptionsKeys: ExcludeType[]
}

export const useExclusionFilter = (): UseExclusionFilter => {
  const { formatMessage } = useIntl()
  const { withIRMPermission, withReportPermission, withInternalPermission } =
    usePermissions()
  const { withFeature } = useFeatureFlags()

  return useMemo(() => {
    const excludeOptionsRecord: Record<ExcludeType, ExcludeOption> = {
      filter_collection: {
        value: ExcludeType.FilterCollection,
        label: formatMessage({ id: 'irm:lable_search_collection_page' }),
        hasPermission: true,
      },
      filter_cooperation: {
        value: ExcludeType.FilterCooperation,

        label: formatMessage({ id: 'irm:lable_search_collaboration_page' }),
        hasPermission:
          withInternalPermission ||
          withFeature(FeatureFlag.DualWheel) ||
          withReportPermission,
      },
      filter_unrecommended: {
        value: ExcludeType.FilterUnrecommend,
        label: formatMessage({ id: 'irm:exclude_search_not_recommend' }),
        hasPermission: withInternalPermission || withIRMPermission,
      },
      filter_kol_type: {
        value: ExcludeType.FilterKolType,
        label: formatMessage({ id: 'general:official_account' }),
        hasPermission: true,
      },
    }

    const excludeOptions = filter(
      excludeOptionsRecord,
      (option) => option.hasPermission,
    )

    const allExcludeOptionsKeys = keys(excludeOptionsRecord) as ExcludeType[]

    return { excludeOptions, allExcludeOptionsKeys }
  }, [
    formatMessage,
    withIRMPermission,
    withReportPermission,
    withInternalPermission,
    withFeature,
  ])
}
