import { Icon, theme } from '@buggy/shared'
import { Tooltip } from 'antd'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { FetchKolRecommendResponse } from '@/api/workspaces-api'
import { usePermissions } from '@/hooks/use-permissions'
import { useIntl } from '@/i18n/hooks/use-intl'

export interface RecommendationTagProps {
  data?: FetchKolRecommendResponse
  size?: 'small' | 'default'
}

const RecommendationTag: FunctionComponent<RecommendationTagProps> = ({
  data,
  size,
}) => {
  const { formatMessage } = useIntl()
  const { withIRMPermission } = usePermissions()

  if (!data || !withIRMPermission) {
    return null
  }

  if (!data.positive && !data.negative) {
    return null
  }

  const tagSize = size === 'small' ? 16 : 20
  const fontSize = size === 'small' ? 10 : 12

  return (
    <Tooltip
      placement='bottom'
      title={`${formatMessage({
        id: data.positive
          ? 'irm:mark_recommened_KOL'
          : 'irm:mark_not_recommened_KOL',
      })}`}
    >
      {data.positive ? (
        <PositiveTag $size={tagSize}>
          <Icon fontSize={fontSize} name='thumbs-up' stroke='#fa9e14' />
        </PositiveTag>
      ) : (
        <NegativeTag $size={tagSize}>
          <Icon
            fontSize={fontSize}
            name='thumbs-down'
            stroke={theme.colors.gray8}
          />
        </NegativeTag>
      )}
    </Tooltip>
  )
}

const Tag = styled.span<{ $size: number }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 4px;
  border-width: 1px;
  border-style: solid;
  width: ${({ $size }): number => $size}px;
  height: ${({ $size }): number => $size}px;
`

const PositiveTag = styled(Tag)`
  background: #fcf5e8;
  border-color: #f8bd87;
`

const NegativeTag = styled(Tag)`
  background: #fafafa;
  border-color: ${({ theme }): string => theme.colors.gray5};
`

export default RecommendationTag
