import { StringParam, useQueryParams } from 'use-query-params'
import { useRouterName } from '@/hooks/use-router-name'
import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'

interface OpenGraphInfo {
  titleId: I18nId
}

// TODO: auto report og info
// ref: https://docs.google.com/spreadsheets/d/1HWBxkRXRjwm0h_aGW1z-DeE1ndnhhM5hsWl04G9AJ6k/edit#gid=0
const pagesInfo: { [key: string]: OpenGraphInfo } = {
  '/': {
    titleId: '/',
  },
  '/report': {
    titleId: '/report',
  },
  '/login': {
    titleId: '/login',
  },
  '/projects': {
    titleId: '/projects',
  },
  '/collections': {
    titleId: '/projects',
  },
  '/register-success': {
    titleId: '/register-success',
  },
  '/register': {
    titleId: '/register',
  },
  '/reset-password': {
    titleId: '/password-reset',
  },
  '/search': {
    titleId: '/search',
  },
  '/settings': {
    titleId: '/settings?tab=acInfo',
  },
  '/settings?tab=acInfo': {
    titleId: '/settings?tab=acInfo',
  },
  '/settings?tab=changePW': {
    titleId: '/settings?tab=changePW',
  },
  '/settings?tab=agentInfo': {
    titleId: '/settings?tab=agentInfo',
  },
  '/verify-account': {
    titleId: '/verify',
  },
  '/verify-payment': {
    titleId: '/verify-payment',
  },
  '/verify': {
    titleId: '/verify',
  },
}

export const useOgInfo = (): {
  title: string
  description: string
} => {
  const page = useRouterName()
  const [query] = useQueryParams({
    tab: StringParam,
  })
  const tab = query.tab || 'acInfo'
  const routerName = page === '/settings' ? `${page}?tab=${tab}` : page
  const { formatMessage } = useIntl()
  const { titleId } = pagesInfo[routerName] || { titleId: '/' }
  const postfix = formatMessage({ id: 'postfix' })
  const title = formatMessage({ id: titleId })
  const description = formatMessage(
    { id: 'general:footer_product_description_1' },
    { kolRadarLink: undefined },
  )
  return {
    title: `${title} ─ ${postfix}`,
    description,
  }
}
