import dayjs from 'dayjs'
import { difference, isEmpty, keys } from 'lodash-es'
import { ChannelMetadataObject, ObjectCustom } from 'pubnub'
import { Epic, ofType, StateObservable } from 'redux-observable'
import { asyncScheduler, combineLatest, EMPTY, from, of, scheduled } from 'rxjs'
import {
  catchError,
  delay,
  filter,
  map,
  skipUntil,
  switchMap,
} from 'rxjs/operators'
import { UserAPITagType } from '@/api/api'
import { AppContext } from '@/api/executor/app-context'
import { userApi } from '@/api/user-api'
import {
  CampaignTab,
  switchCampaignTab,
  updateCampaignKolsSuccess,
} from '@/store/campaign'
import {
  getAppliedCampaignListSuccess,
  getInvitingCampaignListSuccess,
  getRunningCampaignListSuccess,
} from '@/store/campaign-case'
import {
  fetchChannelList,
  fetchChannelListFailure,
  fetchChannelListSuccess,
  fetchLatestMessage,
  fetchMessageCount,
  fetchMessageCountFinished,
  fetchMessages,
  fetchMessagesFailure,
  fetchMessagesSuccess,
  fetchNotifications,
  fetchNotificationsFailure,
  fetchNotificationsSuccess,
  fetchPubNubChannelMetadata,
  fetchPubNubChannelMetadataFailure,
  fetchPubNubChannelMetadataSuccess,
  insertUnreadMessage,
  markMessageAsRead,
  MessageCountsResponse,
  pubNubClientNotDefinedError,
  receiveFileEvent,
  receiveMessageEvent,
  removeUnreadMessage,
  setConversationList,
  setLastReadTimeKey,
  setMessageComposeVisible,
  updatePubNubChannelLastReadTime,
  updatePubNubChannelMetadata,
  updatePubNubChannelMetadataFailure,
} from '@/store/chatroom'
import { RootState } from '@/store/store'
import { isPubNubError } from '@/types/vendor/pubnub'
import { getPubNubClient } from '@/utils/hooks/use-pub-nub-client'

const fetchAllPubNubChannelMetadataAndLatestMessageEpic: Epic = (action$) =>
  combineLatest([
    action$.pipe(filter(setConversationList.match)),
    action$.pipe(filter(setLastReadTimeKey.match)),
  ]).pipe(
    filter((actions) => {
      return !isEmpty(actions[0].payload)
    }),
    switchMap((actions) => {
      const channels = actions[0].payload.map((conversation) => conversation.id)
      return scheduled(
        [fetchPubNubChannelMetadata(channels), fetchLatestMessage(channels)],
        asyncScheduler,
      )
    }),
  )

const fetchCampaignCaseListSuccessEpic: Epic = (
  action$,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(
      getInvitingCampaignListSuccess.type,
      getRunningCampaignListSuccess.type,
      getAppliedCampaignListSuccess.type,
    ),
    switchMap(() => {
      const campaignCaseState = state$.value.campaignCase
      const campaignCaseList = [
        ...(campaignCaseState.invitingCampaignList.data ?? []),
        ...(campaignCaseState.appliedCampaignList?.data ?? []),
        ...(campaignCaseState.runningCampaignList.data ?? []),
      ]

      return of(
        setConversationList(
          campaignCaseList.map((campaignCase) => {
            return {
              campaignCase,
              id: campaignCase.channelId,
              lastReadTime: 1,
              metadata: undefined,
              unreadMessageCount: 0,
            }
          }),
        ),
      )
    }),
  )

const fetchChannelListEpic: Epic = (
  action$,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(fetchChannelList.match),
    switchMap((action) => {
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId

      if (workspaceId) {
        return from(
          AppContext.ApiExecutor.getChannelList({
            ...action.payload,
            workspaceId,
          }),
        ).pipe(
          map(({ data }) => {
            return fetchChannelListSuccess(data?.channelInfo ?? [])
          }),
          catchError(() => {
            return of(fetchChannelListFailure())
          }),
        )
      } else {
        return of(fetchChannelListFailure())
      }
    }),
  )

const fetchChannelListSuccessEpic: Epic = (action$) =>
  action$.pipe(
    filter(fetchChannelListSuccess.match),
    switchMap((action) => {
      return of(
        setConversationList(
          action.payload.map((channel) => {
            return {
              channel: channel,
              id: channel.channelId,
              lastReadTime: 1,
              metadata: undefined,
              unreadMessageCount: channel.unreadCount,
            }
          }),
        ),
      )
    }),
  )

const fetchLatestMessageEpic: Epic = (action$) =>
  action$.pipe(
    filter(fetchLatestMessage.match),
    switchMap((action) => {
      return of(
        fetchMessages({
          channels: action.payload,
          count: 1,
        }),
      )
    }),
  )

const fetchMessagesEpic: Epic = (action$) =>
  action$.pipe(
    filter(fetchMessages.match),
    switchMap((action) => {
      const pubNubClient = getPubNubClient()

      if (pubNubClient) {
        return from(pubNubClient.fetchMessages(action.payload)).pipe(
          map((response) => {
            return fetchMessagesSuccess(response.channels)
          }),
          catchError((error) => {
            return of(fetchMessagesFailure(error))
          }),
        )
      } else {
        return of(fetchMessagesFailure(pubNubClientNotDefinedError))
      }
    }),
  )

const fetchMessagesSuccessEpic: Epic = (
  action$,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(fetchMessagesSuccess.match),
    switchMap((action) => {
      const chatroom = state$.value.chatroom
      const channelIDs = keys(action.payload)
      if (
        channelIDs.length === 1 &&
        channelIDs[0] === chatroom.currentConversationId &&
        chatroom.messageComposeVisible
      ) {
        return of(markMessageAsRead(chatroom.currentConversationId))
      } else {
        return of(fetchMessageCount(channelIDs)).pipe(delay(500))
      }
    }),
  )

const fetchMessagesFailureEpic: Epic = (action$) =>
  action$.pipe(
    filter(fetchMessagesFailure.match),
    switchMap((action) => {
      if (
        isPubNubError(action.payload) &&
        action.payload.status.statusCode === 403
      ) {
        return of(
          userApi.util.invalidateTags([UserAPITagType.PubNubAccessToken]),
        )
      }

      return EMPTY
    }),
  )

const refreshChannelListEpic: Epic = (
  action$,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(fetchMessagesSuccess.match),
    switchMap((action) => {
      const chatroom = state$.value.chatroom
      const channelIDs = keys(action.payload)
      const campaignId = state$.value.campaign.campaign?.id
      const shouldRefreshChannelList = !isEmpty(
        difference(channelIDs, keys(chatroom.conversationList)),
      )

      if (shouldRefreshChannelList && campaignId) {
        return of(fetchChannelList({ campaignId }))
      } else {
        return EMPTY
      }
    }),
  )

const fetchMessageCountEpic: Epic = (
  action$,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(fetchMessageCount.match),
    skipUntil(action$.pipe(filter(fetchPubNubChannelMetadataSuccess.match))),
    switchMap((action) => {
      const pubNubClient = getPubNubClient()
      const conversationList = state$.value.chatroom.conversationList

      if (pubNubClient) {
        return from(
          Promise.allSettled(
            action.payload.map(
              async (conversationID): Promise<MessageCountsResponse> => {
                const conversation = conversationList[conversationID]
                if (conversation) {
                  const response = await pubNubClient.fetchMessages({
                    channels: [conversationID],
                    end: conversation.lastReadTime,
                  })
                  const messageCount =
                    response.channels[conversationID]?.filter(
                      ({ uuid }) => uuid !== pubNubClient.getUUID(),
                    ).length ?? 0
                  return {
                    channel: conversationID,
                    count: messageCount,
                  }
                } else {
                  throw new Error(`Conversation ${conversationID} not found`)
                }
              },
            ),
          ),
        ).pipe(
          map((results) => {
            return fetchMessageCountFinished(results)
          }),
        )
      } else {
        return of(
          fetchPubNubChannelMetadataFailure(pubNubClientNotDefinedError),
        )
      }
    }),
  )

const fetchPubNubChannelMetadataEpic: Epic = (action$) =>
  action$.pipe(
    filter(fetchPubNubChannelMetadata.match),
    switchMap((action) => {
      const pubNubClient = getPubNubClient()

      if (pubNubClient) {
        return from(
          Promise.all(
            action.payload.map(async (channelId) => {
              try {
                const response = await pubNubClient.objects.getChannelMetadata({
                  channel: channelId,
                  include: {
                    customFields: true,
                  },
                })
                return response.data
              } catch (error) {
                console.error(error)
                return undefined
              }
            }),
          ),
        ).pipe(
          map((metadataObjects) => {
            return fetchPubNubChannelMetadataSuccess(
              metadataObjects.filter(
                (
                  metadataObject,
                ): metadataObject is ChannelMetadataObject<ObjectCustom> =>
                  !!metadataObject,
              ),
            )
          }),
          catchError((error) => {
            return of(fetchPubNubChannelMetadataFailure(error))
          }),
        )
      } else {
        return of(
          fetchPubNubChannelMetadataFailure(pubNubClientNotDefinedError),
        )
      }
    }),
  )

const fetchPubNubChannelMetadataSuccessEpic: Epic = (action$) =>
  action$.pipe(
    filter(fetchPubNubChannelMetadataSuccess.match),
    switchMap((action) =>
      of(fetchMessageCount(action.payload.map((metadata) => metadata.id))),
    ),
  )

const updatePubNubChannelLastReadTimeEpic: Epic = (
  action$,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(updatePubNubChannelLastReadTime.match),
    switchMap((action) => {
      const pubNubClient = getPubNubClient()
      const lastReadTimeKey = state$.value.chatroom.lastReadTimeKey

      if (!lastReadTimeKey || !pubNubClient) {
        return of(
          updatePubNubChannelMetadataFailure(pubNubClientNotDefinedError),
        )
      }

      return from(
        pubNubClient.objects.getChannelMetadata({
          channel: action.payload,
          include: {
            customFields: true,
          },
        }),
      ).pipe(
        map((response) => {
          return updatePubNubChannelMetadata({
            channel: action.payload,
            data: {
              custom: {
                ...response.data.custom,
                [lastReadTimeKey]: dayjs().unixNanoseconds(),
              },
            },
          })
        }),
        catchError((error) => {
          const statusCode = error?.['status']?.['statusCode']
          if (typeof statusCode === 'number' && statusCode === 404) {
            return of(
              updatePubNubChannelMetadata({
                channel: action.payload,
                data: {
                  custom: {
                    [lastReadTimeKey]: dayjs().unixNanoseconds(),
                  },
                },
              }),
            )
          } else {
            return of(updatePubNubChannelMetadataFailure(error))
          }
        }),
      )
    }),
  )

const markMessageAsReadEpic: Epic = (action$) =>
  action$.pipe(
    filter(markMessageAsRead.match),
    switchMap((action) => {
      return scheduled(
        [
          updatePubNubChannelLastReadTime(action.payload),
          removeUnreadMessage(action.payload),
        ],
        asyncScheduler,
      )
    }),
  )

const receiveFileEventEpic: Epic = (action$) =>
  action$.pipe(
    filter(receiveFileEvent.match),
    switchMap((action) => {
      const messageEnvelope = {
        channel: action.payload.channel,
        timetoken: action.payload.timetoken,
        message: {
          ...action.payload.message,
          message: action.payload.message,
          file: action.payload.file,
        },
        uuid: action.payload.publisher,
      }

      return scheduled(
        [
          fetchMessagesSuccess({
            [action.payload.channel]: [messageEnvelope],
          }),
          insertUnreadMessage(messageEnvelope),
        ],
        asyncScheduler,
      )
    }),
  )

const receiveMessageEventEpic: Epic = (action$) =>
  action$.pipe(
    filter(receiveMessageEvent.match),
    switchMap((action) => {
      const messageEnvelope = {
        channel: action.payload.channel,
        timetoken: action.payload.timetoken,
        message: action.payload.message,
        uuid: action.payload.publisher,
      }

      return scheduled(
        [
          fetchMessagesSuccess({
            [action.payload.channel]: [messageEnvelope],
          }),
          insertUnreadMessage(messageEnvelope),
        ],
        asyncScheduler,
      )
    }),
  )

const switchCampaignTabEpic: Epic = (action$) =>
  action$.pipe(
    filter(switchCampaignTab.match),
    switchMap((action) =>
      of(setMessageComposeVisible(action.payload === CampaignTab.chatroom)),
    ),
  )

const updateCampaignKolsSuccessEpic: Epic = (action$) =>
  action$.pipe(
    filter(updateCampaignKolsSuccess.match),
    switchMap((action) => {
      return of(fetchChannelList({ campaignId: action.payload.campaignId }))
    }),
  )

const updatePubNubChannelMetadataEpic: Epic = (action$) =>
  action$.pipe(
    filter(updatePubNubChannelMetadata.match),
    switchMap((action) => {
      const pubNubClient = getPubNubClient()

      if (pubNubClient) {
        return from(
          pubNubClient.objects.setChannelMetadata(action.payload),
        ).pipe(
          map(() => {
            return fetchPubNubChannelMetadata([action.payload.channel])
          }),
          catchError((error) => {
            return of(updatePubNubChannelMetadataFailure(error))
          }),
        )
      } else {
        return of(
          updatePubNubChannelMetadataFailure(pubNubClientNotDefinedError),
        )
      }
    }),
  )

const fetchNotificationsEpic: Epic = (
  action$,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(fetchNotifications.match),
    switchMap((action) => {
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId

      if (workspaceId) {
        return from(
          AppContext.ApiExecutor.fetchNotifications({
            ...action.payload,
            workspaceId,
          }),
        ).pipe(
          map(({ data }) => {
            return fetchNotificationsSuccess({
              data,
              page: action.payload.page,
            })
          }),
          catchError(() => {
            return of(fetchNotificationsFailure())
          }),
        )
      } else {
        return of(fetchNotificationsFailure())
      }
    }),
  )

const epics = [
  fetchAllPubNubChannelMetadataAndLatestMessageEpic,
  fetchCampaignCaseListSuccessEpic,
  fetchChannelListEpic,
  fetchChannelListSuccessEpic,
  fetchLatestMessageEpic,
  fetchMessageCountEpic,
  fetchMessagesEpic,
  fetchMessagesSuccessEpic,
  fetchMessagesFailureEpic,
  fetchPubNubChannelMetadataEpic,
  fetchPubNubChannelMetadataSuccessEpic,
  updatePubNubChannelLastReadTimeEpic,
  markMessageAsReadEpic,
  receiveFileEventEpic,
  receiveMessageEventEpic,
  switchCampaignTabEpic,
  updateCampaignKolsSuccessEpic,
  updatePubNubChannelMetadataEpic,
  fetchNotificationsEpic,
  refreshChannelListEpic,
]

export default epics
