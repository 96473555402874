import { Button } from 'antd'
import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components'

interface AdvancedFilterButtonProps {
  selected: boolean
}

export const AdvancedFilterButton = styled(Button)<AdvancedFilterButtonProps>`
  border: 1px solid ${({ theme }): string => theme.colors.border.divider};
  ${({ selected }): FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    selected
      ? css`
          background: ${({ theme }): string => theme.colors.bgPink};
        `
      : css``}
`
