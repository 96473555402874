import env from '@/utils/env'
import { isClient } from '@/utils/is-client'

export const gtagApi = {
  /**
   * equals `gtag('event', 'conversion', {'send_to': 'AW-780869102/hve8CN3-uowBEO67rPQC'});`
   */
  sendTo(id, value?, currency?, transaction_id?): void {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    if (isClient() && !window?.gtag) {
      console.warn(
        // eslint-disable-next-line @typescript-eslint/unbound-method
        `WANRING: 預期要有 window.gtag，但得到 ${window?.gtag}，請確保 gtag 有安裝。`,
      )
    }

    if (isClient()) {
      window?.gtag('event', 'conversion', {
        ['send_to']: `${process.env.NEXT_PUBLIC_GTAG_ID}/${id}`,
        value,
        currency,
        transaction_id,
      })
    }
  },
  sendGoogleAdsEvent(
    eventId: string,
    eventProperties?: {
      [key: string]: unknown
    },
  ): void {
    if (isClient() && !window?.gtag) {
      console.warn(
        `WANRING: 預期要有 window.gtag，但得到 ${window?.gtag}，請確保 gtag 有安裝。`,
      )
    }

    if (isClient()) {
      window?.gtag('event', 'conversion', {
        ['send_to']: `${env.GOOGLE_ADS_ID}/${eventId}`,
        ...eventProperties,
      })
    }
  },
}
