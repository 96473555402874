import { useRouter } from 'next/router'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { LangSelect } from '@/components/app/footer/lang-select'
import UnsafeNewTabLink from '@/components/public-page/footer/components/unsafe-new-tab-link'
import { allowCountries } from '@/components/public-page/footer/constants/countries'
import EmailIcon from '@/components/public-page/footer/svg/email-icon.svg'
import FacebookIcon from '@/components/public-page/footer/svg/facebook-icon.svg'
import IKalaCdpIcon from '@/components/public-page/footer/svg/ikala-cdp-w.svg'
import IKalaCloudIcon from '@/components/public-page/footer/svg/ikala-cloud-logo-w.svg'
import KolRadarIcon from '@/components/public-page/footer/svg/ikala-kolradar-logo-w.svg'
import InstagramIcon from '@/components/public-page/footer/svg/instagram-icon.svg'
import LinkedinIcon from '@/components/public-page/footer/svg/linkedin-icon.svg'
import LocationIcon from '@/components/public-page/footer/svg/location-icon.svg'
import LogoIcon from '@/components/public-page/footer/svg/logo-horizontal.svg'
import TwitterIcon from '@/components/public-page/footer/svg/twitter-icon.svg'
import YoutubeIcon from '@/components/public-page/footer/svg/youtube-icon.svg'
import { RWD } from '@/components/rwd'
import { CHROME_EXTENSION_STORE_URL } from '@/constants/chrome-extension'
import { CountryCode } from '@/constants/country-currency'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import { getLocaleRegion, useI18n } from '@/hooks/use-i18n'
import { useIntl } from '@/i18n/hooks/use-intl'
import { ampli } from '@/utils/ampli'

const CDP_URL =
  'https://cdp.ikala.ai/?utm_source=offical&utm_medium=kolradar&utm_campaign=footer'

const AppFooter: FunctionComponent = () => {
  const router = useRouter()
  const breakpoint = useBreakpoint()
  const { formatMessage } = useIntl()
  const { locale } = useI18n()
  const currentCountry = getLocaleRegion(locale) || 'tw'
  const isAllowCountriesContent = allowCountries.includes(currentCountry)

  const isJapan = currentCountry === 'jp'
  const displayTaxId = currentCountry === 'tw'
  const displayIkalaPhone = ['tw', 'hk'].includes(currentCountry)

  const getRootUrl = useCallback(
    (path?: string): string => {
      if (!path) {
        return process.env.NEXT_PUBLIC_LANDING_URL
      }

      if (currentCountry && currentCountry !== 'tw') {
        return `${process.env.NEXT_PUBLIC_LANDING_URL}/${currentCountry}/${path}`
      }

      return `${process.env.NEXT_PUBLIC_LANDING_URL}/${path}`
    },
    [currentCountry],
  )

  const iKalaURL = (): string => {
    return ['tw', 'hk'].includes(currentCountry)
      ? 'https://ikala.cloud/'
      : 'https://ikala.cloud/en/'
  }

  const iKalaAboutPath = (country): string => {
    switch (country) {
      case 'jp':
        return 'https://ikala.ai/jp/about_ikala/'
      case 'tw':
        return 'https://ikala.ai/zh-tw/about-ikala/'
      default:
        return 'https://ikala.ai/about/'
    }
  }

  const iKalaNewsPath = (country: string): string => {
    switch (country) {
      case 'jp':
        return 'https://ikala.ai/jp/latest_news/'
      case 'tw':
        return 'https://ikala.ai/zh-tw/press-news/latest-news-list/'
      default:
        return 'https://ikala.ai/press-room/latest-news/'
    }
  }

  const iKalaContactUsPath = (country: string): string => {
    switch (country) {
      case 'jp':
        return 'https://ikala.ai/jp/contact_us/'
      case 'tw':
        return 'https://ikala.ai/zh-tw/contact-us/'
      default:
        return 'https://ikala.ai/contact/'
    }
  }

  const iKalaCareerPath = (country: string): string => {
    switch (country) {
      case 'tw':
        return 'https://ikala.ai/zh-tw/careers/'
      default:
        return 'https://ikala.ai/recruit/'
    }
  }

  const socialLinks = useMemo(
    () => [
      {
        url: 'https://www.facebook.com/kolradar.global/',
        title: 'KOL Radar Facebook',
        imgSrc: <FacebookIcon />,
      },
      {
        url: 'https://www.instagram.com/kolradar_tw/',
        title: 'KOL Radar Instagram',
        imgSrc: <InstagramIcon />,
      },
      {
        url: 'https://www.linkedin.com/showcase/ikala-kol-radar/',
        title: 'KOL Radar Linkedin',
        imgSrc: <LinkedinIcon />,
      },
      {
        url: 'https://www.youtube.com/channel/UC0SRB1NANI9mL5FEbs6vtjw',
        title: 'KOL Radar YouTube',
        imgSrc: <YoutubeIcon />,
      },
      {
        url: 'https://goo.gl/maps/YZX6sJfAVviBRridA',
        title: 'KOL Radar Location',
        imgSrc: <LocationIcon />,
      },
      {
        url: getRootUrl('contact'),
        title: 'Email:kolradar@ikala.ai',
        imgSrc: <EmailIcon />,
      },
    ],
    [getRootUrl],
  )

  const displayLinks = useMemo(() => {
    const filterPlatform = ['tw', 'hk'].includes(currentCountry)
      ? []
      : ['KOL Radar Facebook', 'KOL Radar Instagram', 'KOL Radar YouTube']

    const japanOnly = isJapan
      ? [
          {
            url: 'https://twitter.com/iKalaJPofficial/',
            title: 'iKala Japan株式会社',
            imgSrc: <TwitterIcon />,
          },
        ]
      : []

    return japanOnly.concat(
      socialLinks.filter(
        (socialLink) => !filterPlatform.includes(socialLink.title),
      ),
    )
  }, [isJapan, currentCountry, socialLinks])

  return (
    <FooterWrapper>
      <div className='container'>
        <FooterLogoWrapper>
          <a href={getRootUrl()}>
            <LogoIcon height='36' />
          </a>
        </FooterLogoWrapper>
        <FooterContentWrapper>
          <div>
            <p>
              {formatMessage({ id: 'general:footer_product_description_1' })}
              <br />
              <br />
              {formatMessage({ id: 'general:footer_product_description_2' })}
              <br />
              <br />
              {displayTaxId && (
                <>
                  {formatMessage({ id: 'general:ikala_company_information' })}
                  <RWD mobile={false} pc={true}>
                    ｜
                  </RWD>
                  <RWD mobile={true} pc={false}>
                    <br />
                  </RWD>
                  {formatMessage({ id: 'general:ikala_company_tax_id' })}
                  <br />
                </>
              )}

              {displayIkalaPhone && <>(02) 8768-1110 #338</>}
            </p>
            <SocialWrapper>
              {displayLinks.map((socialLink) => (
                <UnsafeNewTabLink
                  href={socialLink.url}
                  key={socialLink.title}
                  rel='noopener noreferrer'
                >
                  {socialLink.imgSrc}
                </UnsafeNewTabLink>
              ))}
            </SocialWrapper>
          </div>
          <FooterContentItemWrapper>
            <h5>
              {formatMessage({ id: 'general:footer_subtitle_our_service' })}
            </h5>
            <ul>
              {!isJapan && (
                <li>
                  <a href={getRootUrl('solution')}>
                    {formatMessage({ id: 'general:footer_text_e2e_intro' })}
                  </a>
                </li>
              )}
              <li>
                <a href={getRootUrl('feature')}>
                  {formatMessage({
                    id: 'general:footer_text_platform_feature',
                  })}
                </a>
              </li>
              {currentCountry === 'tw' && (
                <li>
                  <a href={getRootUrl('kolradarstar')}>創作者計畫</a>
                </li>
              )}

              <li>
                <a href={getRootUrl('terms')}>
                  {formatMessage({ id: 'general:footer_text_terms' })}
                </a>
              </li>
              <li>
                <a href={getRootUrl('privacy')}>
                  {formatMessage({ id: 'general:footer_text_privacy' })}
                </a>
              </li>
              {isJapan && (
                <li>
                  <a href={getRootUrl('specified-commercial-transactions-law')}>
                    {formatMessage({
                      id: 'general:specified_commercial_transactions_law',
                    })}
                  </a>
                </li>
              )}
              <li>
                <a href={getRootUrl('contact')}>
                  {formatMessage({ id: 'general:footer_text_contact' })}
                </a>
              </li>
              {isJapan && (
                <li>
                  <UnsafeNewTabLink
                    href={CHROME_EXTENSION_STORE_URL}
                    onClick={(): void => {
                      ampli.visitChromeExtensionWebstore({
                        path: router.asPath,
                        from: 'footer',
                      })
                    }}
                  >
                    {formatMessage({
                      id: 'general:navbar_item_install_browser_extension',
                    })}
                  </UnsafeNewTabLink>
                </li>
              )}
            </ul>
          </FooterContentItemWrapper>
          {isAllowCountriesContent && (
            <FooterContentItemWrapper>
              <h5>
                {formatMessage({
                  id: 'general:footer_subtitle_online_resource',
                })}
              </h5>
              <ul>
                <li>
                  <a href={getRootUrl('reports')}>
                    {formatMessage({ id: 'general:footer_text_report' })}
                  </a>
                </li>
                <li>
                  <a href={getRootUrl('billboard')}>
                    {formatMessage({ id: 'general:footer_text_billboard' })}
                  </a>
                </li>
                <li>
                  <UnsafeNewTabLink href='https://blog.kolradar.com/'>
                    {formatMessage({ id: 'general:footer_text_blog' })}
                  </UnsafeNewTabLink>
                </li>
                <li>
                  <a href='https://kolradar.ongoodbits.com/'>
                    {formatMessage({ id: 'general:footer_text_weekly_news' })}
                  </a>
                </li>
                {currentCountry === CountryCode.TW && (
                  <>
                    <li>
                      <a
                        href={getRootUrl(
                          'billboard/top100/instagram/community-volume',
                        )}
                      >
                        百大 IG 帳號榜單
                      </a>
                    </li>
                    <li>
                      <a
                        href={getRootUrl(
                          'billboard/top100/facebook/community-volume',
                        )}
                      >
                        百大 FB 帳號榜單
                      </a>
                    </li>
                    <li>
                      <a
                        href={getRootUrl(
                          'billboard/top100/youtube/community-volume',
                        )}
                      >
                        百大 YT 帳號榜單
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </FooterContentItemWrapper>
          )}
          <FooterContentItemWrapper>
            <h5>{formatMessage({ id: 'general:footer_subtitle_ikala' })}</h5>
            <ul>
              <li>
                <UnsafeNewTabLink href={iKalaAboutPath(currentCountry)}>
                  {formatMessage({ id: 'general:footer_text_ikala_about' })}
                </UnsafeNewTabLink>
              </li>
              <li>
                <UnsafeNewTabLink href={iKalaNewsPath(currentCountry)}>
                  {formatMessage({ id: 'general:footer_text_ikala_news' })}
                </UnsafeNewTabLink>
              </li>
              <li>
                <UnsafeNewTabLink href={iKalaContactUsPath(currentCountry)}>
                  {formatMessage({
                    id: 'general:footer_text_ikala_contact_us',
                  })}
                </UnsafeNewTabLink>
              </li>
              {!isJapan && (
                <li>
                  <UnsafeNewTabLink href={iKalaCareerPath(currentCountry)}>
                    {formatMessage({ id: 'general:footer_text_ikala_join' })}
                  </UnsafeNewTabLink>
                </li>
              )}
            </ul>
          </FooterContentItemWrapper>
          <FooterContentItemWrapper>
            <h5>
              {formatMessage({ id: 'general:footer_subtitle_ikala_products' })}
            </h5>
            <ul>
              <li>
                <UnsafeNewTabLink href={iKalaURL()}>
                  <IKalaCloudIcon height='18' />
                </UnsafeNewTabLink>
              </li>
              <li>
                <UnsafeNewTabLink href={CDP_URL}>
                  <IKalaCdpIcon height='19' />
                </UnsafeNewTabLink>
              </li>
              <li>
                <UnsafeNewTabLink href={getRootUrl()}>
                  <KolRadarIcon height='19' />
                </UnsafeNewTabLink>
              </li>
            </ul>
          </FooterContentItemWrapper>
        </FooterContentWrapper>
        <FooterBottomWrapper>
          {breakpoint === 'mobile' && <br />}
          <LangSelect />
          Copyright © {new Date().getFullYear()}{' '}
          <UnsafeNewTabLink href='https://ikala.ai/'>iKala</UnsafeNewTabLink>{' '}
          All Rights Reserved.
        </FooterBottomWrapper>
      </div>
    </FooterWrapper>
  )
}

export default AppFooter

const FooterWrapper = styled.div`
  text-align: left;
  background-color: #2c2f3a;
  height: auto;
  position: relative;
  line-height: 1.5;
  font-weight: lighter;
  width: 100%;
  font-size: 15px;
  padding: 50px 0;
  color: rgba(255, 255, 255, 0.7);
  z-index: 11;

  a {
    color: white;
    text-decoration: none;

    svg {
      margin: 0 13px 0 0;
    }
  }

  ul {
    padding-left: 0;

    > li {
      list-style-type: none;
    }
  }

  @media (max-width: 767px) {
    .desktop {
      display: none;
    }
  }
  @media (min-width: 768px) {
    .mobile {
      display: none;
    }
  }
`

const FooterLogoWrapper = styled.div`
  padding: 5px 0 20px 0;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  margin-bottom: 20px;
  height: 67px;
`

const FooterContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0 30px;
  font-weight: 400;

  > div {
    width: calc(50% - 10px);
    margin: 0 5px;

    &:nth-child(1) {
      width: 100%;
    }

    @media (min-width: 992px) {
      &:nth-child(1) {
        padding: 0 35px 0 0;
        width: 50%;
      }

      &:not(:nth-child(1)) {
        flex: 1;
      }
    }
  }
`

const FooterContentItemWrapper = styled.div`
  h5 {
    color: ${({ theme }): string => theme.colors.text.white};
    margin-bottom: 14px;
    margin-top: 20px;

    @media (min-width: 992px) {
      margin-top: 0;
    }
  }

  li {
    margin-bottom: 5px;
  }

  a {
    color: rgba(255, 255, 255, 0.7);

    &:hover {
      color: rgba(255, 255, 255, 0.7);
    }

    svg {
      margin: 0 0 10px 0;
    }

    img {
      height: 26px;
    }
  }
`

const FooterBottomWrapper = styled.div`
  svg {
    margin: 0 5px -3px 0;
  }

  select {
    margin: 0 0 10px 0;

    @media (min-width: 768px) {
      margin: 0 10px 0 0;
    }
  }
`

const SocialWrapper = styled.div`
  svg {
    height: 36px;
  }
`
