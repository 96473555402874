import {
  Chat,
  MessageInput,
  MessageList,
  UserEntity,
} from '@pubnub/react-chat-components'
import { Button, Divider, notification } from 'antd'
import { usePubNub } from 'pubnub-react'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import MessageRow from '@/components/campaign/chatroom/message-row'
import { Icon } from '@/components/custom-icon'
import { useIntl } from '@/i18n/hooks/use-intl'

export type Participant = {
  name: string
  imageUrl: string
}

type MessageComposeProps = {
  disabled: boolean
  channelId: string
  participant: Participant
  users?: UserEntity[]
}

const MessageCompose: FunctionComponent<MessageComposeProps> = (props) => {
  const { channelId, participant, users } = props

  const pubNub = usePubNub()
  const { formatMessage } = useIntl()

  return (
    <Wrapper>
      <Chat
        channels={pubNub.getSubscribedChannels()} // To override internal subscription logic since we manage manually in usePubNubClient hook
        currentChannel={channelId}
        users={users}
        onError={(error): void => {
          console.error(error)
          notification.error({
            message: formatMessage({ id: 'general:operation_failure' }),
            placement: 'bottomRight',
          })
        }}
      >
        <MessageList
          fetchMessages={100}
          messageRenderer={(renderProps): JSX.Element => (
            <MessageRow sender={participant} {...renderProps} />
          )}
        />
        <Divider />
        <MessageInput
          senderInfo
          disabled={props.disabled}
          fileUpload='all'
          placeholder={formatMessage(
            { id: 'campaign:message_input_placeholder' },
            { participantName: participant.name },
          )}
          sendButton={
            <Button style={{ padding: 8, height: 34 }} type='primary'>
              <Icon style={{ fontSize: 16 }} type='send' />
            </Button>
          }
        />
      </Chat>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${({ theme }): string => theme.colors.background.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  .ant-divider-horizontal {
    margin: 0 0 0 13px;
    min-width: unset;
    width: calc(100% - 26px);

    @media (min-width: 768px) {
      margin: 0 0 0 24px;
      width: calc(100% - 48px);
    }
  }

  .pn-msg-list {
    --msg--hover__background: ${({ theme }): string =>
      theme.colors.background.white};
    --msg-list__background: ${({ theme }): string =>
      theme.colors.background.white};
    --msg-list__padding: 12px 0;
    --msg-list__spinner__color: ${({ theme }): string =>
      theme.colors.brand.primary};
  }

  .pn-msg-input {
    --msg-input__background: ${({ theme }): string =>
      theme.colors.background.white};
    --msg-input__padding: 13px;
    --msg-input__textarea__borderRadius: 8px;
    --msg-input__textarea__fontSize: 16px;
    --msg-input__textarea__height: auto;
    --msg-input__textarea__padding: 16px;
    --msg-input__placeholder__color: ${({ theme }): string =>
      theme.colors.base.grey.grey3};
    --msg-input__textarea__background: ${({ theme }): string =>
      theme.colors.background.white};
    --msg-input__textarea--focus__border: 1px solid #ff7161;
    --msg-input__textarea__border: 1px solid #e8e8e8;
    --msg-input__icon__fontSize: 30px;

    @media (min-width: 768px) {
      --msg-input__padding: 16px 40px 18px 24px;
    }
  }

  .pn-msg-input__textarea {
    white-space: normal;
  }

  .pn-msg {
    max-width: 100%;
    --msg__padding: 0 13px;

    @media (min-width: 768px) {
      --msg__padding: 0 24px;
    }
  }

  .pn-msg__title {
    display: none;
  }

  .pn-msg--own {
    --msg__flexDirection: row-reverse;
  }
`

export default MessageCompose
