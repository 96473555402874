import { isUndefined } from 'lodash-es'
import { VerifiedKol } from '@/constants/verified-kol'
import { Condition } from '@/types/api/search'
import { LayoutOption } from '@/types/layout-option'
import { ampli, SearchForKolProperties } from '@/utils/ampli'
import { Potential } from '@/utils/detail/potential'
import { getFilterOfficialValues } from '@/utils/tracking/get-filter-official-value'

const searchForKol = (
  query: Condition & { searchId?: string },
  isDefault: boolean,
  currentLayout: LayoutOption['value'],
): void => {
  ampli.searchForKol({
    audienceAge: query.audienceAgeCode as SearchForKolProperties['audienceAge'],
    audienceAgeRateBegin: query.audienceAgeRateBegin,
    audienceAgeRateEnd: query.audienceAgeRateEnd,
    audienceGender:
      query.audienceGenderCode as SearchForKolProperties['audienceGender'],
    audienceGenderRateBegin: query.audienceGenderRateBegin,
    audienceGenderRateEnd: query.audienceGenderRateEnd,
    audienceGeo: query.audienceGeoCode,
    audienceGeoRateBegin: query.audienceGeoRateBegin,
    audienceGeoRateEnd: query.audienceGeoRateEnd,
    avgPvRateBegin: query.avgPvRateBegin,
    avgPvRateEnd: query.avgPvRateEnd,
    budgetBegin: query.minQuotationPrice,
    budgetEnd: query.maxQuotationPrice,
    countryCode: query.country_code,
    fansUpRateBegin: query.fansUpRateBegin,
    fansUpRateEnd: query.fansUpRateEnd,
    filterCollection: !isUndefined(query.filter_collection)
      ? query.filter_collection.toString() === 'true'
      : undefined,
    filterCooperation: !isUndefined(query.filter_cooperation)
      ? query.filter_cooperation.toString() === 'true'
      : undefined,
    filterOfficialAccount: getFilterOfficialValues(query.filter_kol_type),
    filterUnrecommend: !isUndefined(query.filter_unrecommended)
      ? query.filter_unrecommended.toString() === 'true'
      : undefined,
    filterVerified:
      query.verifiedKol === VerifiedKol.Any ? undefined : query.verifiedKol,
    followerEndTo: query.follower_end_to,
    followerStartFrom: query.follower_start_from,
    gender: query.gender,
    growthPotentialType:
      query.potential === Potential.Any ? undefined : query.potential,
    interactiveRateBegin: query.interactiveRateBegin,
    interactiveRateEnd: query.interactiveRateEnd,
    isDefault,
    keyword: query.keyword,
    kolType: query.customized_tags_names?.split('|'),
    platform: query.platform_type ?? 'All',
    postEndDate: query.post_end_time,
    postStartDate: query.post_start_time,
    sort: query.sort,
    view: currentLayout,
    filterKolLanguage: query.languages,
    keywordFrom: query.keywordFrom,
    searchId: query.searchId,
    from: query.from,
  })
}

export default searchForKol
