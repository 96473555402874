import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Nullable } from '@/types/nullable'

export interface Follower {
  createdAt: string
  follower: number
}

export interface FollowerAnalyze {
  [platform: string]: Follower[]
}

export interface AudienceAnalyze {
  [platform: string]: Audience
}

export interface Audience {
  ages: Age[]
  gender?: Gender
  gendersPerAges: Nullable<GendersPerAge[]>
  geo?: {
    cities: City[]
    countries: Country[]
  }
  mainAge?: Age
  mainCountry?: Country
  mainGender?: {
    code: string
    weight: number
  }
  updatedAt: string
  authenticity: Nullable<{
    real: string
    suspicious: string
  }>
}

export interface Age {
  code: string
  weight: number
}

export interface Gender {
  female: number
  male: number
}

export interface GendersPerAge {
  code: string
  female: number
  male: number
}

interface City {
  country: {
    code: string
    name: string
  }
  name: string
  weight: number
}

interface Country {
  code: string
  name: string
  weight: number
}

export interface SimilarKols {
  follower: number
  name: string
  uuid: string
}

export interface State {
  kolUUID: string | null
}

export enum RelatedKolType {
  Brand = 'brand',
  Person = 'person',
}

export const initialState: State = {
  kolUUID: null,
}

const kolDetailSlice = createSlice({
  name: 'kolDetail',
  initialState,
  reducers: {
    setKolUUID: (state, action: PayloadAction<State['kolUUID']>) => {
      state.kolUUID = action.payload
    },
  },
})

export const { setKolUUID } = kolDetailSlice.actions

export default kolDetailSlice.reducer
