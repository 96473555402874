import { Select } from 'antd'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'
import FollowerLevel from '@/types/schema/follower-level'

interface FollowerLevelOption {
  label: I18nId
  value: FollowerLevel
}

export interface FollowerCountRange {
  followerStartFrom: number
  followerEndTo: number
}

interface FollowerLevelSelectorProps {
  value: FollowerLevel
  onChange: (value: FollowerLevel) => void
  isDemo?: boolean
}

// TODO: 應和 constants/follower-range.ts 的 FollowerRange 數字對齊
export const FOLLOWER_COUNT_RANGE_RECORD: Record<
  FollowerLevel,
  FollowerCountRange
> = {
  level1: {
    followerStartFrom: 0,
    followerEndTo: 9999,
  },
  level2: {
    followerStartFrom: 10000,
    followerEndTo: 49999,
  },
  level3: {
    followerStartFrom: 50000,
    followerEndTo: 499999,
  },
  level4: {
    followerStartFrom: 500000,
    followerEndTo: 999999,
  },
  level5: {
    followerStartFrom: 1000000,
    followerEndTo: 999999999,
  },
}

// todo: 整合 campaign irm 等網紅級距翻譯。
const FollowerRange: FollowerLevelOption[] = [
  { value: 'level1', label: 'campaign:kol_list_kol_level_1' },
  { value: 'level2', label: 'campaign:kol_list_kol_level_2' },
  {
    value: 'level3',
    label: 'campaign:kol_list_kol_level_3',
  },
  {
    value: 'level4',
    label: 'campaign:kol_list_kol_level_4',
  },
  {
    value: 'level5',
    label: 'campaign:kol_list_kol_level_5',
  },
]

const FollowerLevelSelector: FunctionComponent<FollowerLevelSelectorProps> = ({
  value,
  onChange,
  isDemo,
}) => {
  const { formatMessage } = useIntl()
  const options = FollowerRange.map((level) => {
    return {
      disabled: isDemo,
      label: formatMessage({ id: level.label }),
      value: level.value,
    }
  })

  return (
    <StyledSelector
      options={options}
      popupMatchSelectWidth={false}
      size='small'
      value={value}
      onChange={onChange}
    />
  )
}

const StyledSelector = styled(Select)`
  min-width: 120px;
`

export default FollowerLevelSelector
