import { Icon } from '@buggy/shared'
import { Dropdown, Menu, notification } from 'antd'
import Cookies from 'js-cookie'
import { isEmpty, noop } from 'lodash-es'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FunctionComponent, ReactElement } from 'react'
import styled from 'styled-components'
import {
  useFetchUserStatusQuery,
  Workspace,
  WorkspaceMemberStatus,
} from '@/api/user-api'
import { useChangeCurrentWorkspaceNameMutation } from '@/api/workspaces-api'
import { FeatherIcon, Icon as CustomIcon } from '@/components/custom-icon'
import { WorkspaceItem } from '@/components/header/workspace-item'
import { AvatarImage } from '@/components/workspace/avatar-image'
import { CookieName } from '@/constants/cookie-name'
import { workspaceDropdownMenuItems } from '@/constants/workspace/workspace-dropdown-menu-items'
import { Page } from '@/hooks/use-authorization/constants'
import { useI18n } from '@/hooks/use-i18n'
import { usePermissions } from '@/hooks/use-permissions'
import { useSelector } from '@/hooks/use-selector'
import * as userAPI from '@/hooks/user-api'
import { useIntl } from '@/i18n/hooks/use-intl'
import { reduxAPI } from '@/store/redux-api'
import { ampli } from '@/utils/ampli'
import { goToPage } from '@/utils/routes/go-to-page'
import { getWorkspaceTypeI18nId } from '@/utils/workspace/get-workspace-type-i18n-id'

export const WorkspaceDropdown: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const router = useRouter()
  const [, logout] = userAPI.hooks.useLogoutFn()
  const { locale } = useI18n()
  const { tourStepAction } = useSelector((state) => state.tour)
  const [changeCurrentWorkspace] = useChangeCurrentWorkspaceNameMutation()
  const { withFreePermission, withGuestPermission, withTrialPermission } =
    usePermissions()
  const { data: userStatus } = useFetchUserStatusQuery()

  const currentWorkspace = userStatus?.currentWorkspace
  const vipOnlyModal = reduxAPI.state.modal.hooks.useVisible('vipOnly')
  const onboardingModal =
    reduxAPI.state.modal.hooks.useVisible('onboardingModal')
  const switchList =
    userStatus?.workspaces?.filter(
      (workspace) => userStatus?.currentWorkspaceId !== workspace.id,
    ) ?? []
  const enableCreateWorkspace =
    !withGuestPermission ||
    userStatus?.workspaces.every(
      (workspace) => workspace.memberStatus === 'guest',
    )

  const showUpgradeCta = withFreePermission || withTrialPermission

  const toggleCreateWorkspaceModal: VoidFunction = () => {
    if (showUpgradeCta) {
      vipOnlyModal.toggleVisible()

      ampli.popUpUpgradeNotify({
        path: router.asPath,
        section: 'navbar-dropdown-create-workspace',
      })
    } else {
      router.push(Page.CreateWorkspace).catch((e) => console.warn(e))
    }
  }

  const onLogout: VoidFunction = () => {
    const _locale = locale
    logout()
      .then(() => {
        ampli.logoutSuccess({
          isSingleLogin: Boolean(Cookies.get(CookieName.IsSingleLogin)),
        })
        Cookies.remove(CookieName.IsSingleLogin)

        notification.success({
          message: formatMessage({ id: 'general:logout_successfully' }),
          placement: 'bottomRight',
        })

        goToPage({
          pathname: '/login',
          query: { lang: _locale },
        }).catch((e) => console.warn(e))

        return noop()
      })
      .catch((error) => console.warn(error))
  }

  const handleSelectWorkspace = async (workspace: Workspace): Promise<void> => {
    try {
      await changeCurrentWorkspace({ workspaceId: workspace.id }).unwrap()

      ampli.toggleToWorkspace({
        workspaceID: workspace.id,
        workspaceName: workspace.name,
      })
      const isGuestPermission =
        workspace.memberStatus === WorkspaceMemberStatus.Guest ||
        workspace.memberStatus === WorkspaceMemberStatus.Viewer

      const pathname = isGuestPermission ? Page.Report : Page.Search

      await router.replace({
        pathname,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const menu = (workspace: Workspace): ReactElement => (
    <Menu
      getPopupContainer={(triggerNode): HTMLElement => triggerNode}
      triggerSubMenuAction='click'
    >
      <CurrentWorkspaceItem
        imgSrc={workspace.iconUrl ?? undefined}
        isGuest={workspace.memberStatus === 'guest'}
        memberAmount={workspace.memberAmount}
        name={workspace.name}
        type={getWorkspaceTypeI18nId(workspace.workspaceType ?? undefined)}
      />
      {!withGuestPermission &&
        workspaceDropdownMenuItems.map((menuItem) => (
          <Menu.Item
            icon={<CustomIcon type={menuItem.icon} />}
            key={menuItem.url}
            onClick={(): void => {
              if (menuItem.url === '/workspace-plan') {
                ampli.clickWorkspacePlan({ path: router.asPath })
              }
            }}
          >
            <Link href={menuItem.url}>
              {formatMessage({ id: menuItem.titleI18n })}
            </Link>
          </Menu.Item>
        ))}
      <StyledSubMenu
        icon={<CustomIcon type='elIconExternalLink' />}
        title={formatMessage({ id: 'general:navbar_workspace_switch' })}
      >
        {switchList.map((switchWorkspace) => {
          return (
            <Menu.Item
              key={switchWorkspace.id}
              onClick={async (): Promise<void> => {
                await handleSelectWorkspace(switchWorkspace)
              }}
            >
              <WorkspaceItem
                imgSrc={switchWorkspace.iconUrl ?? undefined}
                isGuest={switchWorkspace?.memberStatus === 'guest'}
                memberAmount={switchWorkspace.memberAmount}
                name={switchWorkspace?.name ?? ''}
                type={getWorkspaceTypeI18nId(
                  switchWorkspace?.workspaceType ?? undefined,
                )}
              />
            </Menu.Item>
          )
        })}
        {!withGuestPermission && !isEmpty(switchList) && <Menu.Divider />}
        {enableCreateWorkspace && (
          <Menu.Item
            icon={
              showUpgradeCta ? (
                <CrownIcon type='elIconCrownOutline' />
              ) : undefined
            }
            onClick={toggleCreateWorkspaceModal}
          >
            {formatMessage({ id: 'general:navbar_workspace_new' })}
          </Menu.Item>
        )}
      </StyledSubMenu>
      <Menu.Divider />
      <Menu.Item icon={<CustomIcon type='elIconSetting' />}>
        <Link href='/settings'>
          {formatMessage({ id: 'general:navbar_personal_setting' })}
        </Link>
      </Menu.Item>
      {!withGuestPermission && (
        <Menu.Item
          data-tour-final
          icon={
            <IconWrapper>
              <Icon fontSize={16} name='compass' />
            </IconWrapper>
          }
          onClick={(): void => {
            ampli.replayOnboardingTutorial()
            onboardingModal.toggleVisible()
          }}
        >
          {formatMessage({ id: 'general:navbar_onboarding_tour' })}
        </Menu.Item>
      )}

      <Menu.Item icon={<FeatherIcon type='log-out' />} onClick={onLogout}>
        <span>{formatMessage({ id: 'general:navbar_logout_text' })}</span>
      </Menu.Item>
    </Menu>
  )

  return (
    currentWorkspace && (
      <Dropdown
        open={tourStepAction === 'tour-final-step' ? true : undefined}
        overlay={menu(currentWorkspace)}
        trigger={['click']}
      >
        <UserItem onClick={(e): void => e.preventDefault()}>
          <AvatarImage
            imgSrc={currentWorkspace.iconUrl ?? undefined}
            name={currentWorkspace.name}
          />
          <FeatherIcon type='chevron-down' />
        </UserItem>
      </Dropdown>
    )
  )
}

const UserItem = styled.div`
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  ${FeatherIcon} {
    color: ${({ theme }): string => theme.colors.gray7};
    margin-left: 8px;
  }
`

const CurrentWorkspaceItem = styled(WorkspaceItem)`
  padding: 16px;
`

const StyledSubMenu = styled(Menu.SubMenu)`
  .ant-dropdown-menu-submenu {
    padding: 0;
  }

  .ant-dropdown-menu-submenu-popup {
    position: fixed;
    max-height: 40%;
    overflow-y: auto;

    .ant-dropdown-menu-item {
      padding: 0 20px 0 16px;
      min-height: 36px;
    }
  }
`
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`
const CrownIcon = styled(CustomIcon)`
  path {
    fill: ${({ theme }): string => theme.colors.premium};
  }
`
