import { flatMap, omit } from 'lodash-es'
import { api, PartnerAPITagType } from '@/api/api'
import {
  getAppliedCampaignListSuccess,
  getInvitingCampaignListSuccess,
  getRunningCampaignListSuccess,
} from '@/store/campaign-case'
import {
  ResponseCampaignCaseGroup,
  ResponseCampaignCaseList,
} from '@/types/campaign-case'
import { camelizeKeys, decamelizeKeys } from '@/utils/humps'

export enum CampaignStatus {
  Inviting = 'inviting',
  Applied = 'applied',
  Running = 'running',
  /** over 等於campaignStatus 的 done + cancel + reject */
  Over = 'over',
}

interface FetchKolCampaignListRequest {
  page: number
  perPage?: number
  campaignStatus: CampaignStatus
}

const DEFAULT_PER_PAGE = 10

const partnerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchKolCampaignList: builder.query<
      ResponseCampaignCaseList,
      Partial<FetchKolCampaignListRequest>
    >({
      query: ({ perPage = DEFAULT_PER_PAGE, ...params }) => {
        return {
          url: '/partner/campaigns/list',
          params: decamelizeKeys({ perPage, ...params }),
        }
      },
      transformResponse: (response: ResponseCampaignCaseGroup) => {
        const camelizeResponse = camelizeKeys(
          response,
        ) as unknown as ResponseCampaignCaseGroup

        const campaignCaseColumnList = flatMap(camelizeResponse?.data, (item) =>
          item.campaignKolList?.map((campaignKol, index) => ({
            ...campaignKol,
            isHead: index === 0,
            isLast: index === item.campaignKolList.length - 1,
            campaign: {
              ...omit(item, 'campaignKolList'),
              kolsCount: item.campaignKolList.length,
            },
          })),
        )

        return {
          ...response,
          data: campaignCaseColumnList,
        } as ResponseCampaignCaseList
      },
      providesTags: [PartnerAPITagType.CampaignList],
      onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
        try {
          const response = await queryFulfilled
          /**
           * @todo Remove below dispatches when fetchCampaignCaseListSuccessEpic migrated to RTKQ
           */
          if (request.campaignStatus === CampaignStatus.Inviting) {
            dispatch(getInvitingCampaignListSuccess(response.data))
          }

          if (request.campaignStatus === CampaignStatus.Applied) {
            dispatch(getAppliedCampaignListSuccess(response.data))
          }

          if (request.campaignStatus === CampaignStatus.Running) {
            dispatch(getRunningCampaignListSuccess(response.data))
          }
        } catch (error) {
          console.error(error)
        }
      },
    }),
    signContract: builder.mutation<void, string>({
      query: (id) => {
        return {
          url: `partner/contract/acception/${id}`,
          method: 'POST',
        }
      },
      invalidatesTags: [PartnerAPITagType.CampaignList],
    }),
  }),
})

export const useFetchKolCampaignListQuery =
  partnerApi.endpoints.fetchKolCampaignList.useQuery

export const useSignContractMutation =
  partnerApi.endpoints.signContract.useMutation
