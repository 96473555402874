import { SupportedLanguages } from '@/i18n/config'

const getLocalizedDiscountRate = (
  discountRate: number,
  language: SupportedLanguages,
): number => {
  switch (language) {
    case SupportedLanguages.zhTW:
      return parseFloat(((1 - discountRate) * 10).toFixed(2))
    default:
      return discountRate * 100
  }
}

export default getLocalizedDiscountRate
