import Cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
import { isUndefined } from 'lodash-es'
import moment from 'moment/moment'
import { AppContext } from '@/api/executor/app-context'
import { CookieName } from '@/constants/cookie-name'
import { UserSchema } from '@/types/schema/user-schema'

const MILLISECONDS_PER_SECOND = 1000

interface RefreshTokenIfNeeded {
  (): Promise<boolean>
}

const refreshTokenIfNeeded: RefreshTokenIfNeeded = async () => {
  const token = Cookies.get(CookieName.UserToken)

  if (isUndefined(token)) {
    return false
  }

  const user = jwt.decode(token) as UserSchema
  const now = moment()

  if (now.isAfter(moment(user?.exp * MILLISECONDS_PER_SECOND))) {
    try {
      await AppContext.ApiExecutor.refreshToken(token)
    } catch (error) {
      console.error(error)
      return false
    }
  }

  return true
}

export default refreshTokenIfNeeded
